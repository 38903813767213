import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import { ReactComponent as Edit } from './edit-button.svg';
import { ReactComponent as Download } from './download-button.svg';
import { checkUser } from '../../utils';

import './selectPhoto.scss';

const MergedPhoto = observer(({ item, index, onSelectPhoto, onRemoveWatermark, ...bind }) => {
    const store = useStore();
    const { session, mixpanel, nonBoothSessionId, brand } = store;

    const savePhoto = async (item) => {
        trackDownloadImage();
        saveAs(item?.photo, `${brand.baseConfig.options.brand}_PhotoBooth_Pic00${index}.jpg`, { type: "image/jpeg" })
    }

    const trackDownloadImage = () => {
        let sessionSplit;
        let sessionId = null;
        if (session) {
            sessionSplit = session.split('/');
            sessionId = sessionSplit[1];
        }
        mixpanel.track('DownloadIndividualImage', {
            'BoothSessionID': sessionId,
            'NonBoothSessionID': nonBoothSessionId,
            'Brand': brand.baseConfig.options.brand,
        })
    }

    return (
        <div display-if={item?.photo !== null}>
            <div alt=''>
                <img src={item?.photo} className="photo" alt='' {...bind} />
                <Download
                    alt=''
                    className={clsx('sq-button', `download-button-${brand.baseConfig.options.buttonPosition}`)}
                    onClick={() => savePhoto(item)}
                />
                {/* <img
                    src={'edit-button.svg'}
                    alt=''
                    className={clsx('sq-button', `edit-pencil-${store.brand.buttonPosition}`)}
                    onClick={() => onSelectPhoto(index)}
                /> */}
                <Edit
                    alt=''
                    className={clsx('sq-button', `edit-pencil-${brand.baseConfig.optionsv.buttonPosition}`)}
                    onClick={() => onSelectPhoto(index)}
                />
                {/* <div 
                    className="remove-watermark-button" 
                    display-if={store.brand.useWatermark && !store.watermarkRemoved}
                    onClick={() => store.setShowEmailRegister(true)}
                >
                    <p className="remove-watermark-text">REMOVE THE WATERMARK</p>
                </div> */}
            </div>
        </div>
    )
})

export default MergedPhoto;