import React, { useState } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react-lite';
import { registerEmail } from '../../services';
import { useStore } from '../../stores/store';
import clsx from 'clsx';

import './emailModal.scss'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
    }
};



export const EmailModal = observer(({ isOpen, setIsOpen }) => {
    const store = useStore();
    const { brand } = store;
    const [agree, setAgree] = useState(false)

    const validateEmail = email => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const checkForm = () => {
        if (store.user?.firstName && store.user?.lastName && validateEmail(store.user?.email) && agree)
            return true;
        else return false;
    }

    const registerUser = async () => {
        try {
            const res = await registerEmail(store.user, store.session?.split('/')[1] || store.nonBoothSessionId, store.config, brand);
            store.setWatermarkRemoved();
            store.setRemoveWatermark(true);
            store.setShowEmailRegister(false);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            class="email-modal"
            id="modal1"
            style={customStyles}
            className="modal-container"
            overlayClassName="modal-overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="close-modal-container" onClick={() => setIsOpen(false)}>
                <img src="assets/svg/close.svg" className="close-modal-x" alt="" />
            </div>

            <div className="modal-content">
                <p className="register-title">YOU'RE ONE YARD AWAY<br />FROM SCORING YOUR PHOTOS</p>

                <form className="form-container">
                    <div className="input-container">
                        <label className="input-label">FIRST NAME<span className="required">*</span></label>
                        <div className="input-row">
                            <input type="text" value={store.user?.firstName} placeholder="Jane" className="input-firstname" onChange={event => store.setFirstName(event.target.value)} />
                            <img src="assets/svg/circle-check.svg" alt="" display-if={store.user?.firstName} />
                        </div>
                    </div>
                    <div className="input-container">
                        <label className="input-label">LAST NAME<span className="required">*</span></label>
                        <div className="input-row">
                            <input type="text" value={store.user?.lastName} placeholder="Doe" className="input-firstname" onChange={event => store.setLastName(event.target.value)} />
                            <img src="assets/svg/circle-check.svg" alt="" display-if={store.user?.lastName} />
                        </div>
                    </div>
                    <div className="input-container">
                        <label className="input-label">EMAIL ADDRESS<span className="required">*</span></label>
                        <div className="input-row">
                            <input type="text" value={store.user?.email} placeholder="example@mail.com" className="input-firstname" onChange={event => store.setEmail(event.target.value)} />
                            <img src="assets/svg/circle-check.svg" alt="" display-if={validateEmail(store.user?.email)} />
                            <img src="assets/svg/circle-wrong.svg" alt="" display-if={!validateEmail(store.user?.email) && store.user?.email} />
                        </div>
                    </div>
                </form>

                <div className="consent-container">
                    <div onClick={() => setAgree(!agree)} className="consent-check-container">
                        {
                            agree ?
                                <img src="assets/svg/square-check.svg" alt="" className="consent-check" /> :
                                <img src="assets/svg/empty-check.svg" alt="" className="consent-check" />
                        }
                    </div>
                    <p className="agree-text">By creating FANtastic Photos™, I agree that the New England Patriots may email me special marketing and promotional materials, and agree to the terms of the <span className="agree-text-link">Photo Butler Privacy Policy</span>.</p>
                </div>

                <div className={clsx('submit-button', checkForm() && 'submit-button-available')} onClick={() => checkForm() && registerUser()}>
                    <p className="submit-button-text">SUBMIT</p>
                </div>
            </div>

        </Modal>
    )
})

export default EmailModal;