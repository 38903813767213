import React from 'react';
import { observer } from 'mobx-react-lite';

import EmailModal from '../emailModal';
import EmailModalV2 from '../emailModalV2';
import OracleHospitalityModal from './components/oracleHospitalityModal';

import './removeWatermark.scss'

const RemoveWatermark = observer(({ isOpen, setIsOpen, removeVersion }) => {
  const renderOption = () => {
    switch (removeVersion) {
      case 1:
        return <EmailModal isOpen={isOpen} setIsOpen={setIsOpen} />
      case 2:
        return <EmailModalV2 isOpen={isOpen} setIsOpen={setIsOpen} />
      case 3:
        return <OracleHospitalityModal isOpen={isOpen} setIsOpen={setIsOpen} />
      default:
        return <div />
    }
  }
  return (renderOption ())
})

export default RemoveWatermark;