import axios from 'axios';
import { getArrayBuffer } from '../utils';

const instance = axios.create({
  validateStatus: function (status) {

      return (status >= 200 && status < 400) || status === 400 || status === 404 || status === 403 || status === 502;
  },
});


export const getAssetUUID = (assetURL) => {
  const cleanURL = assetURL.split('?')[0]
  const splitURL = cleanURL.split('/');
  const assetUUID = splitURL[splitURL.length - 1].split('.')[0]
  return assetUUID;
}

export const registerUser = async ({ photo, config, bookingID, keyCard }) => {
  let resRegisterFace
  try {

    // const fetched = await fetch(photo);
    // const blobToSend = await fetched.blob();

    photo.name = 'selfie.jpg'
    const blobArrayBuffer = await getArrayBuffer(photo);

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + config.Token,
        'Content-Type': 'application/json'
      }
    }

    const payload = {
      assets: ["selfie.jpg"]
    }
    const resStartUpload = await axios.post(
      config.API.StartUpload,
      payload,
      axiosConfig,
    );
    let resUpload;
    if (resStartUpload.status === 200 && resStartUpload.data.result["selfie.jpg"]) {
      resUpload = await fetch(resStartUpload.data.result["selfie.jpg"], {
        method: 'PUT',
        headers: {
          'Content-Type': ''
        },
        body: blobArrayBuffer
      })

      const assetUUID = getAssetUUID(resStartUpload.data.result["selfie.jpg"])

      if (resUpload.status === 200) {
        const body = {
          "assets": [
            {
              "streamId": config.StreamId,
              "fileName": `${assetUUID}.jpg`,
              "uploadUrl": resStartUpload.data.result["selfie.jpg"],
              "captureDateTimeUTC": new Date().toISOString(),
              "captureDateTime": new Date().toISOString(),
              "autoRotate": true,
              "date_time_source": "exif"
            }
          ]
        }
        const resUploadComplete = await axios.post(
          config.API.UploadComplete,
          body,
          axiosConfig,
        )
        if (resUploadComplete.status === 200) {
          const body = {
            "booking_id": bookingID,
            "selfie_id": getAssetUUID(resStartUpload.data.result["selfie.jpg"]),
            "key_card_background_id": keyCard
          }
          resRegisterFace = await instance.post(
            config.API.RegisterFace,
            body,
            axiosConfig,
          );
          return resRegisterFace;
        }
      }
    }
    return resRegisterFace;
  } catch (e) {
    console.log(e)
    return resRegisterFace;
  }
}

export const getBookingUserData = (config, bookingID) => {
  const axiosConfig = {
    headers: {
      Authorization: 'Bearer ' + config.Token,
      'Content-Type': 'application/json'
    }
  }
  return instance.get(`${config.API.GetBookingUserData}/${bookingID}`, axiosConfig)
}