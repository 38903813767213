import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Spinner from '../../components/spinner/spinner';
import Loading from '../../components/loading';
import LogoHeader from '../../components/logoHeader';
import PromotionalContent from '../../components/promotionalContent';
import PromotionalContentV2 from '../../components/promotionalContentV2';
import PBFooter from '../../components/pBFooter';
import { getPhotoBoothPhotos } from '../../services';
import { makeMergedPhoto, resizePhoto, makeTodayCaption } from '../../utils';

import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';

import './sessionUrl.scss';
import PatriotsPromotionalContent from '../../components/patriotsPromotionalContent';
import RetreatPromotionalContent from '../../components/retreatPromotionalContent';
import CustomContent from '../home/customContent';

const SessionUrl = observer(() => {
    const store = useStore();

    const { config, mergeData, backgrounds, frames, captions, photos, mergedPhotos, session, mixpanel, nonBoothSessionId, brand } = store;
    const history = useHistory();
    const { date, sessionId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isMerging, setIsMerging] = useState(false);
    const [showError, setShowError] = useState(false);

    // useEffect(() => {
    //     const redirectToSharedHome = () => {
    //         console.log('inside redirect to shared home')
    //         history.push(`?date=${date}&sessionId=${sessionId}&redirected=true#/shared-home`)
    //     }

    //     if (store.brand.sharedHome && !store.finalSite) redirectToSharedHome();
    // }, [store])

    useEffect(() => {
        const redirectToSharedHome = () => {
            history.push(`/shared-home/${date}/${sessionId}`)
        }

        if (sessionId && brand.baseConfig.options.sharedHome && !store.finalSite) redirectToSharedHome();
        else if (sessionId) {
            store.setSession(date + '/' + sessionId);
        }
    }, [store, date, sessionId])


    useEffect(() => {
        const trackMixpanel = () => {
            mixpanel.track('LaunchWebExperience', {
                'BoothSessionID': session?.split('/')[1] || null,
                'NonBoothSessionID': nonBoothSessionId,
                'Brand': brand.baseConfig.options.brand,
                ...store.mixpanelBaseData,
            });
        }

        if (store && session && nonBoothSessionId && mixpanel && mixpanel.config?.token != null) trackMixpanel();
    }, [session, mixpanel, nonBoothSessionId, store])

    useEffect(() => {
        const newTodayCaption = async () => {
            const black = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'black')
            const white = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'white')
            store.setTodayCaption({
                black,
                white
            });
        }

        if (frames && captions) newTodayCaption();
    }, [frames, captions])

    useEffect(() => {
        const handleGetPhotos = async () => {
            const base = config.PhotoBoothUrl + session;
            const urls = new Array(4).fill().map((_, i) => `${base}/photo00${i}.jpg`)
            const urlsOriginal = new Array(4).fill().map((_, i) => `${base}/photo00${i}_orig.jpg`)
            setIsLoading(true);
            try {
                let existed;
                let existedOriginal;
                let photos;
                let photosOriginal;
                let fourPhotos = false;
                let timeout = false;
                setTimeout(() => timeout = true, 15000);

                while (!fourPhotos && !timeout) {
                    existed = await Promise.all(urls.map((url) => getPhotoBoothPhotos(url)));
                    existedOriginal = await Promise.all(urlsOriginal.map((url) => getPhotoBoothPhotos(url)));
                    photos = existed.filter(Boolean)
                    photosOriginal = existedOriginal.filter(Boolean)
                    if (photos.length === 4) fourPhotos = true;
                }
                if (photos.length === 0) throw new Error(JSON.stringify({ error: 'No photos.' }));
                let boothPhotos = await Promise.all(photos.map((photo) => resizePhoto(photo, brand.baseConfig.options.resolution)))
                let boothPhotosOriginal = await Promise.all(photosOriginal.map((photo) => resizePhoto(photo, brand.baseConfig.options.resolution)))
                store.setResizedPhotos(boothPhotosOriginal);
                store.setBoothPhotos(boothPhotos);
                setIsLoading(false);
            } catch (e) {
                console.log(e)
                setIsLoading(false);
                setIsMerging(false);
                setShowError(true);
            }
            setIsLoading(false);
        }

        if (session && config && mergeData) handleGetPhotos();
    }, [store, session, config, mergeData])

    useEffect(() => {
        const makeMergedPhotos = async () => {
            try {
                setIsMerging(true)
                let newMergedPhotos = [];
                let newMergeData = [];

                let mustUpdate = false;
                for (const p in photos) {
                    if (JSON.stringify(mergeData[p]) !== JSON.stringify(mergedPhotos[p]?.mergeData) && photos[p] !== null) {
                        const mergedPhoto = await makeMergedPhoto(store, photos[p], mergeData[p], backgrounds, frames, captions, brand.baseConfig.options.resolution, true);
                        const mergedPhotoObj = {
                            photo: mergedPhoto.finalMerge,
                            mergeData: { ...mergedPhoto.finalMergeData }
                        }
                        trackMixpanel(mergeData[p].frame, mergeData[p].background)
                        newMergedPhotos.push(mergedPhotoObj)
                        newMergeData.push({ ...mergedPhoto.finalMergeData });
                        mustUpdate = true;
                    }
                }
                if (mustUpdate) {
                    store.setMergedPhotos(newMergedPhotos)
                    store.setMergeData(newMergeData);
                    history.push('/editphoto/0');
                    // if (photos.length === 1) {
                    //     history.push('/editphoto/0');
                    // } else history.push('/selectphoto');

                    setIsMerging(false);
                }
            } catch (e) {
                console.log(e)
                setIsMerging(false);
                setIsLoading(false);
                setShowError(true);
            }
        }

        if (photos && mergeData && backgrounds && frames && captions && mergedPhotos) makeMergedPhotos();
    }, [history, store, photos, mergeData, backgrounds, frames, captions, mergedPhotos])

    const trackMixpanel = (frIndex, bgId) => {
        let sessionSplit;
        let sessionId = null;
        if (session) {
            sessionSplit = session.split('/');
            sessionId = sessionSplit[1];
        }
        mixpanel.track('MergeImage', {
            'Foreground': frames[frIndex].name,
            'Background': backgrounds[bgId].name,
            'BoothSessionID': sessionId,
            'NonBoothSessionID': nonBoothSessionId,
            ...store.mixpanelBaseData,
        })
    }


    return (
        <div className="session-url-container">
            <LogoHeader dark={true} alternateLogo={false} />

            <PromotionalContent isLoading={false} />
            <PromotionalContentV2 />
            {/* <PatriotsPromotionalContent display-if={store?.brand?.brand === 'patriots'}/>
            <RetreatPromotionalContent display-if={store?.brand?.brand === 'retreat'}/> */}

            <CustomContent brand={brand.baseConfig.options.brand} />

            <Loading
                className='loading-spinner-container'
                color={brand.baseConfig.colors.loadingBarColor}
                fontColor={brand.baseConfig.colors.quaternary}
                text={'Preparing your photos...'}
                display-if={isLoading || isMerging}
            />
            <p
                className="no-photos-found"
                style={{ textTransform: brand.baseConfig.options.textTransform }}
                display-if={showError}
            >
                Photos could not be found.<br />Please retake your PHOTOS AGAIN.
            </p>
            <PBFooter dark={true && brand.baseConfig.options.brand !== 'retreat'} />
        </div>
    )
});

export default SessionUrl;
