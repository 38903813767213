import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx'
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';

import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import Text from '../../components/text';

import './noMatches.scss'

const NoMatches = observer(() => {
  const store = useStore();
  const history = useHistory();

  const screen = { Screen: 'noPhotosFound' };

  useEffect(() => {
    const redirectHome = () => {
      history.push('/')
    }

    if (store && !store.nonBoothSessionId) redirectHome();
  }, [store])

  useEffect(() => {
    store.mixpanel.track('noPhotosFound', {
      ...store.mixpanelBaseData,
      ...screen,
    });
  },[])


  const handleTryAgain = () => {
    store.mixpanel.track('tapTryAgain', {
      ...store.mixpanelBaseData,
      ...screen,
    });
    history.push(`/take-photo`)
  }



  return (
    <div className='no-matches-container'>
      <LogoHeader dark={false} showBack />
      <p className="no-matches-title"><Text id='common.noMatchesString' /></p>
      <p className='no-matches-text'><Text id='common.noMatchesSorry' /></p>
      <p className='no-matches-text'><Text id='common.noMatchesReason' /></p>

      <img
        src={store.brand.baseConfig.images.noMatches}
        display-if={store.brand.baseConfig.images.noMatches}
        className='no-matches-image'
      />

      <p className='no-matches-hint'><Text id='common.noMatchesHint'/></p>

      <div 
        className='no-matches-buttons-container'
        display-if={store.brand.baseConfig?.options.isVisibleManualSearch}
      >
        <button
          className={clsx('no-matches-try-again-button', store.brand.baseConfig?.options?.buttonStyle)}
          // onClick={handleTryAgain}
          onClick={() => window.open(store.config.StreamWebUrl, '_blank')}
        >
          <Text id='common.tryAgainButton' />
        </button>
      </div>
      <PBFooter dark={false} />
    </div>
  )
})

export default NoMatches;