import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import { ReactComponent as Check } from './check.svg';
import clsx from 'clsx';
import { FORMAT } from '../../constants';

const SelectBackground = ({ backgrounds, bgIndex, setBgIndex, isMobile = false, format }) => (
  <div>
    <p
      className="option-menu-item"
      display-if={!isMobile}
    >
      Backgrounds
    </p>
    <ScrollContainer
      horizontal={isMobile && format === FORMAT.SQUARE}
      vertical={!isMobile || format === FORMAT.RECTANGULAR_16_BY_9}
      className={clsx('scroll-container', format === FORMAT.RECTANGULAR_16_BY_9 && 'scroll-container-16-by-9', backgrounds?.length <= 5 && 'centered')}
      nativeMobileScroll={false}
    >

      {
        backgrounds?.map((background, index) => {
          return (
            <div className="thumbnail-container" key={background.asset} onClick={() => setBgIndex(index)}>
              <Check className="check" display-if={bgIndex === index} />
              <img
                src={background.thumbnail}
                className={clsx('thumbnail', bgIndex === index && 'selected')}
                alt=''
                // display-if={!background.video}
              />
              {/* <video
                src={background.asset}
                className={clsx('thumbnail', bgIndex === index && 'selected')}
                display-if={background.video}
                playsInline
                autoPlay
                loop
              /> */}
            </div>

          )
        })
      }
    </ScrollContainer>
  </div>
)

export default SelectBackground;