import React from 'react';
import { Route } from 'react-router-dom';

import Welcome from 'containers/welcome';
import TermsAndConditions from 'containers/termsAndConditions';
import UserIdentity from 'containers/user-identity';
import TakePhoto from 'containers/takePhoto/takePhoto';
import UserPhoto from 'containers/userPhoto';
import SelectYourCard from 'containers/selectYourCard';
import FinalPhoto from 'containers/finalPhoto';

const RoutesLab = ({ oracleLab }) => {
    return (
        oracleLab ?
        <>
            <Route path="/welcome" exact component={Welcome} />
            <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
            <Route path="/user-identity" exact component={UserIdentity} />
            <Route path="/register-user/:isRegister" exact component={TakePhoto} />
            <Route path="/user-photo/:photoIndex" exact component={UserPhoto} />
            <Route path="/select-your-card" exact component={SelectYourCard} />
            <Route path="/registration-complete" exact><FinalPhoto registrationComplete/></Route>
        </>
        : <div/>
    )
}

export default RoutesLab;