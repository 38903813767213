import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Scrollbars } from 'rc-scrollbars';

import { useStore } from 'stores/store';
import LogoHeader from 'components/logoHeader';
import PBFooter from 'components/pBFooter';
import clsx from 'clsx';
import Text from 'components/text';
import CheckboxRow from './components/checkboxRow/checkboxRow'

import './termsAndConditions.scss'

const SCREEN = 'termsAndConditions';

const TermsAndConditions = observer(() => {
  const store = useStore();
  const history = useHistory();

  const [hasLegalAge, setHasLegalAge] = useState(false);
  const [hasReadAndAgree, setHasReadAndAgree] = useState(false);
  const [hasAgreedCookies, setHasAgreedCookies] = useState(false);
  const [hasReadAndAgreeConsent, setHasReadAndAgreeConsent] = useState(false);

  const fallbackTerms = store.lang.translate({ id: 'common.termslink', fields: null, plural: null, fallback: null, useDefault: true });
  const fallbackPrivacy = store.lang.translate({ id: 'common.privacylink', fields: null, plural: null, fallback: null, useDefault: true });

  useEffect(() => {
    const trackMixpanel = () => {
      store.mixpanel.track('LandingTerms', {
        ...store.mixpanelBaseData,
        'Screen': SCREEN,
      });
    }

    if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
  }, [store])

  const onAccept = () => {
    store.mixpanel.track('tapAccept', {
      ...store.mixpanelBaseData,
      'Screen': SCREEN,
    });
    // store.setTermsAccepted(true)
    history.push('/take-photo');
  }

  const onDecline = () => {
    store.mixpanel.track('tapDecline', {
      ...store.mixpanelBaseData,
      'Screen': SCREEN,
    });
    history.push('/');
  }

  const handleChangeCheckbox = (event, key) => {
    const { checked } = event.target
    switch (key) {
      case 'hasLegalAge':
        setHasLegalAge(checked);
        break;
      case 'hasReadAndAgree':
        setHasReadAndAgree(checked);
        break;
      case 'hasAgreedCookies':
        setHasAgreedCookies(checked);
        break;
      case 'hasReadAndAgreeConsent':
        setHasReadAndAgreeConsent(checked);
        break;
      default:
        break;
    }
  }

  const handlePressTerms = () => {
    store.mixpanel.track('tapTerms', {
      ...store.mixpanelBaseData,
      ...screen,
    });
    window.open(store.lang.translate({
      id: 'common.termslink',
      fields: null,
      plural: null,
      fallback: fallbackTerms,
      useDefault: false
    }), "_blank")
  };

  const handlePressPrivacy = () => {
    store.mixpanel.track('tapPrivacy', {
      ...store.mixpanelBaseData,
      ...screen,
    });
    window.open(store.lang.translate({
      id: 'common.privacylink',
      fields: null,
      plural: null,
      fallback: fallbackPrivacy,
      useDefault: false
    }), "_blank")
  };

  const handlePressCookies = () => {
    // store.mixpanel.track('tapPrivacy', {
    //   ...store.mixpanelBaseData,
    //   ...screen,
    // });
    // window.open(store.lang.translate({
    //   id: 'common.privacylink',
    //   fields: null,
    //   plural: null,
    //   fallback: fallbackPrivacy,
    //   useDefault: false
    // }), "_blank")
  };

  const handlePressConsent = () => {
    // store.mixpanel.track('tapPrivacy', {
    //   ...store.mixpanelBaseData,
    //   ...screen,
    // });
    // window.open(store.lang.translate({
    //   id: 'common.privacylink',
    //   fields: null,
    //   plural: null,
    //   fallback: fallbackPrivacy,
    //   useDefault: false
    // }), "_blank")
  };

  const handleReadAndAgree = () => {
    history.push('/user-identity')
  }

  const hasAgreedTermsAndConditions = useMemo(() => {
    if (hasLegalAge && hasReadAndAgree && hasAgreedCookies && hasReadAndAgreeConsent) return true;
    else return false
  }, [hasLegalAge, hasReadAndAgree, hasAgreedCookies, hasReadAndAgreeConsent])

  return (
    <div className="terms-and-conditions__container">
      <LogoHeader
        dark={false}
        alternateLogo={store.brand.brand === 'carnival'}
        hideBackButton={false}
      />



      <div className="terms-and-conditions__content-container">
        <p className="terms-and-conditions__title">
          <Text id='common.termsAndConditions' />
        </p>
        <div className='separator-line' />




        <div className='terms-and-conditions__scroll-container-parent'>
          <Scrollbars
            style={{ width: '100vw', flex: 1 }}
            className="terms-and-conditions__scroll-container"
          >

            <div className="terms-and-conditions__legal-agreement--container">

              <CheckboxRow
                checkboxState={hasLegalAge}
                checkboxKey='hasLegalAge'
                textId='common.legalAge'
                onInputChange={handleChangeCheckbox}
              />

              <CheckboxRow
                checkboxState={hasReadAndAgree}
                checkboxKey='hasReadAndAgree'
                onInputChange={handleChangeCheckbox}
              >
                <span>
                  I have <strong>read and agree</strong> to the<br />
                  <span
                    className='terms-and-conditions__link'
                    onClick={handlePressTerms}
                  >
                    Terms of Service
                  </span>
                  {' & '}
                  <span
                    className='terms-and-conditions__link'
                    onClick={handlePressPrivacy}
                  >
                    Privacy Policy.
                  </span>
                </span>
              </CheckboxRow>

              <CheckboxRow
                checkboxState={hasAgreedCookies}
                checkboxKey='hasAgreedCookies'
                onInputChange={handleChangeCheckbox}
              >
                <span>
                  I <stron>agree</stron> to the storing of
                  <span
                    className='terms-and-conditions__link'
                    onClick={handlePressCookies}
                  >
                    {' Cookies '}
                  </span>
                  on my devices to enhance the site navigation and analyze site usage.
                </span>
              </CheckboxRow>

              <CheckboxRow
                checkboxState={hasReadAndAgreeConsent}
                checkboxKey='hasReadAndAgreeConsent'
                onInputChange={handleChangeCheckbox}
              >
                <span>
                  This application uses Facial Recognition to find your photos. I have <strong>read and agree</strong> to the
                  <span
                    className='terms-and-conditions__link'
                    onClick={handlePressConsent}
                  >
                    {' Consent and Written Release.'}
                  </span>
                </span>
              </CheckboxRow>



            </div>


          </Scrollbars>
        </div>






      </div>

      <button
        className={clsx(
          'terms-and-conditions__read-and-agree--button',
          store.brand?.baseConfig?.options?.buttonStyle,
          !hasAgreedTermsAndConditions && 'terms-and-conditions__disabled'
        )}
        onClick={handleReadAndAgree}
        disabled={!hasAgreedTermsAndConditions}
      >
        <Text id='common.iReadAndAgree' />
      </button>

      <PBFooter dark={false} />
    </div>
  )
});

export default TermsAndConditions;