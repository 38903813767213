import axios from 'axios';
import { getArrayBuffer } from '../utils';
import Jimp from 'jimp';
import GIFEncoder from 'gif-encoder-2';

export const getPhotoBoothPhotos = (url) => {
    return fetch(url, { method: 'HEAD' }).then((res) => res.ok ? url : false);
}

export const getPhoto = url => {
    return axios.get(url, { responseType: "blob", cache: "no-store" });
}

export const getCutPhoto = url => {
    return axios.get(url);
}

export const getCutOutPhotoFromURL = async (url, config) => {
    try {
        const res = await getPhoto(url);
        if (res.status === 200) {
            const blob = await res.data;
            const cutPhoto = getCutOutPhoto({ photo: blob, config });
            return cutPhoto;
        }
    } catch (e) {
        console.log(e)
    }
}

export const getCutOutPhoto = async ({ photo, config, useUrlBlob = true }) => {
    let urlBlob;
    if (useUrlBlob) urlBlob = URL.createObjectURL(photo);
    else urlBlob = photo;
    let resizedImage = await Jimp.read(urlBlob);
    await resizedImage.resize(Jimp.AUTO, 1920);
    let toProcess = await resizedImage.getBase64Async(Jimp.MIME_JPEG);
    // console.log('toProcess: ', toProcess)
    const fetched = await fetch(toProcess);
    const blobToSend = await fetched.blob();

    // blobToSend.lastModifiedDate = photo.headers['last-modified'];
    blobToSend.name = 'asset'
    const blobArrayBuffer = await getArrayBuffer(blobToSend);
    // console.log('blogArrayBuffer: ', blobArrayBuffer)

    // blobToSend.name = name
    const payload = new FormData();
    payload.append('asset', blobToSend)

    const axiosConfig = {
        headers: {
            Authorization: 'Bearer ' + config.Token,
            'Content-Type': 'multipart/formData'
        },
        responseType: 'blob'
    }
    try {
        const resMask = await axios.post(
            config.API.Mask,
            payload,
            axiosConfig,
        )
        // console.log('resMask: ', resMask)
        if (resMask.status === 200) {
            // const cutoutBlob = new Blob([resMask.data], { type: "image/png" })
            const cutoutBlob = await resMask.data;
            // console.log('cutoutBlob: ', cutoutBlob)
            // const resultFile = new File([resMask.data], "photo.png", { type: "image/png" });
            // console.log('resultFile: ', resultFile)
            const urlBlob = URL.createObjectURL(cutoutBlob);
            // const fileURL = URL.createObjectURL(resultFile)
            return  urlBlob;
        }
    } catch (e) {
        console.log(e)
    }

}

export const getCutOutPhotoOld = async ({ photo, config, useUrlBlob = true }) => {
    try {

        // resize Image to send
        let urlBlob;
        if (useUrlBlob) urlBlob = URL.createObjectURL(photo);
        else urlBlob = photo;
        let resizedImage = await Jimp.read(urlBlob);
        await resizedImage.resize(Jimp.AUTO, 1920);
        let toProcess = await resizedImage.getBase64Async(Jimp.MIME_JPEG);
        const fetched = await fetch(toProcess);
        const blobToSend = await fetched.blob();

        // blobToSend.lastModifiedDate = photo.headers['last-modified'];
        blobToSend.name = 'foto.jpg'
        const blobArrayBuffer = await getArrayBuffer(blobToSend);

        const axiosConfig = {
            headers: {
                Authorization: 'Bearer ' + config.Token,
                'Content-Type': 'application/json'
            }
        }

        const payload = {
            assets: ["foto.jpg"],
            effect: "bg-changer"
        }
        const resStartUpload = await axios.post(
            config.API.StartUpload,
            payload,
            axiosConfig,
        );

        const FITSToken = config.FITSToken || config.Token;
        // const FITSToken = config.FITSToken;

        const axiosConfigFITS = {
            headers: {
                Authorization: 'Bearer ' + FITSToken,
                'Content-Type': 'application/json'
            }
        }

        if (resStartUpload.status === 200 && resStartUpload.data.result["foto.jpg"]) {
            const resFetch = await fetch(resStartUpload.data.result["foto.jpg"], {
                method: 'PUT',
                headers: {
                },
                body: blobArrayBuffer
            })

            if (resFetch.status === 200) {

                const resCutOut = await axios.post(
                    config.API.Cutout,
                    {
                        "assets": [
                            {
                                "uploadUrl": resStartUpload.data.result["foto.jpg"]
                            }
                        ]
                    },
                    axiosConfig,
                );

                if (resCutOut.status === 200) {

                    if (config.Env === 'dev') {
                        const urlFromCutOut = resCutOut.data.assets[0].url;
                        const maskURL = urlFromCutOut.split('.png')[0]
                        const maskPath = maskURL.split('https://pb-assets-upload-dev.s3.amazonaws.com')[1]
                        const imageURL = resStartUpload.data.result["foto.jpg"].split('?')[0]
                        const imagePath = imageURL.split('https://pb-assets-upload-dev.s3.amazonaws.com')[1]

                        const resS3Mask = await axios.post(
                            config.API.s3Mask,
                            // {
                            //     "assets": [
                            //         {
                            //             "uploadUrl": resStartUpload.data.result["foto.jpg"]
                            //         }
                            //     ]
                            // },
                            {
                                // "assets": [
                                // {
                                "image_s3_url": 's3://pb-assets-upload-dev' + imagePath,
                                "mask_s3_url": 's3://pb-assets-upload-dev' + maskPath + '.png'
                                // }
                                // ]
                            },
                            axiosConfigFITS,
                        );

                        if (resS3Mask.status !== 200) return;
                    }


                    const urlToGet = resCutOut.data.assets[0].url;
                    // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
                    // const resFetchGetPhoto = await fetch(proxyUrl + urlToGet);
                    // const resFetchGetPhoto = await fetch(urlToGet);
                    const resCutPhoto = await getPhoto(urlToGet);
                    // const resCutPhoto = await getPhoto(resFetchGetPhoto);
                    if (resCutPhoto.status === 200) {
                        const blob = await resCutPhoto.data;
                        // saveAs(blob , "image.png");
                        const urlBlob = URL.createObjectURL(blob);
                        return urlBlob;
                    }
                }

            }
        }
    } catch (e) {
        console.log(e)
    }
}

export const registerEmail = (user, sessionId, config, brand, team = null) => {
    const axiosConfig = {
        headers: {
            Authorization: 'Bearer ' + config.Token,
            'Content-Type': 'application/json',
        }
    }

    const body = {
        campaign_id: brand.baseConfig.data.campaingId,
        list_id: config.Env === 'dev' ? brand.baseConfig.data.listIdDev : brand.baseConfig.data.listIdProd,
        name: user.name,
        first_name: user.firstName,
        last_name: user.lastName,
        zip_code: user.zipCode,
        // phone_number: "617-555-5555",
        email: user.email,
        team_selected: user.teamSelected,
        session_id: sessionId,
        // template_id: "d-9b6b51eba9dd47fab01135bdf729de7b",
        asset: config.Asset,
        brandID: brand.baseConfig.options.brand,
        team: team,
        opt_status: true
    }
    return axios.post(config.API.CollectInfo, body, axiosConfig)
}

export const uploadAsset = async (photo, config, name) => {

    try {
        let uploadedURL;
        const fetched = await fetch(photo);
        const blobToSend = await fetched.blob();

        blobToSend.name = name;
        const blobArrayBuffer = await getArrayBuffer(blobToSend);

        const axiosConfig = {
            headers: {
                Authorization: 'Bearer ' + config.Token,
                'Content-Type': 'application/json',
            }
        }

        const payload = {
            assets: [name],
        }
        const resStartUpload = await axios.post(
            config.API.StartUpload,
            payload,
            axiosConfig,
        );
        if (resStartUpload.status === 200 && resStartUpload.data.result[name]) {
            const resFetch = await fetch(resStartUpload.data.result[name], {
                method: 'PUT',
                headers: {
                },
                body: blobArrayBuffer
            })
            if (resFetch.status === 200) {
                uploadedURL = resFetch.url;
            }
        }
        return uploadedURL;
    } catch (e) {
        console.log(e)
    }
}

export const uploadRedbullAsset = async (photo, config, name) => {
    try {
        const fetched = await fetch(photo);
        const blobToSend = await fetched.blob();

        blobToSend.name = name
        const payload = new FormData();
        payload.append('file', blobToSend, name)
        const axiosConfig = {
            mode: 'no-cors',
            headers: {
                'Content-Type': 'multipart/formData'
            }
        }
        return axios.post(config.API.RedbullUpload, payload, axiosConfig)
        // return fetch(config.API.RedbullUpload, {
        //     method: 'POST',
        //     headers: {
        //     },
        //     body: payload
        // })
    } catch (e) {
        console.log(e)
        return
    }
}

export const uploadSelfieSearchNew = async ({ photo, config, overrideSystemId, overrideSessionId }) => {
    try {

        const systemId = overrideSystemId || config.SystemId;
        const sessionId = overrideSessionId || config.SessionId;
        const fetched = await fetch(photo);
        const blob = await fetched.blob();
        const file = new File([blob], "foto.jpg", { type: "image/jpeg" })

        const token = config.SearchToken || config.Token

        blob.name = 'selfie.jpg'

        let aConfig = {
            method: 'post',
            url: `${config.API.SelfieWallSearch}?systemId=${systemId}&sessionId=${sessionId}`,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'image/jpeg',
            },
            data: photo.name ? photo : file,
        };

        const resStartUpload = await axios(aConfig)
        return resStartUpload;

    } catch (e) {
        console.log(e)
    }
}

export const uploadSelfieSearch = async ({ photo, config }) => {
    try {
        let assets;
        const fetched = await fetch(photo);
        const blobToSend = await fetched.blob();

        blobToSend.name = 'selfie.jpg'
        const blobArrayBuffer = await getArrayBuffer(blobToSend);

        const token = config.ProdToken || config.Token;

        const axiosConfig = {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/formdata',
            },
            timeout: 20000
        }

        const payload = new FormData();
        payload.append('file', blobToSend, 'selfie.jpg');
        const resStartUpload = await axios.post(
            config.API.SelfieSearch,
            payload,
            axiosConfig,
        );
        return resStartUpload;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const createAnimatedGif = async ({ }) => {
    const encoder = new GIFEncoder(500, 700)
    encoder.setDelay(100)
    encoder.start()
}