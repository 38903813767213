import React from 'react';
import { observer } from 'mobx-react-lite';

import './mainContent.scss';

const MainContent = observer(() => (
    <div className="home-main-content-container">
        <div className="create-memory-container" >
            <div className="linear-background">
                <p className="promotional-text">Let's Get Started Creating<br/>Your Awesome Photo!</p>
                <img src={'assets/images/family-photo.png'} className="photo-merged"  alt='' />
                <p className="list-title">You can:</p>
                <ul>
                    <li>Add backgrounds</li>
                    <li>Add frames</li>
                    <li>Resize yourself in the image</li>
                </ul>
            </div>
        </div>
    </div>
));

export default MainContent;
