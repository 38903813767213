import React from 'react'
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx'


import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';

import './iframeDesktop.scss';

const IframeDesktop = observer(() => {

    const getSourceURL = () => {
        const isSharedHome = window.location.href.includes('/shared-home');
        const isParamPresent = window.location.href.includes('?')
        if (isSharedHome) {
            return window.location.href.split('#')[0] + '?iframe=true#/shared-desktop'
        } else if (isParamPresent) {
            return window.location.href.split('#')[0] + '&iframe=true#/'
        } else {
            return window.location.href.split('#')[0] + '?iframe=true#/'
        }
    }

    return (
        <div className='iframe-desktop-container'>
            <iframe 
                src={getSourceURL()}
                title="Fantastic Photos"
                className="iframe-desktop"
                frameBorder="0"
            />
        </div>
    )
})

export default IframeDesktop;