import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import EXIF from "exif-js";
// import { isAndroid, isMobile } from 'react-device-detect';
import Jimp from 'jimp';
import clsx from 'clsx'
// import useScreenOrientation from 'react-hook-screen-orientation'


import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { makeMergedPhoto, makeTodayCaption, resizePhoto } from '../../utils';
import { format } from 'date-fns';

import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import { v4 as uuidv4 } from 'uuid';
import { getCutOutPhoto, uploadAsset } from '../../services';
import Loading from '../../components/loading';
import Text from '../../components/text';

import './landingMenu.scss';
import { RATIO, WIDTH } from '../../styles/mixins';

const SCREEN = 'Landing_Menu';

const LandingMenu = observer(() => {
  const store = useStore();

  const { config, backgrounds, frames, captions, session, nonBoothSessionId, mergeData, mergedPhotos, baseMergeData, mixpanel, brand } = store;
  const history = useHistory();
  const { referralurl, childId, showLoader } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const createNewNonSessionId = () => {
      const newNonBoothSessionId = uuidv4();
      store.setNonBoothSessionId(newNonBoothSessionId);
    }

    if (!session) createNewNonSessionId();
  }, [store, session])

  useEffect(() => {
    const handleErrorShowing = () => {
      setTimeout(() => setShowError(false), 3000);
    }

    if (showError) handleErrorShowing();
  }, [showError])

  useEffect(() => {
    const newTodayCaption = async () => {
      const black = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'black')
      const white = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'white')
      store.setTodayCaption({
        black,
        white
      });
    }

    if (frames && captions && brand.baseConfig.options.useCaption) newTodayCaption();
  }, [store, frames, captions])

  useEffect(() => {
    const trackMixpanel = () => {
      mixpanel.track('AppOpened', {
        ...store.mixpanelBaseData,
        'Screen': SCREEN,
      });
    }

    if (store && mixpanel && mixpanel.config?.token != null) trackMixpanel();
  }, [referralurl, session, mixpanel, nonBoothSessionId, store])

  const handleTapSeeAllPhotos = () => {
    mixpanel.track('tapSeeAllPhotos', {
      ...store.mixpanelBaseData,
      'Screen': SCREEN,
    })
    window.open(store.wallStreamWebUrl, '_self')
  }

  const handleTapFindPhotos = () => {
    mixpanel.track('tapFindMyPhotos', {
      ...store.mixpanelBaseData,
      'Screen': SCREEN,
    })
    history.push('/terms')
  }

  const handleTapCreateSouvenir = () => {
    mixpanel.track('tapCreateSouvenir', {
      ...store.mixpanelBaseData,
      'Screen': SCREEN,
    })
    history.push('/fantasticphotos')
  }

  return (
    <div
      className="landing-menu-container"
      style={{ backgroundImage: `url(${brand.baseConfig.images.backgroundImage})` }}
    >
      <Loading
        display-if={isLoading || showLoader}
        color={brand.baseConfig.colors.loadingBarColor}
        backColor={brand.baseConfig.colors.loadingBarColorLight}
      />

      <LogoHeader
        dark={false}
        alternateLogo={brand.baseConfig.options.brand === 'carnival'}
        childId={childId}
        hideBackButton={true}
        showFlag={store.brand.baseConfig.options.showFlag}
      />

      <p className="landing-menu__promotional-text">
        <span className="landing-menu__title-bold" lang='pl'><Text id='common.title' /></span>
      </p>
      <p className="landing-menu__subtitle">
        <div className="landing-menu__line" />
        <div className="landing-menu__white-line" />
        <span className='landing-menu__subtitle-text'><Text id='common.subtitle' /></span>
      </p>

      <div className='landing-menu_main-content'>
        <div className='landing-menu__button-container'>
          <button
            className={clsx('landing-menu__start-button', !store.wallStreamWebUrl && 'button-disabled', 'landing-menu__stream-web-button')}
            onClick={handleTapSeeAllPhotos}
            disabled={!store.wallStreamWebUrl}
          >
            <Text id='common.streambutton' />
          </button>

          <button 
            className={clsx('landing-menu__start-button', 'landing-menu__face-search-button', store.brand.baseConfig.options.buttonStyle)}
            onClick={handleTapFindPhotos}
          >
            <Text id='common.facesearchbutton' />
          </button>

          <button 
            className={clsx('landing-menu__start-button', 'landing-menu__create-souvenir-button', store.brand.baseConfig.options.buttonStyle)} 
            onClick={handleTapCreateSouvenir}
          >
            <Text id='common.landingpagebutton' />
          </button>
        </div>


        <img
          src={store.brand.baseConfig.images.landingMenu}
          className='landing-menu__img'
          alt=''
          display-if={store.brand.baseConfig.images.landingMenu}
        />
      </div>

      <PBFooter dark={false} />
    </div>
  )
});

export default LandingMenu;

