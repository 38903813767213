import React from 'react';
import { Route } from 'react-router-dom';

import Home from '../containers/home';
import SessionUrl from '../containers/sessionUrl';
import SelectPhoto from '../containers/selectPhoto';
import EditPhoto from '../containers/editPhoto';
import Help from '../containers/help';
import OnSiteSession from '../containers/onSiteSession';
import RemoteSession from '../containers/remoteSession';
import FinalPhoto from '../containers/finalPhoto';
import IframeDesktop from '../containers/iframeDesktop/iframeDesktop';
import Terms from '../containers/terms';
import TakePhoto from '../containers/takePhoto';
import PhotoDetail from '../containers/photoDetail';
import SouvenirTicket from '../containers/souvenirTicket';
import ShippingInformation from '../containers/shippingInformation';
import Congratulations from '../containers/congratulations';
import HtmlForPDF from '../containers/htmlForPDF';
import NoMatches from '../containers/noMatches';
import ErrorScreen from '../containers/error';
import HomePreview from '../containers/homePreview';
import FinalVideo from '../containers/finalVideo';

const RoutesFP = ({ fantasticPhotos }) => {
    return (

        fantasticPhotos &&
        <>
            <Route path="/home/:referralurl" exact component={Home} />
            <Route path="/loading-home/:showLoader" exact component={Home} />
            <Route path="/fantasticphotos" exact component={Home} />
            <Route path="/fantasticphotos/:childId" exact component={Home} />
            <Route path="/sessionurl/:date/:sessionId" exact component={SessionUrl} />
            <Route path="/selectphoto" exact component={SelectPhoto} />
            <Route path="/selectphoto/:scrollTo" exact component={SelectPhoto} />
            <Route path="/finalphoto" exact component={FinalPhoto} />
            <Route path="/finalphoto/:isVideo" exact component={FinalPhoto} />
            <Route path="/editphoto/:phIx" exact component={EditPhoto} />
            <Route path="/editphoto/:phIx/:childId" exact component={EditPhoto} />
            <Route path="/help" exact component={Help} />
            <Route path="/onsitesession/:sessionId" exact component={OnSiteSession} />
            <Route path="/remotesession/:sessionId" exact component={RemoteSession} />

            <Route path="/iframe-desktop" exact component={IframeDesktop} />
            <Route path="/terms" exact component={Terms} />


            <Route path="/homePreview" exact component={HomePreview} />
            <Route path="/take-photo" exact component={TakePhoto} />
            <Route path="/photo-detail/:photoIndex" exact component={PhotoDetail} />
            <Route path="/souvenir-ticket/:photoIndex" exact component={SouvenirTicket} />
            <Route path="/shipping-information" exact component={ShippingInformation} />
            <Route path="/congratulations" exact component={Congratulations} />
            <Route path="/html-for-pdf" exact component={HtmlForPDF} />
            <Route path="/no-matches" exact component={NoMatches} />
            <Route path="/error" exact component={ErrorScreen} />

            {/* TODO move to routesFV */}
            <Route path="/final-video" exact component={FinalVideo} />


        </>
    )
}

export default RoutesFP;