import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';

import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import Text from '../../components/text';

import './error.scss'

const Error = observer(() => {
  const store = useStore();
  const history = useHistory();

  const handleRetake = () => {
    history.push(`/take-photo`)
  }

  useEffect(() => {
    const redirectHome = () => {
      history.push('/')
    }

    if (store && !store.nonBoothSessionId) redirectHome();
  }, [store])

  const showError = (error) => {
    switch (error?.error_code) {
      case 'NO_FACES':
        return <Text id='common.retakePhotoButton' />
      default:
        return <Text id='common.retakePhotoButton' />
    }
  }


  return (
    <div className='error-container'>
      <LogoHeader dark={false} showBack />
      <p className="error-title"><Text id='common.ohNoString' /></p>
      <p className='error-text'>{showError(store.error)}</p>
      <img
        src={store.userPhoto}
        className='error-image'
        alt=''
        display-if={store?.userPhoto}
      />
      <div className='error-buttons-container'>
        <button
          className={clsx('error-try-again-button', store?.brand?.baseConfig?.options?.buttonStyle)}
          onClick={handleRetake}
        >
          <Text id='common.retakePhotoButton' />
        </button>
      </div>
      <PBFooter dark={false} />
    </div>
  )
})

export default Error;