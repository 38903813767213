import React from 'react';
import './loadingScreen.scss'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import Text from '../text';
import clsx from 'clsx';

import LogoHeader from '../logoHeader';
import PBFooter from '../pBFooter';

const LoadingScreen = observer(({ color, fontColor, text, alternative = false }) => {
    const store = useStore();
    const { brand } = store;

    return (
        <div className={clsx('loading-screen-container')}>
            <div className={clsx('loading-screen-content-container')}>
                <div>
                    <LogoHeader
                        dark={false}
                        alternateLogo={brand.baseConfig.options.brand === 'retreat' || brand.baseConfig.options.brand === 'partner' || brand.baseConfig.options.brand === 'uva'}
                        centeredLogo={true}
                        hideBackButton={true}
                    />
                </div>
                {/* <img src="assets/svg/close-x.svg" className="close-x" alt=""/> */}
                <img src="assets/svg/fantastic-photos_logo-2.svg" className="fantasic-photos-logo" alt="" />

                <div className={clsx('loading-screen-content')}>
                    {
                        alternative ?
                            <p className={clsx('loading-advice-text', brand.baseConfig.colors.primary === '#ffffff' && 'contrast-loading-advice-text')}>Please wait while we find your<br />photos...</p> :
                            <p className={clsx('loading-advice-text', brand.baseConfig.colors.primary === '#ffffff' && 'contrast-loading-advice-text')}>This may take a few moments...</p>
                    }

                    <div className="loading-screen-logo-container">
                        <img src={store.brand.loadingSVG} className="loading-screen-logo" alt='' />
                    </div>
                    <div className='loading-screen-only-bar-container'>

                        <svg width="173" height="14" viewBox="0 0 173 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" width="170" height="6" rx="3" fill="#C4C4C4" />
                            <rect width="55" height="6" rx="3" fill={color} className="loading-screen-bar" />
                        </svg>
                        <p className={clsx('loading-screen-text', brand.baseConfig.colors.primary === '#ffffff' && 'contrast-loading-screen-text')}><Text id='common.preparingPhotos' /></p>

                    </div>
                </div>
                <PBFooter dark={brand.baseConfig.options.brand === 'retreat'} />
            </div>

        </div>

    )
})

export default LoadingScreen;