import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { v4 as uuidv4 } from 'uuid';

import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import { getCutOutPhoto, orderTicket } from '../../services';
import { STATES } from '../../constants/states';
// import { orderTicket } from '../../services/orderServices';

import './shippingInformation.scss'


const PhotoDetail = observer(() => {
    const store = useStore();
    const history = useHistory();
    const [photoFile, setPhotoFile] = useState()
    const { onsitePhotos } = store;
    const { photoIndex } = useParams();
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const refInput = useRef();

    const screen = { Screen: 'ShippingInformation' }

    const searchOptions = {
        types: ['address'],
        componentRestrictions: { country: "us" },
    }

    useEffect(() => {
        const redirectHome = () => {
            history.push('/')
        }

        if (store && !store.nonBoothSessionId) redirectHome();
    }, [store])

    useEffect(() => {
        const trackMixpanel = () => {
            store.mixpanel.track('ShippingInformation', {
                ...store.mixpanelBaseData,
                ...screen,
            });
        }

        if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
    }, [store])

    const validateEmail = email => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const checkForm = () => {
        const data = { ...store.shippingInfo }
        if (data.name && data.address1 && data.city && data.state && data.zip) return true
        else return false
    }

    const registerUser = async () => {
        try {
            setButtonPressed(true);
            const res = await registerEmail(store.user, store.session?.split('/')[1] || store.nonBoothSessionId, store.config, store.brand);
            store.mixpanel.track('Submit_Email', {
                ...store.mixpanelBaseData,
                'SessionID': store.nonBoothSessionId,
            });
            localStorage.setItem("RCData", JSON.stringify({ user: store.user, watermarkRemoved: true }));
            setButtonPressed(false);
            store.setWatermarkRemoved();
            store.setRemoveWatermark(true);
            store.setShowEmailRegister(false);
        } catch (e) {
            console.log(e)
        }
    }

    const getNumberOfPopulatedFields = () => {
        let number = 0;
        const data = { ...store.shippingInfo }
        data.name && number++;
        data.address1 && number++;
        data.address2 && number++;
        data.city && number++;
        data.state && number++;
        data.zip && number++;
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const orderSessionId = uuidv4();
        store.mixpanel.track('TapEnterShippingInfo', {
            ...store.mixpanelBaseData,
            ...screen,
            NumberFieldsPopulated: getNumberOfPopulatedFields(),
            OrderSessionId: orderSessionId,
        })
        try {
            const res = await orderTicket({
                config: store.config,
                imageURL: onsitePhotos[store.photoIndex].url,
                shippingInfo: { ...store.shippingInfo },
                sessionId: orderSessionId,
            })
            setIsLoading(false);
            if (res.status === 200) history.push('/congratulations')
            else handleSubmitError();
        } catch (e) {
            console.log(e)
            setIsLoading(false)
            handleSubmitError()
        }
    }

    const handleSubmitError = () => {
        store.updateShippingInfo('address1', '')
        store.updateShippingInfo('city', '')
        store.updateShippingInfo('state', '')
        store.updateShippingInfo('zip', '')
        setError(true);
        setTimeout(() => setError(false), 2000);
    }

    const handleChange = address => {
        // this.setState({ address });
        store.updateShippingInfo('address1', address)
    };

    const handleSelect = (address, placeId, suggestion) => {
        // console.log('handleSelect suggestion: ', suggestion)
        geocodeByAddress(address)
            .then(results => handleSetAddress(results, suggestion))
            // .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };

    const parseAddress = (address) => {
        let parsedAddress = {
            address1: address.split(',')[0],
            city: address.split(',')[1].slice(1),
            state: address.split(',')[2].slice(1, 3),
            zip: address.split(',')[2].slice(4)
        }
        return parsedAddress;
    }

    const parseResults = (results, suggestion) => {
        const address = {};
        const parsed = {};
        const components = results[0].address_components;
        components.forEach(element => {
            element.types.forEach(type => {
                address[type] = element.short_name;
            })
        });
        // console.log('address: ', address)
        // while (!selected) {
        //     console.log('waiting')
        // }
        if (address.street_number && address.route) {
            parsed.address1 = `${address.street_number} ${address.route}`;
        } else {
            // console.log('suggestion: ', suggestion)
            parsed.address1 = suggestion?.formattedSuggestion?.mainText;
        }
        // parsed.address1 = `${address.street_number} ${address.route}`;
        parsed.city = address.locality;
        parsed.state = address.administrative_area_level_1;
        parsed.zip = address.postal_code;
        // console.log('parsed: ', parsed)
        return parsed;
    }

    const handleSetAddress = (results, suggestion) => {
        // console.log('results: ', results)
        // const parsedAddress = parseAddress(results[0].formatted_address)
        const parsedAddress = parseResults(results, suggestion)
        // console.log('parsedAddress: ', parsedAddress)
        store.updateShippingInfo('address1', parsedAddress.address1)
        store.updateShippingInfo('city', parsedAddress.city)
        store.updateShippingInfo('state', parsedAddress.state)
        store.updateShippingInfo('zip', parsedAddress.zip)
    }

    const handleClickInput = () => {
        refInput.current.focus();
    }

    return (
        <div className='shipping-information-container'>
            <LogoHeader dark={false} showBack />
            <div className="shipping-information-content-container">
                {/* <Menu alt="" className="menu-close"  onClick={() => setIsOpen(false)}/> */}
                <p className="register-title">Enter information to RECEIVE your Souvenir Ticket </p>
                <p className='terms-advice'>Only available for shipping within the United States</p>

                <form className="form-container">
                    <div className="input-container">
                        <label className="input-label">Name</label>
                        <div className="input-row">
                            <input
                                type="text"
                                value={store.shippingInfo?.name}
                                // placeholder="John Doe" 
                                className="input-firstname"
                                onChange={event => store.updateShippingInfo('name', event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="input-container">
                        <label className="input-label">Address</label>
                        <div className="input-row">
                            <PlacesAutocomplete
                                value={store.shippingInfo.address1}
                                onChange={handleChange}
                                onSelect={handleSelect}
                                searchOptions={searchOptions}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div style={{ zIndex: 10 }}>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: 'input-firstname',
                                            })}
                                            ref={refInput}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                    </div>
                    <div className="input-container">
                        <label className="input-label">Address 2</label>
                        <div className="input-row">
                            <input
                                type="text"
                                value={store.shippingInfo.address2}
                                className={clsx('input-firstname')}
                                onChange={event => store.updateShippingInfo('address2', event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="input-container">
                        <label className="input-label">City</label>
                        <div className="input-row" onClick={handleClickInput}>
                            <input
                                disabled
                                type="text"
                                value={store.shippingInfo.city}
                                className={clsx('input-firstname')}
                                onChange={event => store.updateShippingInfo('city', event.target.value)}
                            />
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className="input-container input-state-container">
                            <label className="input-label">State</label>
                            <div className="input-row" onClick={handleClickInput}>
                                <select
                                    disabled
                                    className="input-firstname input-state"
                                    onChange={event => store.updateShippingInfo('state', event.target.value)}
                                    value={store.shippingInfo.state}
                                    autoComplete='state'
                                >
                                    <option value="" disabled selected hidden></option>
                                    {STATES.map(state => <option value={state.abbreviation}>{state.abbreviation}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="input-container input-zip-code-container">
                            <label className="input-label">Zip Code</label>
                            <div className="input-row" onClick={handleClickInput}>
                                <input
                                    disabled
                                    type="text"
                                    value={store.shippingInfo?.zip}
                                    className="input-firstname input-zip-code"
                                    onChange={event => store.updateShippingInfo('zip', event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <div className='submit-container'>
                    <img
                        display-if={loading && !error}
                        src="assets/gif/loading-spinner.gif"
                        alt=""
                        className="loading-spinner"
                    />
                    <div
                        display-if={!loading && !error}
                        className={clsx('submit-button', checkForm() && 'submit-button-available')}
                        onClick={() => checkForm() && handleSubmit()}
                    >
                        <span className="submit-button-text">Enter</span>
                    </div>
                    <p className='error-advice' display-if={error}>Invalid Order<br />try again</p>
                </div>
                <p className='terms-advice'>Use of this site implies agreement to our Terms & Conditions and Privacy Policy</p>
            </div>

            <PBFooter dark={false} />
        </div>
    )
})

export default PhotoDetail;