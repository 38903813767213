import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';

import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';

import './congratulations.scss'

const Congratulations = observer(() => {
    const store = useStore();
    const history = useHistory();
    const [photoFile, setPhotoFile] = useState()
    const { onsitePhotos } = store;
    const { photoIndex } = useParams();

    const screen = { Screen: 'Congratulations' }

    useEffect(() => {
        const redirectHome = () => {
            history.push('/')
        }

        if (store && !store.nonBoothSessionId) redirectHome();
    },[store])

    useEffect(() => {
        const trackMixpanel = () => {
            store.mixpanel.track('Congratulations', {
                ...store.mixpanelBaseData,
                ...screen,
            });
        }

        if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
    }, [store])

    useEffect(() => {
        if (onsitePhotos[store.photoIndex].url) 
            generateFile(onsitePhotos[store.photoIndex].url);
    }, [onsitePhotos]);

    const generateFile = async (photo) => {
        const fetched = await fetch(photo);
        const blob = await fetched.blob();
        const file = new File([blob], "photo.jpg", { type: "image/jpeg" })
        setPhotoFile(file);
    }
    const handleShare = () => {
        store.mixpanel.track('TapShare', {
            ...store.mixpanelBaseData,
            ...screen
        })
        navigator.canShare({ files: [store.mergedTicketFile] }) && navigator.share({ files: [store.mergedTicketFile] })
        generateNewMergedTicketFile ();
    }

    const generateNewMergedTicketFile = async () => {            
        const fetched = await fetch(store.mergedTicket);
        const blob = await fetched.blob();
        const file = new File([blob], "PGA_2022_Souvenir.jpg", { type: "image/jpeg" })
        store.setMergedTicketFile(file);
    }

    const handleMorePhotos = () => {
        store.mixpanel.track('TapMorePhotos', {
            ...store.mixpanelBaseData,
            ...screen
        })
        history.push('/select-favorite-photo')
    }

    const handleTapSouvenirTicket = () => {
        store.mixpanel.track('TapTicket', {
            ...store.mixpanelBaseData,
            ...screen,
            PhotoIndex: store.photoIndex,
        });
    }

    return (
        <div className='congratulations-container'>
            <LogoHeader dark={false} hideBackButton />
            <p className='congratulations-title'>Congratulations</p>
            <p className='congratulations-subtitle'>Your souvenir ticket<br/>will arrive within 14 days</p>
            <div className='congratulations-photomontage-container' onClick={handleTapSouvenirTicket}>
                {/* <img
                    src={onsitePhotos[store.photoIndex || 0].url}
                    className='congratulations-image'
                    alt=''
                />
                <img 
                    src={store.brand.baseConfig.images.ticketTemplate}
                    className='congratulations-template'
                    alt=''
                /> */}
                <img 
                    src={store.mergedTicket}
                    className='congratulations-template'
                    alt=''
                />
            </div>
            <div className='congratulations-buttons-container'>
                <button
                    className="congratulations-save-share"
                    onClick={handleShare}
                    // onClick={() => navigator.canShare({ files: [store.mergedTicketFile] }) && navigator.share({ files: [store.mergedTicketFile] })}
                >
                    SAVE & SHARE
                </button>
                <button
                    className="congratulations-more-photos"
                    onClick={handleMorePhotos}
                >
                    MORE PHOTOS
                </button>
            </div>
            <PBFooter dark={false} />
        </div>
    )
})

export default Congratulations;