import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { OsTypes, isAndroid, isIOS, isMacOs } from 'react-device-detect'
import { webToMp4 } from '../../utils';

import './videoMontage.scss'

let mediaRecorder;
let recordedChunks;

const iosOptions = {
  mimeType: 'video/webm;codecs=vp9',
  videoBitsPerSecond: 1000000
}

const androidOptions = {
  mimeType: 'video/webm;codecs=vp9',
  ignoreMutedMedia: true
}

let frame = 1
const k = 0.01
const k2 = 0.001

const { innerWidth, innerHeight } = window;

const VideoMontage = observer(({
  startRecording,
  setStartRecording,
  ugc,
  videoSrc,
  frameSrc,
  frameAltSrc,
  xAxis,
  yAxis,
  modScale,
  setIsLoading,
  captionFrameSrc,
  isAltFrameActive,
  bgIndex,
  frIndex,
  phIx,
  onFinishVideo
}) => {
  const videoRef = useRef(null);
  const imgRef = useRef(null)
  const frameRef = useRef(null);
  const captionFrameRef = useRef(null);
  const canvasRef = useRef(null);
  const canvas2Ref = useRef(null);

  const store = useStore();
  const { videoRes } = store;

  const [photoFile, setPhotoFile] = useState();
  const [recording, setRecording] = useState(false);
  const [finalRecording, setFinalRecording] = useState(false);
  const [bFrame, setBFrame] = useState(1);
  const width = videoRes;
  const height = videoRes;

  let frame = 1

  useEffect(() => {
    if (startRecording) {
      setTimeout(() => record(), 500);
      setIsLoading(true)
    }
  }, [startRecording])

  const generateFile = async (photo) => {
    const fetched = await fetch(photo);
    const blob = await fetched.blob();
    const file = new File([blob], "foto.jpg", { type: "image/jpeg" })
    setPhotoFile(file);
    store.setUserPhotoFile(file);
  }

  useEffect(() => {
    if (recording) {
      let canvas = canvasRef.current
      const stream = canvas.captureStream(15);
      mediaRecorder = new MediaRecorder(stream, {
        // mimeType: isIOS ? 'video/mp4;codecs=avc1' : 'video/webm;codecs=vp9',
        mimeType: (isIOS || isMacOs) ? 'video/mp4;codecs=avc1' : 'video/webm;codecs=avc1',
        videoBitsPerSecond: 1000000
      });
      recordedChunks = [];
      mediaRecorder.ondataavailable = e => {
        if (e.data.size > 0) {
          recordedChunks.push(e.data);
        }
      };
      mediaRecorder.start(1000);

    }
  }, [recording])

  const finalizeRecording = async () => {
    setFinalRecording(false)
    if (mediaRecorder && mediaRecorder.state !== 'inactive') mediaRecorder.stop();
    setTimeout( async () => {
      // try {
        const blob = new Blob(recordedChunks, {
          type: (isIOS || isMacOs) ? "video/mp4" : "video/webm"
        });
  
        let newBlob
        // if (!(isIOS || isMacOs)) {
        //   newBlob = await webToMp4(blob)
        // }
  
        // console.log('newBlob: ', newBlob)
  
        // let mp4Blob
  
        // if (isIOS) {
        //   mp4Blob = webToMp4(blob)
        //   console.log(mp4Blob)
        // }
        setIsLoading(false)
  
        let updatedMergeData = [...store.mergeData];
        updatedMergeData[phIx].position.x = xAxis;
        updatedMergeData[phIx].position.y = yAxis;
        updatedMergeData[phIx].scale = modScale;
        updatedMergeData[phIx].background = bgIndex;
        updatedMergeData[phIx].frame = frIndex;
        store.setMergeData(updatedMergeData);
  
        store.setVideoSouvenir(newBlob || blob);
        // const url = URL.createObjectURL(blob);
        // const a = document.createElement("a");
        // a.href = url;
        // a.download = isIOS ? "recording.mp4" : "recording.webm";
        // a.click();
        // URL.revokeObjectURL(url);
        frame = 1;
  
        onFinishVideo();
      // } catch (e) {
      //   console.log(e)
      //   setIsLoading(false)
      // }

    }, 0);
  }

  useEffect(() => {

    if (finalRecording) {
      finalizeRecording()
    }
  }, [finalRecording])

  const record = () => {
    let video = videoRef.current;
    video.play()
    setRecording(true)
    paintToCanvas()
    setTimeout(() => {
      setRecording(false)
      setFinalRecording(true)
      setStartRecording(false)
    }, 15000)
  };

  const paintToCanvas = () => {
    try {
      frame++
      setBFrame(frame)
      let video = videoRef.current;
      let image = imgRef.current;
      let foreground = frameRef.current;
      let captionFrame
      if (captionFrameSrc) captionFrame = captionFrameRef.current;
      let canvas = canvasRef.current;
      let ctx = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(video, 0, 0, width, height);
      ctx.drawImage(image, 0, 0, width, height);
      ctx.drawImage(foreground, 0, 0, width, height);
      // captionFrame && ctx.drawImage(captionFrame, 0, 0, width, height);

      if (frame < 333) setTimeout(() => paintToCanvas(), 1000 / 15)

    } catch (e) {
      console.log(e)
    }
  }


  const ugcStyles = useMemo(() => {
    return {
      position: 'absolute',
      top: yAxis,
      left: xAxis,
      transform: `scale(${modScale})`
    }
  }, [yAxis, xAxis, modScale])

  return (
    <div
      className='final-video-montage-container'
      style={{ width: `${videoRes}px`, height: `${videoRes}px` }}
    >
      <div className={clsx('final-video-video-container')} >
        <video
          ref={videoRef}
          src={videoSrc}
          className="final-video-montage-video"
          playsInline
          muted
        />
        <img
          ref={frameRef}
          src={(isAltFrameActive && frameAltSrc) || frameSrc}
          className={clsx('final-video-montage-video')}
          alt=''
        />
        {/* <img
          ref={captionFrameRef}
          src={captionFrameSrc}
          className={clsx('final-video-montage-video')}
          alt=''
        /> */}
        <div className='final-video-montage-ugc-container'>
          <img
            ref={imgRef}
            src={ugc}
            className={clsx('final-video-montage-ugc')}
            alt=''
          />
          <canvas ref={canvas2Ref} width={0} height={0} className='canvas' style={{ zIndex: 199, display: 'block' }}></canvas>
        </div>
      </div>
      <canvas ref={canvasRef} width={videoRes} height={videoRes} className='canvas'></canvas>
    </div>
  )
})

export default VideoMontage;