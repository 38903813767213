import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import clsx from 'clsx';

import './retreatPromotionalContent.scss'
import FantasticPhotos from './fantasticPhotos';

const PromotionalContent = observer(() => {
    const store = useStore();
    const { brand } = store;
    return (
        <div className="retreat__promotional-content-container">
            <div className="retreat__promotional-group">
                <p className="retreat__promotional-text">
                    PHOTO BUTLER
                </p>
                <p className="retreat__company-retreat">
                    COMPANY RETREAT 2021
                </p>
            </div>
            <div className="retreat__line" />
            <img src="assets/brands/retreat/stone-houses.png" className="retreat__stone-houses" alt="" />
            <div className="retreat__photo-container">
                <img src={brand.baseConfig.images.promoStrip} className="retreat__photo" alt='' />
            </div>
            <FantasticPhotos color={brand.baseConfig.colors.fantasticPhotosColor} />
            <p className="retreat__fun-line">
                it's fun to be a <span className="retreat__fun-line-fan">fan</span>
            </p>
        </div>
    )
});

export default PromotionalContent;