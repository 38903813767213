import React from 'react';
import './loading.scss'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import Text from '../text';
import clsx from 'clsx';

const Loading = ({ color, fontColor, text, uploadCloud = false, backColor = '#ffffff' }) => {
    const store = useStore();

    return (
        <div className={clsx('loading-bar-container', uploadCloud && 'loading-bar-container-cloud')}>
            <img
                src={store.brand.baseConfig.images.loadingBackground}
                className='loading-background'
                alt=''
            />
            <div className='loading-background'/>
            <div className={clsx('loading-bar-content', uploadCloud && 'loading-bar-content-cloud')}>
                <div className="loading-logo-container">
                    <img src={store.brand.baseConfig.images.loadingSVG} className="loading-logo" alt='' />
                </div>
                <div className='loading-only-bar-container'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="193.325" height="8" viewBox="0 0 193.325 6.74">
                        <g transform="translate(0 -0.164)">
                            <g transform="translate(0)">
                                <g transform="translate(0 0.164)" fill={backColor} stroke={color} strokeWidth="1">
                                    <rect width="193.325" height="6.74" rx="3.37" stroke="none" />
                                    <rect x="0.5" y="0.5" width="192.325" height="5.74" rx="2.87" fill="none" />
                                </g>
                                <g className="loading-bar">
                                    <g transform="translate(1.65 2.164)" fill={color}>
                                        <path d="M 67.19595336914062 2.500741004943848 L 2.337250947952271 2.500741004943848 C 1.433411002159119 2.500741004943848 0.8938009738922119 2.129371166229248 0.8938009738922119 1.507321119308472 C 0.8938009738922119 0.8765711188316345 1.433411002159119 0.500001072883606 2.337250947952271 0.500001072883606 L 67.19595336914062 0.500001072883606 C 68.27867126464844 0.500001072883606 69.033203125 1.02721107006073 69.033203125 1.500371098518372 C 69.033203125 1.973531126976013 68.27867126464844 2.500741004943848 67.19595336914062 2.500741004943848 Z" stroke="none" />
                                        <path d="M 2.337249755859375 1.000001192092896 C 2.053581237792969 1.000001192092896 1.393798828125 1.049431085586548 1.393798828125 1.507321119308472 C 1.393798828125 1.669471144676208 1.461639404296875 1.746661067008972 1.520744323730469 1.795791149139404 C 1.677413940429688 1.926041126251221 1.975021362304688 2.000741004943848 2.337249755859375 2.000741004943848 L 67.19595336914062 2.000741004943848 C 68.04981231689453 2.000741004943848 68.50025177001953 1.625085949897766 68.53272247314453 1.500371098518372 C 68.50025177001953 1.375656247138977 68.04981231689453 1.000001192092896 67.19595336914062 1.000001192092896 L 2.337249755859375 1.000001192092896 M 2.337249755859375 1.192092895507812e-06 L 67.19595336914062 1.192092895507812e-06 C 68.48677825927734 1.192092895507812e-06 69.533203125 0.6717410087585449 69.533203125 1.500371098518372 C 69.533203125 2.329000949859619 68.48677825927734 3.000741004943848 67.19595336914062 3.000741004943848 L 2.337249755859375 3.000741004943848 C 1.046417236328125 3.000741004943848 0.393798828125 2.335951089859009 0.393798828125 1.507321119308472 C 0.393798828125 0.6786911487579346 1.046417236328125 1.192092895507812e-06 2.337249755859375 1.192092895507812e-06 Z" stroke="none" fill={color} />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <p className="loading-text" style={{ color: fontColor, textTransform: store.brand.textTransform }}><Text id='common.loadingString' /></p>
            </div>
        </div>

    )
}

export default Loading;

