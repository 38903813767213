import React, { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores/store';
import { useHistory, useParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import clsx from 'clsx';
import LogoHeader from 'components/logoHeader';
import PBFooter from 'components/pBFooter';
import Text from 'components/text';

import './userPhoto.scss'

const UserPhoto = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { onsitePhotos } = store;
  const { photoIndex } = useParams();

  const screen = { Screen: 'UserPhoto' }

  const callback = useCallback(event => {
    trackMixpanelHardPress();
  }, []);

  const bind = useLongPress(callback, {
    threshold: 200,
    captureEvent: true,
    detect: 'both',
  });

  useEffect(() => {
    const redirectHome = () => {
      history.push('/')
    }

    if (store && !store.nonBoothSessionId) redirectHome();
  }, [store])

  useEffect(() => {
    const trackMixpanel = () => {
      store.mixpanel.track('PageOpened', {
        ...store.mixpanelBaseData,
        ...screen,
      });
    }

    if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
  }, [store])

  const handlePressPhoto = () => {
    store.mixpanel.track('TapPhotoDetail', {
      ...store.mixpanelBaseData,
      ...screen
    })
  }

  const trackMixpanelHardPress = () => {
    store.mixpanel.track('LongTapFullPhoto', {
      ...store.mixpanelBaseData,
      ...screen,
      PhotoIndex: photoIndex,
    });
  }

  const handleNext = () => {
    history.push('/select-your-card')
  }


  return (
    <div className='user-photo__container'>
      <LogoHeader dark={false} showBack />

      <span className='user-photo__title'>
        <Text id='common.lookingGreat' />
      </span>
      <div className='separator-line' />

      <div className='user-photo__image--container'>
        <img
          src={onsitePhotos[photoIndex || 0].url}
          className='user-photo__image'
          alt=''
          onClick={handlePressPhoto}
          {...bind}
        />
      </div>

      <button
        className={clsx('user-photo__begin-button', store.brand?.baseConfig?.options?.buttonStyle)}
        onClick={handleNext}
      >
        <Text id='common.next' />
      </button>

      <PBFooter dark={false} />
    </div>
  )
})

export default UserPhoto;