import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react-lite';
import { registerEmail } from '../../services';
import { useStore } from '../../stores/store';
import { ReactComponent as Menu } from './menu.svg';
import clsx from 'clsx';

import emptyCheck from './empty-check.svg'
import squareCheck from './square-check.svg'

import './emailModal.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  }
};



export const EmailModal = observer(({ isOpen, setIsOpen }) => {
  const store = useStore();
  const [agree, setAgree] = useState(false)
  const [buttonPressed, setButtonPressed] = useState(false);

  useEffect(() => {
    const trackMixpanel = () => {
      store.mixpanel.track('View_Email_Capture', {
        'SessionID': store.nonBoothSessionId,
      });
    }

    if (isOpen && store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
  }, [store, isOpen])

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const checkForm = () => {
    if (store.user?.name && validateEmail(store.user?.email) && agree)
      return true;
    else return false;
  }

  const registerUser = async () => {
    try {
      setButtonPressed(true);
      const res = await registerEmail(store.user, store.session?.split('/')[1] || store.nonBoothSessionId, store.config, store.brand);
      store.mixpanel.track('Submit_Email', {
        'SessionID': store.nonBoothSessionId,
      });
      localStorage.setItem("RCData", JSON.stringify({ user: store.user, watermarkRemoved: true }));
      setButtonPressed(false);
      store.setWatermarkRemoved();
      store.setRemoveWatermark(true);
      store.setShowEmailRegister(false);
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      class="email-modal"
      id="modal1"
      style={customStyles}
      className="modal-container"
      overlayClassName="modal-overlay transparent-overlay"
      shouldCloseOnOverlayClick={true}
    >

      <div className="modal-content">
        {/* <Menu alt="" className="menu-close"  onClick={() => setIsOpen(false)}/> */}
        <p className="register-title">ENTER YOUR INFORMATION<br />TO ACCESS YOUR PHOTOS</p>

        <form className="form-container">
          <div className="input-container">
            <label className="input-label">Name<span className="required">*</span></label>
            <div className="input-row">
              <input
                type="text"
                value={store.user?.firstName}
                placeholder="John Doe"
                className="input-firstname"
                onChange={event => store.setName(event.target.value)}
              />
              {/* <img src="assets/svg/circle-check.svg" alt="" display-if={store.user?.name} /> */}
            </div>
          </div>
          <div className="input-container">
            <label className="input-label">Email<span className="required">*</span></label>
            <div className="input-row">
              <input
                type="text"
                value={store.user?.email}
                placeholder="johndoe@gmail.com"
                className={clsx('input-firstname', (!validateEmail(store.user?.email) && store.user?.email) && 'input-wrong-state')}
                onChange={event => store.setEmail(event.target.value)}
              />
              {/* <img src="assets/svg/circle-check.svg" alt="" display-if={validateEmail(store.user?.email)} /> */}
              <img src="assets/svg/circle-wrong.svg" alt="" display-if={!validateEmail(store.user?.email) && store.user?.email} />
            </div>
          </div>
          <div className="input-container">
            <label className="input-label">Zip Code (Optional)</label>
            <div className="input-row">
              <input type="text" value={store.user?.lastName} placeholder="92134" className="input-firstname" onChange={event => store.setZipCode(event.target.value)} />
              {/* <img src="assets/svg/circle-check.svg" alt="" display-if={store.user?.zipCode} /> */}
            </div>
          </div>
        </form>

        <div className="consent-container">
          <div onClick={() => setAgree(!agree)} className="consent-check-container">
            {
              agree ?
                <img src={squareCheck} alt="" className="consent-check" /> :
                <img src={emptyCheck} alt="" className="consent-check" />
            }
          </div>
          <p className="agree-text">{store.brand.emailAgrement}<span className="agree-text-link">Photo Butler Privacy Policy</span>.</p>
        </div>

        <div className={clsx('submit-button', checkForm() && 'submit-button-available', buttonPressed && 'edit-pressed')} onClick={() => checkForm() && registerUser()}>
          <span className="submit-button-text">Enter</span>
        </div>
      </div>

    </Modal>
  )
})

export default EmailModal;