import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

import SelectPhoto from '../containers/selectPhoto';
import SelectFavoritePhoto from '../containers/selectFavoritePhoto';
import FaceSearchHome from '../containers/facialSearch';
import IframeDesktop from '../containers/iframeDesktop/iframeDesktop';
import Terms from '../containers/terms';
import TakePhoto from '../containers/takePhoto';
import PhotoDetail from '../containers/photoDetail';
import NoMatches from '../containers/noMatches';
import ErrorScreen from '../containers/error';
import FaceSearchMenu from '../containers/facialSearchMenu';

const RoutesFS = ({ faceSearch }) => {
    return (
        faceSearch &&
        <>
            <Route path="/selectphoto" exact component={SelectPhoto} />
            <Route path="/selectphoto/:scrollTo" exact component={SelectPhoto} />
            <Route path="/iframe-desktop" exact component={IframeDesktop} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/take-photo" exact component={TakePhoto} />
            <Route path="/select-favorite-photo" exact component={SelectFavoritePhoto} />
            <Route path="/photo-detail/:photoIndex" exact component={PhotoDetail} />
            <Route path="/no-matches" exact component={NoMatches} />
            <Route path="/error" exact component={ErrorScreen} />
            <Route path="/face-search" exact component={FaceSearchHome} />
            <Route path="/face-search-menu" exact component={FaceSearchMenu} />

        </>
    )
}

export default RoutesFS;