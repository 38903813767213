import React from 'react';
import { observer } from 'mobx-react-lite';
import PatriotsPromotionalContent from '../../components/patriotsPromotionalContent';
import RetreatPromotionalContent from '../../components/retreatPromotionalContent';
import PartnerPromotionalContent from '../../components/partnerPromotionalContent';
import RyderCupPromotionalContent from '../../components/ryderCupPromotionalContent';
import FenwaybowlPromotionalContent from '../../components/fenwaybowlPromotionalContent';


const CustomContent = observer(({ brand }) => {

    const renderBrandContent = (brand) => {
        switch (brand) {
            case 'patriots':
                return <PatriotsPromotionalContent />
            case 'retreat':
                return <RetreatPromotionalContent />
            case 'partner':
                return <PartnerPromotionalContent />
            case 'rydercup':
                return <RyderCupPromotionalContent />
            // case 'fenwaybowl':
            //     return <FenwaybowlPromotionalContent />
            default:
                return <div></div>;
        }
    }

    return renderBrandContent(brand);
});

export default CustomContent;