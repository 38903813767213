import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import Text from 'components/text';

import './welcome-user.scss'

const WelcomeUser = observer(({ bookingData }) => {


  return (
    <div className="welcome-user__container">
      <span className='welcome-user__title'>
        <Text id='common.welcome' />
        <span> {bookingData.first_name} {bookingData.last_name}</span>
      </span>
      <div className='separator-line' />

      <div className='welcome-user__form--container'>

        <div className='welcome-user__form--row'>
          <div className='welcome-user__form--row-column'>
            <span>Hotel:</span>
            <span className='welcome-user__form--data'>Oracle Industry Lab</span>
          </div>
        </div>

        <div className='welcome-user__form--row'>
          <div className='welcome-user__form--row-column'>
            <span>Check-in:</span>
            <span className='welcome-user__form--data'>{bookingData.check_in_date}</span>
          </div>
          <div className='welcome-user__form--row-column'>
            <span>Check-out:</span>
            <span className='welcome-user__form--data'>{bookingData.check_out_date}</span>
          </div>
        </div>

      </div>

      <div className='welcome-user__advice--container'>
        <span><Text id='common.ourKiosksUse' markup/></span>
      </div>
    </div>
  )
});

export default WelcomeUser;