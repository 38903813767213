import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import clsx from 'clsx';

import './promotionalContent.scss'
import FantasticPhotos from './fantasticPhotos';

const PromotionalContent = observer(() => {
    const store = useStore();
    const { brand } = store;
    return (
        <div className="promotional-content-container">
            <div className="promotional-group">
                <p className="promotional-text" style={{ textTransform: brand.baseConfig.options.textTransform }}>{brand.baseConfig.strings.title1}</p>
                <FantasticPhotos color={brand.baseConfig.colors.fantasticPhotosColor} />
            </div>
            <div className="strip-merged-container">
                <img src={brand.baseConfig.images.promoStrip} className="strip-merged" alt='' />
            </div>
        </div>
    )
});

export default PromotionalContent;