import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import kjua from 'kjua'
import clsx from 'clsx';

import './appDesktop.scss';

const AppDesktop = observer(() => {
    const store = useStore();
    const { brand, config } = store;

    const [qrCode, setQrCode] = useState(null);

    useEffect(() => {
        const generateQR = () => {
            const { src } = kjua({
                ecLevel: 'H',
                rounded: 100,
                mSize: 20,
                size: 800,
                text: window.location.href,
            })
            setQrCode(src)
        }

        if (!brand.baseConfig.images.qrCode) generateQR()
    }, [])

    return (
        <div className="desktop-container">
            <div className="content-container">
                <div className={clsx(qrCode && 'qrCode-wrap')}>
                    <img src={qrCode ? qrCode : brand?.baseConfig?.images?.qrCode} className={clsx('qr', qrCode && 'qrCode')} alt="" />
                </div>
                <p className="advice-text">{brand?.baseConfig?.strings?.desktopCopy ? brand.baseConfig.strings.desktopCopy : 'THIS FANTASTIC PHOTOS'}<br />EXPERIENCE IS ONLY AVAILABLE<br />ON A MOBILE DEVICE</p>
                <p className="second-line">SCAN THE QR CODE WITH YOUR PHONE TO CONTINUE</p>
                <img src="assets/brands/rydercup/pb-logo-white-home.svg" className="logo-home" alt="" />
            </div>
            <div className="image-container">
                <img src={brand?.baseConfig?.images?.desktopImage} className="hole-image" alt="" display-if={brand?.baseConfig?.images?.desktopImage} />
                <img src={brand?.baseConfig?.images?.logo} className="rc-logo" alt="" />
            </div>
        </div>
    )
})

export default AppDesktop;