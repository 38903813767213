import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Scrollbars } from 'rc-scrollbars';
import { useStore } from '../../stores/store';
import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import Loading from '../../components/loading';
import clsx from 'clsx';
import Text from '../../components/text';

import './terms.scss'

const SCREEN = 'terms';

const Terms = observer(() => {
  const store = useStore();
  const history = useHistory();

  useEffect(() => {
    const redirectHome = () => {
      history.push('/')
    }

    if (store && !store.nonBoothSessionId) redirectHome();
  }, [store])

  useEffect(() => {
    const trackMixpanel = () => {
      store.mixpanel.track('LandingTerms', {
        ...store.mixpanelBaseData,
        'Screen': SCREEN,
      });
    }

    if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
  }, [store])

  const onAccept = () => {
    store.mixpanel.track('tapAccept', {
      ...store.mixpanelBaseData,
      'Screen': SCREEN,
    });
    // store.setTermsAccepted(true)
    history.push('/take-photo');
  }

  const onDecline = () => {
    store.mixpanel.track('tapDecline', {
      ...store.mixpanelBaseData,
      'Screen': SCREEN,
    });
    history.push('/');
  }

  return (
    <div className="terms-container">
      <LogoHeader
        dark={false}
        alternateLogo={store.brand.brand === 'carnival'}
        hideBackButton={false}
      />


      <div className="terms-content-container">
        <p
          className="terms-title"
        >
          <Text id='common.facialString' /></p>


        <div className='terms-scroll-container-parent'>
          <Scrollbars
            style={{ width: '90vw', height: '40vh' }}
            className="terms-scroll-container"
          >
            <div className="terms-scroll-content-container">
              <p className="terms-paragraph">
                <Text id='common.terms' markup />
              </p>
            </div>
          </Scrollbars>
        </div>
        <div className='terms-buttons-container'>
          <button
            className={clsx('terms-decline-button', store.brand?.baseConfig?.options?.buttonStyle)}
            onClick={onDecline}
          >
            <Text id='common.declineButton' />
          </button>
          <button
            className={clsx('terms-agree-button', store.brand?.baseConfig?.options?.buttonStyle)}
            onClick={onAccept}
          >
            <Text id='common.acceptButton' />
          </button>
        </div>
      </div>

      <PBFooter dark={false} />
    </div>
  )
});

export default Terms;