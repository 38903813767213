import React, { createContext, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { observable } from 'mobx'
import mixpanel from 'mixpanel-browser'
import { makeTodayCaption } from '../utils'
//import brand from 'brand/config.js';
//import childBrands from 'brand/childConfig.js';
import { lang } from './lang'
import { getWallEventInfo } from 'services';
import userPhoto from 'assets/user-photo.png';
import userPhotoOriginal from 'assets/user-photo-original.png'
import photomontage from 'assets/photomontage.jpg';
import { UNIT } from 'constants';

const newMergedPhotos = () => (
  [
    { photo: null, mergeData: {} },
    { photo: null, mergeData: {} },
    { photo: null, mergeData: {} },
    { photo: null, mergeData: {} },
  ]
)

const user = {
  name: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  email: '',
  teamSelected: '',
}

const shippingInfo = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
}

const defaultMergedPhoto = {
  photo: photomontage,
  mergeData: { ...window.MERGE_DATA[0] }
}

mixpanel.init(window.PB_ENV.Mixpanel.Token)

let urlSearchParams = new URLSearchParams(window.location.search);
export const store = observable({

  distance: urlSearchParams.get('distance')?.replace('/', '') || '',

  unit: urlSearchParams.get('unit')?.replace('/', '') || 'mi',

  pid: urlSearchParams.get('pid'),

  forceHorizontal: urlSearchParams.get('h'),

  homelogo: urlSearchParams.get('homelogo'),

  videoRes: 1080,
  setVideoRes: () => {
    const video = urlSearchParams.get('video')?.replace('/', '')
    if (!video) return;
    store.videoRes = parseInt(video) || 1080;
  },

  localizedDistance: {},
  setLocalizedDistance: () => {
    let localizedDistance = {};
    let distance = store.distance;
    let unit = '';
    let shortUnit = '';
    if (store.unit === UNIT.MILES) {
      distance = store.distance.replace(',', '.')
      unit = 'MILES';
      shortUnit = 'MI';
    } else if (store.unit === UNIT.KM) {
      distance = store.distance.replace('.', ',');
      unit = 'KM';
      shortUnit = 'KM';
    } else {
      return
    }
    localizedDistance.distance = distance;
    localizedDistance.unit = unit;
    localizedDistance.shortUnit = shortUnit;
    store.localizedDistance = { ...localizedDistance }
  },

  captionsArray: [''],
  setCaptionsArray: (captions) => {
    store.captionsArray = [...captions]
  },

  lang: lang,
  updateLangInfo: (field, value) => {
    store.lang[field] = value
  },

  changeLang: (lang) => {
    store.lang.currentLang = lang;
  },

  acceptCookies: false,
  setAcceptCookies: (value) => {
    store.acceptCookies = value;
  },

  photoEdited: true,
  setPhotoEdited: (value) => {
    store.photoEdited = value;
  },

  showEmailRegister: false,
  setShowEmailRegister: (state) => {
    store.showEmailRegister = state;
  },

  user: user,
  setName: (value) => {
    store.user.name = value;
  },
  setFirstName: (value) => {
    store.user.firstName = value;
  },
  setLastName: (value) => {
    store.user.lastName = value;
  },
  setZipCode: (value) => {
    store.user.zipCode = value;
  },
  setEmail: (value) => {
    store.user.email = value;
  },
  setUser: (value) => {
    store.user = { ...value };
  },

  roomLastName: '',
  setRoomLastName: (value) => {
    store.roomLastName = value;
  },
  room: null,
  setRoom: (value) => {
    store.room = value;
  },

  setTeamSelected: (value) => {
    store.user.teamSelected = value;
  },

  shippingInfo: shippingInfo,
  updateShippingInfo: (field, value) => {
    store.shippingInfo[field] = value;
  },

  watermarkRemoved: false,
  setWatermarkRemoved: () => {
    store.watermarkRemoved = true;
  },

  removeWatermark: false,
  setRemoveWatermark: (value) => {
    store.removeWatermark = value;
  },

  loyaltyMember: urlSearchParams.get('loyaltyMember'),
  setLoyaltyMember: (value) => {
    store.loyaltyMember = value;
  },

  purchaseComplete: false,
  setPurchaseComplete: (value) => {
    store.purchaseComplete = value
  },

  brand: window.BRAND,
  setBrand: (brand) => {
    store.brand = brand;
  },
  updateBrandInfo: (instance, typeOption, field, value) => {
    store.brand[instance][typeOption][field] = value;
  },

  cssVariables: window.CSS_VARIABLES,
  setCssVariables: (cssVariables) => {
    store.cssVariables = cssVariables;
  },

  childBrands: null,

  config: window.PB_ENV,

  backgrounds: window.BACKGROUNDS.filter((b, index) => !(!window.BRAND.baseConfig.options.noBG && index === 0)),
  setBackgrounds: (backgrounds) => {
    store.backgrounds = backgrounds.filter((b, index) => !(!store.brand.baseConfig.options.noBG && index === 0));
  },
  frames: window.FRAMES.filter((f, index) => !(!window.BRAND.baseConfig.options.noFrame && index === 0)),
  setFrames: (frames) => {
    store.frames = frames.filter((f, index) => !(!store.brand.baseConfig.options.noFrame && index === 0));
  },

  captions: window.CAPTIONS,
  setCaptions: (captions) => {
    store.captions = captions;
  },

  captionFrame: null,
  setCaptionFrame: (frame) => {
    store.captionFrame = frame;
  },

  mergeData: window.MERGE_DATA,
  setMergeData: (updatedMergeData) => {
    store.mergeData = updatedMergeData;
  },

  baseMergeData: { ...window.BASE_MERGE_DATA },

  session: null,
  setSession: (newSession) => {
    store.session = newSession;
  },

  nonBoothSessionId: null,
  setNonBoothSessionId: (newNonBoothSessionId) => {
    store.nonBoothSessionId = newNonBoothSessionId;
  },

  onsitePhotos: [],
  setOnsitePhotos: (newPhotos) => {
    store.onsitePhotos = [...newPhotos];
  },

  selfieBlob: null,
  setSelfieBlob: (blob) => {
    store.selfieBlob = blob;
  },

  photos: [userPhoto],
  setBoothPhotos: (newPhotos) => {
    store.photos = [...newPhotos];
  },
  cleanPhotos: () => {
    store.photos = [];
  },

  resizedPhotos: [userPhotoOriginal],
  setResizedPhotos: (newPhotos) => {
    store.resizedPhotos = [...newPhotos];
  },
  cleanResizedPhotos: () => {
    store.resizedPhotos = [];
  },

  isUGCVertical: false,
  setIsUGCVertical: (value) => {
    store.isUGCVertical = value
  },

  photo16By9: userPhoto,
  setPhoto16By9: (photo) => {
    store.photo16By9 = photo;
  },
  cleanPhoto16By9: () => {
    store.photo16By9 = [];
  },

  customizedUGC: userPhoto,
  setCustomizedUGC: (photo) => {
    store.customizedUGC = photo;
  },

  defaultMergedPhoto: { ...defaultMergedPhoto },

  mergedPhotos: [defaultMergedPhoto],
  setMergedPhotos: (newMergedPhotos) => {
    store.mergedPhotos = [...newMergedPhotos];
  },

  watermarkedPhotos: [],
  setWatermarkedPhotos: (newMergedPhotos) => {
    store.watermarkedPhotos = [...newMergedPhotos];
  },

  mixpanel: mixpanel,

  mixpanelBaseData: {
    'Brand': window.BRAND?.baseConfig.options.brand,
    'Event': window.BRAND?.baseConfig.options.event,
    'Environment': window.PB_ENV.Env,
    'Client': window.PB_ENV?.Mixpanel?.Client
  },
  setMixpanelBaseData: (data) => {
    store.mixpanelBaseData = { ...store.mixpanelBaseData, ...data };
  },

  todayCaption: { black: null, white: null },
  setTodayCaption: (images) => {
    store.todayCaption = { ...images };
  },

  onsiteUser: {},

  setOnsiteUser: () => {
    // locationQuery: l=0: On Site User, l=1: Off Site User

    // let urlSearchParams = new URLSearchParams(window.location.search);
    const locationQuery = urlSearchParams.get('l');
    const referrerQuery = urlSearchParams.get('r');

    // const userLocation = parseInt(locationQuery) === 0 ? true : false;
    // forced facial search without parameter
    const userLocation = true;
    const userReferral = parseInt(referrerQuery) !== 0 ? referrerQuery : false;

    store.onsiteUser = { isUserOnSite: userLocation, hasReferral: userReferral };
  },

  iframeViewer: false,

  setIframeViewer: (value) => {
    // let urlSearchParams = new URLSearchParams(window.location.search);

    const iframe = urlSearchParams.get('iframe');
    store.iframeViewer = iframe === 'true' || value;
  },

  fantasticPhotos: true,
  setFantasticPhotos: (value) => {
    store.fantasticPhotos = value;
  },

  checkIfUserIsOnSite: () => {
    return store.onsiteUser.isUserOnSite ? true : false
  },

  selfieLegalAccepted: false,
  setSelfieLegalAccepted: (state) => {
    store.selfieLegalAccepted = state
  },

  showChooseYourTeamModal: false,
  setShowChooseYourTeamModal: (state) => {
    store.showChooseYourTeamModal = state
  },

  showFantasticPhotosModal: false,
  setShowFantasticPhotosModal: (state) => {
    store.showFantasticPhotosModal = state
  },

  showAcceptCookiesModal: window.BRAND.baseConfig.coockieActive,
  setShowAcceptCookiesModal: (state) => {
    store.showAcceptCookiesModal = state
  },

  finalSite: urlSearchParams.get('redirected') === 'true',

  setFinalSite: () => {
    // let urlSearchParams = new URLSearchParams(window.location.search);

    const redirected = urlSearchParams.get('redirected');
    // console.log('redirected: ', redirected)
    store.finalSite = redirected === 'true';
  },

  photoIndex: 0,
  setPhotoIndex: (index) => {
    store.photoIndex = index;
  },

  ticketUGCImage: urlSearchParams.get('image'),

  userPhoto: null,
  setUserPhoto: (blob) => {
    store.userPhoto = blob;
  },
  userPhotoFile: null,
  setUserPhotoFile: (file) => {
    store.userPhotoFile = file;
  },

  mergedTicket: null,
  setMergedTicket: (ticket) => {
    store.mergedTicket = ticket;
  },

  mergedTicketFile: null,
  setMergedTicketFile: (file) => {
    store.mergedTicketFile = file;
  },

  userBrandedImage: null,
  setUserBrandedImage: (image) => {
    store.userBrandedImage = image;
  },

  userBrandedImageFile: null,
  setUserBrandedImageFile: (file) => {
    store.userBrandedImageFile = file;
  },

  error: null,
  setError: (error) => {
    store.error = error
  },

  wallSessionId: null,
  wallStreamWebUrl: null,
  setWallSessionId: (config) => {
    try {
      getWallEventInfo(config)
        .then(res => {
          if (res.status === 200) {
            store.wallSessionId = res.data.sessionId;
            store.wallStreamWebUrl = res.data.streamWebUrl;
          }
        })
    } catch (e) {
      console.log(e)
    }
  },

  videoSouvenir: null,
  setVideoSouvenir: (video) => {
    store.videoSouvenir = video
  },

  agreeTerms: true,
  
  bookingId: '',
  setBookingId: (value) => {
    store.bookingId = value
  },

  bookingData: {
    "id": "",
    "msg": "",
    "first_name": "",
    "last_name": "",
    "check_in_date": "",
    "check_out_date": "",
    "room_number": ""
  },
  setBookingData: (data) => {
    store.bookingData = data;
  },
  resetBookingData: () => {
    store.bookingData = {
      "id": "",
      "msg": "",
      "first_name": "",
      "last_name": "",
      "check_in_date": "",
      "check_out_date": "",
      "room_number": ""
    }
  }

})

const StoreContext = createContext()

export const StoreProvider = observer((props) => (
  <StoreContext.Provider {...props} value={store} />
))

export const useStore = () => useContext(StoreContext)
