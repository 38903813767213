import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import Text from 'components/text';

import './booking-id.scss'

const BookingId = observer(({ bookingId, onChangeBookingId, isInvalid }) => {


  return (
    <div className="booking-id__container">
      <span className='booking-id__title'>
        <Text id='common.enterBookingId' />
      </span>
      <div className='separator-line' />
      <span className='booking-id__try-again'>
        <Text id='common.pleaseTryAgainOrContact' markup display-if={isInvalid}/>
      </span>

      <div className={clsx('booking-id__form--container')}>
        <span><Text id='common.bookingId' /></span>
        <input
          type='text'
          className={clsx('booking-id__input', isInvalid && 'booking-id__input--invalid')}
          onChange={onChangeBookingId}
          placeholder='00000000'
          value={bookingId}
        />
        <span className='booking-id__input--invalid-label' display-if={isInvalid}><Text id='common.invalidBookingID' /></span>
      </div>
    </div>
  )
});

export default BookingId;