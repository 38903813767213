import React, { useState } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../stores/store';
import PBFooter from '../../components/pBFooter';
import clsx from 'clsx';

import './acceptCookiesModal.scss'

const customStyles = {
    content: {
        position: 'absolute',
        bottom: '0'
    }
};



export const AcceptCookiesModal = observer(({ isOpen, setIsOpen }) => {
    const store = useStore();
    const history = useHistory();
    
    const onAcceptCookies = () => {
        store.mixpanel.track('AcceptCookies', {
            ...store.mixpanelBaseData
        })
        const acceptCookies = true;
        localStorage.setItem("RCCookies", JSON.stringify({ acceptCookies }));
        setIsOpen(false);
        // history.push('/fantasticphotos')
    }

    const handleClose = () => {
        store.mixpanel.track('DeclineCookies', {
            ...store.mixpanelBaseData
        });
        setIsOpen(false)
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            class="email-modal"
            id="accept-cookies-modal"
            style={customStyles}
            className="accept-cookies-container"
            overlayClassName="accept-cookies-modal-overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="team-modal-content">
                <div className="close-modal-container" onClick={handleClose}>
                    <img src="assets/svg/close.svg" className="close-modal-x" alt="" />
                </div>
                <div className='fantastic-photos-container'>
                    <div>
                        <p className="rydercup__promotional-text-2">By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts and that you are in the age of majority in your local domicile.</p>
                    </div>

                    <div className='submit-button' onClick={onAcceptCookies}>
                        <p className="submit-button-text">Accept All Cookies</p>
                    </div>
                </div>
            </div>
        </Modal>
    )
})

export default AcceptCookiesModal;