import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from '../../stores/store';
import { makeTodayCaption } from '../../utils';
import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import Loading from '../../components/loading';
import Text from '../../components/text';
import clsx from 'clsx';

import './facialSearch.scss'
import FacialSearchPhotos from './facialSearchPhotos';
const SCREEN = 'Face_Search_Home';

const FacialSearch = observer(() => {
    const store = useStore();
    const history = useHistory();

    store.setOnsiteUser();
    const userIsOnSite = store.checkIfUserIsOnSite();


    useEffect(() => {
        const createNewNonSessionId = () => {

            const newNonBoothSessionId = uuidv4();
            store.setNonBoothSessionId(newNonBoothSessionId);
        }

        if (!store.nonBoothSessionId) createNewNonSessionId();
    }, [])

    useEffect(() => {
        const trackMixpanel = () => {
            store.mixpanel.track('LaunchSession', {
                ...store.mixpanelBaseData,
                'Screen': SCREEN,
            });
        }

        if (store && store.mixpanelBaseData) trackMixpanel();
    }, [store])

    const onEnter = () => {
        store.mixpanel.track('TapFindPhoto', {
            ...store.mixpanelBaseData,
            'Screen': SCREEN,
        })
        history.push('/terms')
    }

    const handlePressVideo = () => {
        store.mixpanel.track('PressIntroVideo', {
            ...store.mixpanelBaseData,
            'Screen': SCREEN,
        });
    }

    return (
        <div className="facial-search-container">
            <Loading display-if={false} color={store.brand.loadingBarColor} text={'loading...'} fontColor={store.brand.quaternary} />

            <LogoHeader hideBackButton={true} dark={true} alternateLogo={false} />



            <div className="extended__promotional-content-container">

                <div className="extended__promotional-container">

                    <div className="extended__promotional-group" display-if={!store.brand.baseConfig.images.title}>
                        <p className="extended__promotional-text">
                            <span className="extended__new-england-title-bold" lang='pl'><Text id='common.title' /></span>
                        </p>
                        <p className="extended__training-camp">
                            <Text id='common.subtitle' />
                        </p>
                    </div>

                    <div className='extended__title-container' display-if={store.brand.baseConfig.images.title}>
                        <img
                            src={store.brand.baseConfig.images.title}
                            className='extended__title-img'
                        />
                    </div>

                    <div className='extended__video-container' onClick={handlePressVideo}>
                        <video
                            src={store.brand.baseConfig.images.video}
                            className="extended__video"
                            playsInline
                            autoPlay
                            muted
                            loop
                        />
                    </div>


                </div>


                <div className="start-button" onClick={onEnter} >
                    <span className="">FIND PHOTO</span>
                </div>

            </div>
            <PBFooter dark={true && (store.brand.brand !== 'retreat' && store.brand.brand !== 'partner')} />
        </div>
    )
});

export default FacialSearch;