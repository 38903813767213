
import mergeImages from 'merge-images';
import Jimp from 'jimp';
import { flow, isFlowCancellationError } from 'mobx'
import { getPhoto } from '../services'
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
 

export const getArrayBuffer = async (Blob) => {
    // this: File or Blob
    return new Promise((resolve) => {
        let fr = new FileReader();
        fr.onload = () => {
            resolve(fr.result);
        };
        fr.readAsArrayBuffer(Blob);
    })
}

// export const webToMp4 = async (blob) => {
//     const ffmpeg = createFFmpeg({ 
//         log: true,
//         corePath: '@ffmpeg/core/dist/ffmpeg-core.js',
//         // corePath: 'https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js'
//      });

//     await ffmpeg.load();
//     console.log('Start transcoding');
//     ffmpeg.FS('writeFile', 'test.webm', await fetchFile(blob));
//     await ffmpeg.run('-i', 'test.webm', 'test.mp4');
//     console.log('Complete transcoding');
//     const data = ffmpeg.FS('readFile', 'test.mp4');
//     const newBlob = new Blob([data.buffer], { type: 'video/mp4' })
//     return newBlob
// }

const getFrameSrc = ({ bgMaskAsset, proxy, frameAsset, frameAltAsset, useAltAsset }) => {
    let asset
    if (bgMaskAsset) asset = bgMaskAsset
    else if (useAltAsset && frameAltAsset) asset = frameAltAsset
    else asset = frameAsset
    return proxy + asset
}

// export const generateMergeData = async ({ photo, mergeData }) => {
//     const resoltutionW = store.brand.baseConfig.options.width || containerWith;
//     const resoltutionH = store.brand.baseConfig.options.height || containerWith;
//     const { background, frame, size, position, scale } = mergeData;
//     const scaleData = await scalePhoto(photo, scale);
//     const { toMerge, scaledW, scaledH, originalW, originalH } = scaleData;
//     const finalMergeData = { ...mergeData }
//     positionX = (store.brand.baseConfig.options.resolution - scaledW) / 2
//     const xfinal = (positionX * size.width / resoltutionW - (scaledW - originalW) / 2)
//     finalMergeData.position.x = xfinal;
//     return {
//         finalMergeData
//     }
// }

export const makeMergedPhoto = async (store, photo, mergeData, backgrounds, frames, captions, containerWith, centered = false, bypassWatermark = false, onlyMergeUGC = false) => {
    const format = store.brand.baseConfig.options.format;
    const resoltutionW = store.brand.baseConfig.options.width || containerWith;
    const resoltutionH = store.brand.baseConfig.options.height || containerWith;
    const { background, frame, size, position, scale } = mergeData;
    const scaleData = await scalePhoto(photo, scale);
    const { toMerge, scaledW, scaledH, originalW, originalH } = scaleData;
    const { useWatermark } = store.brand.baseConfig?.options;
    const { watermark } = store.brand.advanceConfig?.images;
    let positionX;
    if (centered) {
        positionX = (store.brand.baseConfig.options.resolution - scaledW) / 2
    } else {
        positionX = position.x;
    }
    const xfinal = (positionX * size.width / resoltutionW - (scaledW - originalW) / 2)
    // const xfinal = (position.x * size.width / containerWith - (scaledW - originalW) / 2)
    const yfinal = (position.y * size.height / resoltutionH - (scaledH - originalH) / 2)

    try {

        const frameSrc = getFrameSrc({
            bgMaskAsset: backgrounds[background].maskAsset,
            proxy: store.config.Proxy || '',
            frameAsset: frames[frame].asset,
            frameAltAsset: frames[frame].alternaiveAsset,
            useAltAsset: !!store[store.brand.baseConfig.options.alternativeFrameCondition] && store.agreeTerms
        })
        // const frameImage = await getPhoto(backgrounds[background].maskAsset === "" ? `${store.config.Proxy || ''}${frames[frame].asset}` : `${store.config.Proxy}${backgrounds[background].maskAsset}`)
        const frameTransparent = await getPhoto('assets/frames/fr_transparent_1080.png')
        const frameImage = await getPhoto(frameSrc)
        const backgroundImage = await getPhoto(`${store.config.Proxy || ''}${backgrounds[background].asset}`)
        let maskAsset
        let maskAssetV
        try {
            maskAsset = await getPhoto(`${store.config.Proxy || ''}${backgrounds[background]?.maskAsset}`)
            maskAssetV = await getPhoto(`${store.config.Proxy || ''}${backgrounds[background]?.maskAssetV}`)
        } catch (error) {
            
        }
        // console.log('backgrounds[background].maskAssetV: ', backgrounds[background]?.maskAssetV)

        const options = {
            format: 'image/png'
        }

        let mergedImage;
        let image;

        // TODO: add watermark grayscale
        if (onlyMergeUGC) {
            mergedImage = await mergeImages([
                { src: URL.createObjectURL(frameTransparent.data) },
                { src: toMerge, x: xfinal, y: yfinal }
            ], options)
        } else if (!store.watermarkRemoved && useWatermark && !frames[frame]?.grayscale && !bypassWatermark) {
            mergedImage = await mergeImages([
                { src: URL.createObjectURL(backgroundImage.data) },
                { src: toMerge, x: xfinal, y: yfinal },
                { src: URL.createObjectURL(frameImage.data) },
                { src: watermark },
            ], options)
        } else if (backgrounds[background]?.useOriginalPhoto) {
            mergedImage = await mergeImages([
                { src: URL.createObjectURL(backgroundImage.data) },
                { src: toMerge, x: xfinal, y: yfinal },
                { src: URL.createObjectURL(store.isUGCVertical ? maskAssetV.data : maskAsset.data) },
            ], options)
        } else if (!frames[frame]?.grayscale && store.captionFrame && store.agreeTerms) {
            mergedImage = await mergeImages([
                { src: URL.createObjectURL(backgroundImage.data) },
                { src: toMerge, x: xfinal, y: yfinal },
                { src: URL.createObjectURL(frameImage.data) },
                { src: URL.createObjectURL(store.captionFrame)},
            ], options)
        } else if (!frames[frame]?.grayscale) {
            mergedImage = await mergeImages([
                { src: URL.createObjectURL(backgroundImage.data) },
                { src: toMerge, x: xfinal, y: yfinal },
                { src: URL.createObjectURL(frameImage.data) },
            ], options)
        } else {
            mergedImage = await mergeImages([
                { src: URL.createObjectURL(backgroundImage.data) },
                { src: toMerge, x: xfinal, y: yfinal },
            ], options)
        }

        image = await Jimp.read(mergedImage);
        image.crop(0, 0, size.width, size.height);
        if (frames[frame]?.grayscale) {
            image.grayscale()
            const grayscaleImage = await image.getBase64Async(Jimp.MIME_JPEG);
            mergedImage = await mergeImages([
                { src: grayscaleImage },
                { src: URL.createObjectURL(frameImage.data) },
            ], options)
            image = await Jimp.read(mergedImage);
            image.crop(0, 0, size.width, size.height);
        }

        let font22;
        let font16;
        const date = new Date();
        const dateArray = date.toString().split(' ')

        if (store.brand.useCaption) {
            if (frames[frame].captionColor === 'black') {
                font22 = await Jimp.loadFont('assets/fonts/poppins/poppins-semibold-18-black/Poppins-SemiBold.ttf.fnt');
                font16 = await Jimp.loadFont('assets/fonts/poppins/poppins-medium-14-black/Poppins-Medium.ttf.fnt');
            } else {
                font22 = await Jimp.loadFont('assets/fonts/poppins/poppins-semibold-18-white/Poppins-SemiBold.ttf.fnt');
                font16 = await Jimp.loadFont('assets/fonts/poppins/poppins-medium-14-white/Poppins-Medium.ttf.fnt');
            }

            await image.print(
                font22,
                90,
                402,
                {
                    text: captions[`${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`] ? captions[`${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`] : store.brand.baseCaption,
                    alignmentX: Jimp.HORIZONTAL_ALIGN_CENTER,
                },
                300,
                (err, image, { x, y }) => {
                    image.print(font16, 90, y, { text: dateArray[1] + ' ' + dateArray[2] + ', ' + dateArray[3], alignmentX: Jimp.HORIZONTAL_ALIGN_CENTER }, 300)
                },
            )
        }



        const finalMergeData = { ...mergeData }
        // finalMergeData.position.x = positionX;
        finalMergeData.position.x = xfinal;
        // const photoFile = await image.writeAsync('FantasticPhoto.png')

        // console.log('photoFile: ', photoFile)
        const finalMerge = await image.getBase64Async(Jimp.MIME_PNG);
        // const fetched = await fetch(finalMerge);
        // const finalMergeBlob = await fetched.blob();
        // console.log('finalMergeBlob: ', finalMergeBlob)
        // const file = new File([finalMergeBlob], "foto.jpg", { type: "image/jpeg" })


        if (centered) {
            return {
                finalMerge,
                finalMergeData
            }
        } else {
            return finalMerge;
        }


    } catch (e) {
        console.log(e)
    }
}

export const makeWatermarkedPhoto = async (store, photo) => {
    try {
        let mergedImage;
        // const toMerge = await resizePhoto(photo, 1080);
        const toMerge = await makeSquarePhoto(photo, 1080);
        const { watermarkV, watermarkH } = store.brand;
        let positionX = 0;
        let positionY = 0;
        const image = await Jimp.read(toMerge);
        const width = image.bitmap.width;
        const height = image.bitmap.height;
        const vertical = image.bitmap.width < image.bitmap.height;
        if (vertical) {
            positionX = (store.brand.resolution - width) / 2
        } else {
            positionY = (store.brand.resolution - height) / 2
        }
        positionX = (store.brand.resolution - width) / 2
        mergedImage = await mergeImages([
            { src: store.frames[0].asset },
            { src: toMerge, x: vertical ? 110 : 0, y: vertical ? 0 : 110 },
            { src: vertical ? watermarkV : watermarkH, x: vertical ? 110 : 0, y: vertical ? 0 : 110 }
        ])
        return mergedImage;
    } catch (e) {
        console.log(e)
    }
}

export const makeBrandedPhoto = async (store, photo) => {
    try {
        let mergedImage;
        // const toMerge = await resizePhoto(photo, 1080);
        const toMerge = await makeSquarePhoto(photo, 1080);
        const { brandedV, brandedH } = store.brand;
        const image = await Jimp.read(toMerge);
        const vertical = image.bitmap.width < image.bitmap.height;
        mergedImage = await mergeImages([
            { src: toMerge, x: 0, y: 0 },
            { src: vertical ? brandedV : brandedH, x: 0, y: 0 }
        ])
        return mergedImage;
    } catch (e) {
        console.log(e)
    }
}

export const makeTodayCaption = async (store, frame, captions, captionColor = 'white') => {
    try {
        const image = await Jimp.read(frame.asset);
        let font22;
        let font16;
        if (captionColor === 'black') {
            font22 = await Jimp.loadFont('assets/fonts/poppins/poppins-semibold-18-black/Poppins-SemiBold.ttf.fnt');
            font16 = await Jimp.loadFont('assets/fonts/poppins/poppins-medium-14-black/Poppins-Medium.ttf.fnt');
        } else {
            font22 = await Jimp.loadFont('assets/fonts/poppins/poppins-semibold-18-white/Poppins-SemiBold.ttf.fnt');
            font16 = await Jimp.loadFont('assets/fonts/poppins/poppins-medium-14-white/Poppins-Medium.ttf.fnt');
        }
        const date = new Date();
        const dateArray = date.toString().split(' ')
        await image.print(
            font22,
            90,
            402,
            {
                text: captions[`${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`] ? captions[`${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`] : store.brand.baseCaption,
                alignmentX: Jimp.HORIZONTAL_ALIGN_CENTER,
            },
            300,
            (err, image, { x, y }) => {
                image.print(font16, 90, y, { text: dateArray[1] + ' ' + dateArray[2] + ', ' + dateArray[3], alignmentX: Jimp.HORIZONTAL_ALIGN_CENTER }, 300)
            }
        )
        const todayCaption = await image.getBase64Async(Jimp.MIME_PNG);
        return todayCaption;
    } catch (e) {
        console.log(e)
    }
}

export const scalePhoto = async (photo, scale) => {
    const image = await Jimp.read(photo);
    const originalW = image.bitmap.width;
    const originalH = image.bitmap.height;

    await image.scale(scale);
    const scaledW = image.bitmap.width;
    const scaledH = image.bitmap.height;
    const toMerge = await image.getBase64Async(Jimp.MIME_PNG);
    return {
        toMerge: toMerge,
        scaledW: scaledW,
        scaledH: scaledH,
        originalW: originalW,
        originalH: originalH,
    }
}

export const resizePhoto = async (photo, height) => {
    try {
        const image = await Jimp.read(photo);
        await image.resize(Jimp.AUTO, height);
        const resized = await image.getBase64Async(Jimp.MIME_PNG);
        return resized;
    } catch (e) {
        console.log(e)
    }
}

export const resizePhotoH = async (photo, width) => {
    try {
        const image = await Jimp.read(photo);
        await image.resize(width, Jimp.AUTO);
        const resized = await image.getBase64Async(Jimp.MIME_PNG);
        return resized;
    } catch (e) {
        console.log(e)
    }
}

export const checkVertical = async (photo) => {
    try {
        const image = await Jimp.read(photo);
        const imageW = image.bitmap.width;
        const imageH = image.bitmap.height;
        return imageH > imageW;
    } catch (e) {
        console.log(e)
    }
}

export const getWidth = async (photo) => {

}

export const makeSquarePhoto = async (photo, height) => {
    try {
        const image = await Jimp.read(photo);
        let vertical = image.bitmap.width < image.bitmap.height;
        if (vertical) {
            await image.resize(Jimp.AUTO, height);
        } else {
            await image.resize(height, Jimp.AUTO);
        }
        const resized = await image.getBase64Async(Jimp.MIME_PNG);
        return resized;

    } catch (e) {
        console.log(e)
    }
}

export const flowHook = (gen) => {
    const promise = flow(gen)()
    promise.catch((err) => {
        if (isFlowCancellationError(err)) { return }
        throw err
    })
    return () => {
        promise.cancel()
    }
}

export const makePhotoStrip = async (mergedPhotos) => {
    let mergedPhotosCount = 0;
    mergedPhotos.forEach(photo => {
        if (photo?.photo !== null) mergedPhotosCount++
    })
    const stripHeight = 30 + 500 * mergedPhotosCount + 50; // to margin + margin and photo + down margin
    const blankStrip = await new Jimp(520, stripHeight, 'white');
    const blankStripToMerge = await blankStrip.getBase64Async(Jimp.MIME_JPEG);
    const mergeArray = makeMergeArray(mergedPhotos);
    const strip = await mergeImages([
        { src: blankStripToMerge },
        ...mergeArray
    ]);
    return strip;
}

export const make16By9Photo = async (photo, outputW = 288, outputH = 530) => {
    const resizedPhoto = await resizePhoto(photo, outputH);
    const image = await Jimp.read(resizedPhoto);
    const width = image.bitmap.width;
    image.crop((width - outputW) / 2, 0, outputW, outputH);
    return await image.getBase64Async(Jimp.MIME_PNG);
}

export const makeFanCardPhotoV = async (photo, outputW = 1462, outputH = 1920) => {
    const resizedPhoto = await resizePhoto(photo, outputH);
    const image = await Jimp.read(resizedPhoto);
    const width = image.bitmap.width;
    image.crop((width - outputW) / 2, 0, outputW, outputH);
    return await image.getBase64Async(Jimp.MIME_PNG);
}

export const makeFanCardPhotoH = async (photo, outputW = 2521, outputH = 1920) => {
    const resizedPhoto = await resizePhotoH(photo, outputW);
    const image = await Jimp.read(resizedPhoto);
    const width = image.bitmap.width;
    const height = image.bitmap.height;
    image.crop(0, 0, outputW, outputH);
    return await image.getBase64Async(Jimp.MIME_PNG);
}

export const makeMergedTicket = async (photo, ticketTemplate) => {
    const photo16By9 = await make16By9Photo(photo);
    const ticketTemplateResized = await resizePhoto(ticketTemplate, 720)
    const blankTicket = await new Jimp(288, 720, 'white')
    const blankTicketToMerge = await blankTicket.getBase64Async(Jimp.MIME_JPEG);
    const ticket = await mergeImages([
        { src: blankTicketToMerge },
        { src: photo16By9, x: 0, y: 140 },
        { src: ticketTemplateResized }
    ])
    return ticket
}

export const makeBrandedUserImage = async (photo, frame) => {
    const resizedPhoto = await resizePhoto(photo, 2064);
    const image = await Jimp.read(resizedPhoto);
    const width = image.bitmap.width;
    image.crop((width - 1160) / 2, 0, 1160, 2064);
    const photoToMerge = await image.getBase64Async(Jimp.MIME_JPEG);
    return await mergeImages([
        { src: photoToMerge },
        { src: frame },
    ])
}

export const makeMergeArray = (mergedPhotos) => {
    let mergeArray = [];
    let top = 30;
    mergedPhotos.forEach((photo, index) => {
        const y = top + index * 500
        if (photo.photo !== null)
            mergeArray.push({ src: photo.photo, x: 20, y: y })
    });
    return mergeArray;
}

export const checkUser = (user) => {
    if (!user?.firstName || !user?.lastName || !user?.email) return false;
    else return true;
}

export const generateCaptions = ({ data = {}, shortUnit, twoLines }) => {
    if (!data.distance) return []
    let caption = `${data.distance} ${shortUnit ? data.shortUnit : data.unit}`
    if (twoLines) return caption.split(' ')
    else return [caption]
}

export const setRootVariables = (cssVariables) => {
    let root = document.querySelector(':root');
    root.style.setProperty('--color-primary', cssVariables['--color-primary']);
    root.style.setProperty('--color-secondary', cssVariables['--color-secondary']);
    root.style.setProperty('--color-tertiary', cssVariables['--color-tertiary']);
    root.style.setProperty('--color-quaternary', cssVariables['--color-quaternary']);
    root.style.setProperty("--color-primary-action", cssVariables["--color-primary-action"]);
    root.style.setProperty("--color-dark-gray", cssVariables["--color-dark-gray"]);
    root.style.setProperty("--color-gray", cssVariables["--color-gray"]);
    root.style.setProperty("--color-black", cssVariables["--color-black"]);
    root.style.setProperty("--color-green", cssVariables["--color-green"]);
    root.style.setProperty("--color-pb-footer", cssVariables["--color-pb-footer"]);
    root.style.setProperty("--font-family-primary", cssVariables["--font-family-primary"]);
    root.style.setProperty("--font-family-secondary", cssVariables["--font-family-secondary"]);
    root.style.setProperty("--font-family-header", cssVariables["--font-family-header"]);
    root.style.setProperty("--font-family-body", cssVariables["--font-family-body"]);
    root.style.setProperty("--font-family-button", cssVariables["--font-family-button"]);
}