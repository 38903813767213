import React, { useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import clsx from 'clsx';

import Text from 'components/text';
import { FORMAT } from 'constants';
import { generateCaptions } from 'utils';
import CaptionFrame from 'components/captionFrame';

import { useStore } from 'stores/store';

import './photoMontage.scss'

const PhotoMontage = observer(({
  photo,
  originalPhoto,
  backgrounds,
  frames,
  isAltFrameActive,
  bgId,
  frId,
  setXAxis,
  setYAxis,
  modScale,
  setModScale,
  defaultScale,
  defaultPositionX,
  defaultPositionY,
  todayCaption,
  watermark,
  displayAdjust = false,
  setDisplayAdjust,
  useCaption,
  noBG,
  format,
  xAxis,
  yAxis
}) => {
  const ref_merged = useRef()

  const store = useStore();

  const ugc = useMemo(() => {
    if (backgrounds[bgId]?.useOriginalPhoto) return store.photo16By9
    else if (noBG && bgId === 0) return originalPhoto
    else return photo

  }, [noBG, bgId])


  return (
    <div className={clsx(format === FORMAT.SQUARE && 'photo-montage-container', format === FORMAT.RECTANGULAR_16_BY_9 && 'photo-montage-container-16-by-9')}>
      <div className='border-div' />
      {
        backgrounds && frames &&
        <TransformWrapper
          initialScale={0.5}
          defaultScale={defaultScale}
          defaultPositionX={defaultPositionX}
          defaultPositionY={defaultPositionY}
          options={{
            transformEnabled: true,
            lockAxisX: backgrounds[bgId]?.isResizeOff ? true : false,
            lockAxisY: backgrounds[bgId]?.isResizeOff ? true : false,
            minScale: 0.000000000000000000000000000000000001,
            limitToBounds: false,
            centerContent: false,
            limitToWrapper: false,
            centerZoomedOut: true,
          }}
        >{({ resetTransform, zoomIn, zoomOut, positionX, positionY, scale, onZoomStop }) => {
          {/* scale > 0.1 && setXAxis(positionX);
          scale > 0.1 && setYAxis(positionY);
          scale > 0.1 && setModScale(scale);
          scale < 0.1 && onZoomStop && resetTransform(); */}

          if (backgrounds[bgId]?.isResizeOff) {
            if (store.isUGCVertical) {
              setModScale(backgrounds[bgId]?.ugcScaleV)
              {/* setModScale(0.33) */ }
              setXAxis(backgrounds[bgId]?.maskCoordinatesV?.x)
              {/* setXAxis(-29) */ }
              setYAxis(backgrounds[bgId]?.maskCoordinatesV?.y)
              {/* setYAxis(-40) */ }
            } else {
              setModScale(backgrounds[bgId]?.ugcScale)
              {/* setModScale(0.25) */ }
              setXAxis(backgrounds[bgId]?.maskCoordinates?.x)
              {/* setXAxis(-110) */ }
              setYAxis(backgrounds[bgId]?.maskCoordinates?.y)
              {/* setYAxis(-35) */ }
            }
          } else {
            scale > 0.1 && setXAxis(positionX);
            scale > 0.1 && setYAxis(positionY);
            scale > 0.1 && setModScale(scale);
            scale < 0.1 && onZoomStop && resetTransform();
          }

          return (
            <React.Fragment>
              <div
                className={clsx('background')}
                id="merge-canvas" ref={ref_merged}
              >
                <div className="photo-to-pinch-container" >
                  <img
                    src={backgrounds[bgId]?.asset}
                    className={clsx('photo-to-pinch', frames[frId]?.grayscale && 'grayscale')}
                    alt=''
                    display-if={!backgrounds[bgId]?.video}
                  />
                  <video
                    src={backgrounds[bgId]?.asset}
                    className={clsx('photo-to-pinch')}
                    display-if={backgrounds[bgId]?.video}
                    playsInline
                    autoPlay
                    loop
                  />
                  <img
                    // src={(noBG && bgId === 0) || backgrounds[bgId]?.useOriginalPhoto ? store.photo16By9 : photo}
                    src={ugc}
                    className={clsx('photo-to-pinch', frames[frId]?.grayscale && 'grayscale')}
                    style={{ transform: `translate(${xAxis}px, ${yAxis}px) scale(${modScale})` }}
                    alt=''
                  />
                  <img
                    src={(isAltFrameActive && frames[frId].alternaiveAsset) || frames[frId].asset}
                    className="photo-to-pinch"
                    alt=''
                    display-if={backgrounds[bgId]?.maskAsset === ""}
                  />
                  <img
                    src={backgrounds[bgId]?.maskAsset}
                    className={clsx('photo-to-pinch', frames[frId]?.grayscale && 'grayscale')}
                    alt=''
                    display-if={backgrounds[bgId]?.maskAsset !== ""}
                  />
                  <CaptionFrame
                    style={frames[frId]?.captionStyle}
                    isScaledComponent
                    display-if={store.agreeTerms && !backgrounds[bgId]?.video}
                  />
                  <img src={todayCaption[frames[frId].captionColor]} className="photo-to-pinch" alt='' display-if={useCaption} />
                  <img src={watermark} display-if={watermark} className="photo-to-pinch" alt='' />
                  <div className="adjust-container" display-if={displayAdjust}>
                    {/* <p className="adjust-text">To adjust the image pinch and zoom<br/>When you're happy, press <strong>Done</strong></p> */}
                    <p className="adjust-text"><Text id='common.adjusttooltip' markup /></p>
                  </div>
                  {/* <div className="adjust-container" display-if={displayAdjust && watermark}>
                                        <p className="adjust-text">To adjust the image pinch and zoom<br/>with your fingers. Press <strong>Remove<br/>Watermark</strong> when you’re done.</p>
                                    </div> */}
                  <TransformComponent>
                    <div
                      className="div-to-pinch"
                      onTouchStart={() => setDisplayAdjust(false)}
                      display-if={!backgrounds[bgId]?.isResizeOff}
                    />
                  </TransformComponent>

                </div>
              </div>
            </React.Fragment>
          )
        }}
        </TransformWrapper>
      }

    </div>
  )
})

export default PhotoMontage;