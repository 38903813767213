import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

const Dots = observer(({ list, onChangeSlide, active }) => (
    <div className="dot-container">
        {
            list.map((item, index) => <div className={clsx('dot', index === active && 'active-dot' )} onClick={() => onChangeSlide(index)} />)
        }
    </div>
));

export default Dots;