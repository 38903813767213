import React from 'react'

import './promotionalContent.scss'

const FantasticPhotos = ({ color }) => {
    return (
        <div className='promotional-content-v2__fantastic-photos'>
            <svg width="current" height="current" viewBox="0 0 227 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M223.25 18.9898V19.4508H222.482V21.874H221.891V19.4508H221.128V18.9839L223.25 18.9898Z" fill={color} />
                <path d="M226.861 18.9902V21.8804H226.27V20.0009L225.496 21.8804H225.059L224.279 20.0009V21.8804H223.688V18.9902H224.344L225.266 21.1357L226.182 18.9902H226.861Z" fill={color} />
                <path d="M14.131 0.945801V4.99437H5.67928V9.36801H12.0033V13.2984H5.67928V21.691H0.614136V0.945801H14.131Z" fill={color} />
                <path d="M30.0003 18.0266H22.2577L21.0166 21.691H15.6973L23.2034 0.945801H29.0546L36.5607 21.691H31.2414L30.0003 18.0266ZM28.7 14.1258L26.129 6.53105L23.5876 14.1317L28.7 14.1258Z" fill={color} />
                <path d="M57.7197 21.691H52.6664L44.2146 8.89518V21.691H39.1613V0.945801H44.2146L52.6664 13.8008V0.945801H57.7197V21.691Z" fill={color} />
                <path d="M68.6656 18.2688V21.6968H66.6088C65.3728 21.772 64.1524 21.389 63.1808 20.6211C62.3573 19.9001 61.9475 18.7298 61.9515 17.1104V11.862H60.3439V8.50497H61.9515V5.26611H65.9941V8.48133H68.642V11.8384H65.9941V17.1577C65.98 17.3115 65.998 17.4665 66.0468 17.613C66.0956 17.7595 66.1743 17.8942 66.2778 18.0088C66.551 18.2076 66.8871 18.3 67.2235 18.2688H68.6656Z" fill={color} />
                <path d="M71.1124 11.5017C71.5705 10.5305 72.2964 9.71027 73.2047 9.13753C74.0896 8.58308 75.1157 8.29577 76.1598 8.31008C77.0121 8.29907 77.8535 8.5023 78.6067 8.90111C79.2691 9.24518 79.828 9.75922 80.2261 10.3905V8.52285H84.2688V21.7147H80.2261V19.847C79.8143 20.4754 79.2487 20.9881 78.5831 21.3364C77.8298 21.7353 76.9885 21.9385 76.1362 21.9275C75.0905 21.9406 74.0637 21.649 73.181 21.0882C72.2778 20.5096 71.5533 19.691 71.0888 18.7241C70.5571 17.6064 70.294 16.3799 70.3204 15.1424C70.2882 13.8828 70.5598 12.634 71.1124 11.5017ZM79.3869 12.6837C79.1217 12.4092 78.804 12.1908 78.4527 12.0417C78.1014 11.8926 77.7236 11.8157 77.3419 11.8157C76.9602 11.8157 76.5825 11.8926 76.2311 12.0417C75.8798 12.1908 75.5621 12.4092 75.2969 12.6837C75.0074 13.0025 74.7841 13.3757 74.6401 13.7816C74.496 14.1874 74.434 14.6178 74.4577 15.0479C74.4108 15.927 74.7123 16.7892 75.2969 17.4474C75.5551 17.7322 75.8711 17.9586 76.2237 18.1115C76.5764 18.2644 76.9576 18.3402 77.3419 18.334C77.725 18.3391 78.1048 18.2639 78.4571 18.1133C78.8093 17.9626 79.126 17.7398 79.3869 17.4593C79.9298 16.7818 80.2257 15.9396 80.2257 15.0715C80.2257 14.2034 79.9298 13.3611 79.3869 12.6837Z" fill={color} />
                <path d="M89.73 21.2888C88.8932 20.941 88.1604 20.3832 87.6023 19.6694C87.0884 19.0049 86.7839 18.2025 86.7275 17.3643H90.7229C90.7431 17.5846 90.8106 17.7979 90.9207 17.9896C91.0309 18.1814 91.1811 18.3471 91.3612 18.4755C91.7661 18.7681 92.2568 18.9178 92.7561 18.901C93.1711 18.9268 93.584 18.8236 93.9381 18.6055C94.0652 18.5216 94.1694 18.4075 94.2416 18.2734C94.3138 18.1393 94.3517 17.9894 94.3518 17.8372C94.3526 17.6533 94.296 17.4737 94.1901 17.3233C94.0842 17.1729 93.9342 17.0592 93.7608 16.9979C93.1406 16.7464 92.4999 16.5486 91.8459 16.4069C91.0434 16.2265 90.2536 15.9936 89.4817 15.7094C88.8489 15.4558 88.2886 15.0498 87.8505 14.5274C87.3663 13.923 87.1221 13.1611 87.1649 12.3878C87.1576 11.6505 87.3763 10.9286 87.7914 10.3192C88.2494 9.65791 88.8902 9.14443 89.6354 8.84165C90.5511 8.45638 91.5384 8.271 92.5315 8.2979C93.9418 8.21788 95.3329 8.65497 96.4441 9.52725C97.4025 10.354 97.9935 11.5275 98.0872 12.7897H94.3518C94.3298 12.5785 94.2662 12.3737 94.1648 12.1871C94.0633 12.0004 93.9261 11.8357 93.7608 11.7022C93.3751 11.4186 92.9033 11.2766 92.4251 11.3003C92.043 11.2747 91.6626 11.3698 91.3376 11.5722C91.2162 11.6538 91.1178 11.765 91.0516 11.8954C90.9854 12.0257 90.9536 12.1708 90.9593 12.3169C90.9596 12.5021 91.0163 12.6827 91.1219 12.8348C91.2275 12.9868 91.377 13.1031 91.5504 13.168C92.1551 13.4286 92.7848 13.6266 93.4298 13.759C94.2331 13.9562 95.0227 14.2049 95.794 14.5037C96.4225 14.759 96.9787 15.165 97.4134 15.6858C97.9027 16.3077 98.1579 17.0817 98.1344 17.8726C98.1423 18.6136 97.9146 19.3379 97.4843 19.9412C97.0038 20.5794 96.358 21.074 95.6167 21.3715C94.7192 21.732 93.7585 21.9089 92.7915 21.8917C91.7404 21.9034 90.6982 21.6982 89.73 21.2888Z" fill={color} />
                <path d="M108.217 18.2688V21.6968H106.161C104.925 21.772 103.704 21.389 102.733 20.6211C101.909 19.9001 101.499 18.7298 101.503 17.1104V11.862H99.9076V8.50497H101.515V5.26611H105.558V8.48133H108.182V11.8384H105.534V17.1577C105.52 17.3115 105.538 17.4665 105.587 17.613C105.636 17.7595 105.714 17.8942 105.818 18.0088C106.091 18.2076 106.427 18.3 106.764 18.2688H108.217Z" fill={color} />
                <path d="M110.995 6.50139C110.783 6.30084 110.614 6.059 110.498 5.79069C110.382 5.52238 110.322 5.23326 110.322 4.94107C110.322 4.64887 110.382 4.35976 110.498 4.09145C110.614 3.82314 110.783 3.58129 110.995 3.38074C111.479 2.97579 112.09 2.75391 112.721 2.75391C113.352 2.75391 113.963 2.97579 114.447 3.38074C114.659 3.58129 114.829 3.82314 114.944 4.09145C115.06 4.35976 115.12 4.64887 115.12 4.94107C115.12 5.23326 115.06 5.52238 114.944 5.79069C114.829 6.059 114.659 6.30084 114.447 6.50139C113.963 6.90634 113.352 7.12823 112.721 7.12823C112.09 7.12823 111.479 6.90634 110.995 6.50139ZM114.742 8.49908V21.6909H110.7V8.49908H114.742Z" fill={color} />
                <path d="M117.946 11.5018C118.482 10.4968 119.305 9.67394 120.31 9.13771C121.366 8.57006 122.551 8.28502 123.75 8.31026C125.237 8.25739 126.697 8.71637 127.887 9.61054C129.031 10.5306 129.807 11.831 130.074 13.2749H125.771C125.644 12.8213 125.367 12.4244 124.984 12.1494C124.602 11.8744 124.137 11.7378 123.667 11.7619C123.323 11.7494 122.982 11.8216 122.673 11.9721C122.364 12.1225 122.096 12.3467 121.894 12.6248C121.46 13.3759 121.232 14.228 121.232 15.0953C121.232 15.9627 121.46 16.8147 121.894 17.5658C122.097 17.8428 122.365 18.0661 122.674 18.2165C122.983 18.3668 123.324 18.4397 123.667 18.4287C124.137 18.4518 124.601 18.3147 124.984 18.0399C125.366 17.765 125.643 17.3687 125.771 16.9157H130.05C129.767 18.3496 128.989 19.6384 127.852 20.5565C126.665 21.4591 125.204 21.9265 123.714 21.8804C122.516 21.9027 121.332 21.6178 120.275 21.0529C119.272 20.5133 118.45 19.6913 117.91 18.6888C117.359 17.5713 117.071 16.3417 117.071 15.0953C117.071 13.849 117.359 12.6193 117.91 11.5018H117.946Z" fill={color} />
                <path d="M149.678 13.0736C149.205 13.8996 148.5 14.5692 147.651 15.0003C146.591 15.5196 145.42 15.7712 144.241 15.7332H141.741V21.6908H137.698V5.09462H144.235C145.394 5.05754 146.546 5.29233 147.598 5.78021C148.456 6.19002 149.171 6.84916 149.649 7.67152C150.118 8.5165 150.355 9.47108 150.334 10.4375C150.348 11.3582 150.122 12.2665 149.678 13.0736ZM145.665 11.9742C146.032 11.546 146.233 11.001 146.233 10.4375C146.233 9.87405 146.032 9.32908 145.665 8.90086C145.183 8.50135 144.564 8.30621 143.939 8.35711H141.741V12.4943H143.939C144.56 12.5487 145.178 12.3625 145.665 11.9742Z" fill={color} />
                <path d="M164.431 9.85886C165.401 11.0111 165.892 12.4922 165.802 13.9961V21.6795H161.783V14.528C161.832 13.7791 161.586 13.0406 161.097 12.4712C160.598 12.0012 159.939 11.7394 159.253 11.7394C158.568 11.7394 157.908 12.0012 157.409 12.4712C156.922 13.0419 156.677 13.7794 156.724 14.528V21.6913H152.681V4.19678H156.724V10.2726C157.154 9.67093 157.732 9.19048 158.402 8.87775C159.14 8.52499 159.949 8.34701 160.766 8.35764C161.45 8.32446 162.132 8.44171 162.765 8.7011C163.398 8.9605 163.967 9.35572 164.431 9.85886Z" fill={color} />
                <path d="M171.375 21.0524C170.354 20.5115 169.508 19.6916 168.934 18.6883C168.35 17.5825 168.045 16.3511 168.045 15.1007C168.045 13.8503 168.35 12.6188 168.934 11.5131C169.512 10.5071 170.365 9.6869 171.393 9.14899C172.479 8.60501 173.677 8.32178 174.892 8.32178C176.107 8.32178 177.305 8.60501 178.391 9.14899C179.421 9.68363 180.275 10.5046 180.849 11.5131C181.445 12.613 181.757 13.844 181.757 15.0948C181.757 16.3455 181.445 17.5765 180.849 18.6764C180.259 19.6825 179.396 20.5019 178.361 21.0406C177.277 21.6004 176.071 21.8845 174.851 21.868C173.642 21.8887 172.448 21.6084 171.375 21.0524ZM176.836 17.5062C177.417 16.8331 177.71 15.9584 177.652 15.0711C177.711 14.1867 177.425 13.3138 176.854 12.6361C176.604 12.3689 176.301 12.156 175.965 12.0104C175.629 11.8648 175.267 11.7897 174.901 11.7897C174.535 11.7897 174.172 11.8648 173.836 12.0104C173.5 12.156 173.198 12.3689 172.947 12.6361C172.378 13.3189 172.096 14.1962 172.161 15.083C172.106 15.9619 172.38 16.8299 172.93 17.518C173.169 17.7918 173.466 18.0099 173.799 18.1569C174.132 18.3039 174.493 18.3764 174.856 18.3691C175.227 18.3751 175.594 18.3036 175.935 18.159C176.276 18.0145 176.583 17.8001 176.836 17.5298V17.5062Z" fill={color} />
                <path d="M191.518 18.2688V21.6968H189.461C188.225 21.7709 187.005 21.388 186.033 20.6211C185.217 19.9001 184.803 18.7298 184.803 17.1104V11.862H183.196V8.50497H184.803V5.26611H188.846V8.48133H191.494V11.8384H188.846V17.1577C188.832 17.3115 188.85 17.4665 188.899 17.613C188.948 17.7595 189.026 17.8942 189.13 18.0088C189.403 18.2066 189.739 18.2989 190.075 18.2688H191.518Z" fill={color} />
                <path d="M196.5 21.0524C195.482 20.5095 194.638 19.69 194.065 18.6882C193.481 17.5825 193.176 16.351 193.176 15.1007C193.176 13.8503 193.481 12.6188 194.065 11.5131C194.643 10.5071 195.496 9.6869 196.524 9.14898C197.61 8.60501 198.808 8.32178 200.023 8.32178C201.237 8.32178 202.435 8.60501 203.522 9.14898C204.551 9.68528 205.404 10.5058 205.98 11.5131C206.574 12.6137 206.884 13.8444 206.884 15.0948C206.884 16.3451 206.574 17.5758 205.98 18.6764C205.393 19.6835 204.532 20.5033 203.498 21.0405C202.413 21.6003 201.208 21.8845 199.987 21.868C198.775 21.8889 197.577 21.6086 196.5 21.0524ZM201.961 17.5062C202.542 16.833 202.835 15.9584 202.777 15.0711C202.838 14.1875 202.554 13.3147 201.985 12.6361C201.733 12.3695 201.43 12.1572 201.094 12.012C200.757 11.8669 200.395 11.792 200.029 11.792C199.662 11.792 199.3 11.8669 198.963 12.012C198.627 12.1572 198.324 12.3695 198.072 12.6361C197.508 13.3213 197.228 14.1974 197.292 15.0829C197.237 15.9619 197.511 16.8299 198.06 17.518C198.3 17.7918 198.597 18.0099 198.93 18.1569C199.262 18.3039 199.623 18.3763 199.987 18.3691C200.357 18.3757 200.723 18.3044 201.064 18.1598C201.404 18.0152 201.71 17.8005 201.961 17.5298V17.5062Z" fill={color} />
                <path d="M211.571 21.289C210.735 20.9413 210.002 20.3835 209.444 19.6696C208.932 19.0039 208.628 18.2022 208.569 17.3646H212.564C212.585 17.5848 212.652 17.7981 212.762 17.9899C212.872 18.1816 213.023 18.3474 213.203 18.4757C213.608 18.7676 214.098 18.9172 214.598 18.9013C215.013 18.9291 215.426 18.8257 215.78 18.6058C215.907 18.5226 216.012 18.4086 216.085 18.2744C216.157 18.1401 216.194 17.9899 216.193 17.8374C216.195 17.6533 216.139 17.4733 216.033 17.3228C215.927 17.1722 215.776 17.0587 215.602 16.9981C214.983 16.7448 214.342 16.547 213.687 16.4071C212.885 16.226 212.095 15.993 211.323 15.7097C210.692 15.4538 210.132 15.0481 209.692 14.5276C209.21 13.9219 208.966 13.161 209.006 12.3881C209.002 11.6512 209.22 10.9302 209.633 10.3195C210.094 9.6607 210.734 9.1479 211.477 8.84189C212.393 8.45766 213.38 8.27232 214.373 8.29814C215.783 8.21701 217.175 8.65423 218.286 9.52749C218.762 9.93516 219.153 10.4335 219.435 10.9937C219.717 11.5538 219.885 12.1644 219.929 12.79H216.193C216.174 12.5781 216.111 12.3724 216.01 12.1854C215.908 11.9984 215.77 11.8341 215.602 11.7025C215.217 11.417 214.745 11.2749 214.267 11.3006C213.885 11.2758 213.504 11.3708 213.179 11.5725C213.051 11.6578 212.948 11.7755 212.881 11.9136C212.813 12.0517 212.784 12.2052 212.795 12.3585C212.795 12.5437 212.852 12.7243 212.957 12.8764C213.063 13.0285 213.213 13.1447 213.386 13.2096C213.991 13.4702 214.62 13.6682 215.265 13.8007C216.069 13.9969 216.859 14.2457 217.63 14.5454C218.259 14.7983 218.816 15.2046 219.249 15.7274C219.743 16.3466 219.999 17.1225 219.97 17.9142C219.981 18.6557 219.753 19.3809 219.32 19.9829C218.842 20.6236 218.195 21.1189 217.452 21.4132C216.555 21.7747 215.594 21.9516 214.627 21.9333C213.575 21.93 212.535 21.7107 211.571 21.289Z" fill={color} />
                <path d="M44.3506 31.1357V40.7282H42.7784V31.1357H44.3506Z" fill={color} />
                <path d="M48.3755 34.4043V38.6065C48.3679 38.7134 48.3818 38.8208 48.4163 38.9223C48.4508 39.0238 48.5053 39.1174 48.5765 39.1975C48.7728 39.3401 49.0148 39.4053 49.2562 39.3808H50.2196V40.7283H48.9784C48.3942 40.7666 47.8161 40.5901 47.3531 40.2318C47.1443 40.0212 46.9835 39.768 46.8815 39.4896C46.7795 39.2111 46.7388 38.9139 46.762 38.6183V34.4043H45.8636V33.1217H46.762V31.2363H48.346V33.1217H50.19V34.4043H48.3755Z" fill={color} />
                <path d="M52.034 31.1357H53.7479L52.2999 34.416H51.1947L52.034 31.1357Z" fill={color} />
                <path d="M56.4312 40.5155C55.9863 40.3252 55.5991 40.0215 55.3083 39.6348C55.0345 39.2753 54.8796 38.8394 54.865 38.3877H56.4903C56.5052 38.5469 56.554 38.701 56.6336 38.8397C56.7131 38.9784 56.8215 39.0983 56.9513 39.1915C57.2569 39.4168 57.63 39.5314 58.0093 39.5166C58.37 39.5387 58.7288 39.45 59.0377 39.2624C59.1483 39.1925 59.2394 39.0956 59.3025 38.9809C59.3656 38.8662 59.3985 38.7373 59.3982 38.6064C59.4013 38.4731 59.3648 38.3418 59.2933 38.2292C59.2217 38.1166 59.1184 38.0278 58.9963 37.974C58.5807 37.7855 58.1496 37.6332 57.7079 37.5189C57.2339 37.3964 56.7681 37.2444 56.313 37.0638C55.9543 36.9167 55.636 36.6856 55.3851 36.39C55.1144 36.0569 54.9737 35.6369 54.9891 35.208C54.9861 34.8084 55.1126 34.4185 55.3496 34.0968C55.6118 33.7421 55.9681 33.4677 56.378 33.3048C56.8646 33.1027 57.3879 33.004 57.9147 33.0152C58.6659 32.9798 59.4053 33.2123 60.0011 33.6713C60.2608 33.889 60.4707 34.16 60.6165 34.4659C60.7623 34.7718 60.8407 35.1055 60.8462 35.4444H59.2741C59.2673 35.2868 59.2269 35.1324 59.1556 34.9918C59.0842 34.8511 58.9835 34.7273 58.8604 34.6288C58.5709 34.414 58.2155 34.3074 57.8556 34.3273C57.5216 34.3059 57.1892 34.3885 56.9041 34.5638C56.8009 34.6307 56.7164 34.7228 56.6585 34.8313C56.6006 34.9399 56.5712 35.0613 56.5731 35.1843C56.5726 35.2801 56.5923 35.3749 56.6311 35.4625C56.6698 35.5501 56.7265 35.6285 56.7977 35.6926C56.9539 35.8352 57.1367 35.9456 57.3355 36.0177C57.5424 36.0945 57.8556 36.195 58.2693 36.3132C58.7325 36.4307 59.1867 36.5808 59.6287 36.7624C59.9815 36.9114 60.295 37.14 60.5448 37.4303C60.8177 37.7625 60.9605 38.1826 60.9467 38.6123C60.9549 39.0346 60.8286 39.4486 60.5862 39.7944C60.3262 40.1454 59.9773 40.4208 59.5755 40.5923C58.5628 40.9903 57.4347 40.9776 56.4312 40.5568V40.5155Z" fill={color} />
                <path d="M69.6822 34.4041H68.2755V40.7281H66.6916V34.4041H65.7932V33.1216H66.6916V32.5837C66.661 32.2337 66.7087 31.8813 66.8314 31.5521C66.9541 31.2229 67.1486 30.9252 67.4008 30.6806C68.0348 30.2315 68.8077 30.0221 69.5817 30.0896V31.3957C69.2248 31.3598 68.8671 31.4566 68.577 31.6676C68.4684 31.7947 68.3868 31.9424 68.337 32.1019C68.2873 32.2614 68.2704 32.4293 68.2874 32.5955V33.1334H69.694L69.6822 34.4041Z" fill={color} />
                <path d="M78.0926 33.1216V40.7281H76.5205V39.8298C76.2611 40.1525 75.9262 40.4062 75.5453 40.5686C75.1458 40.7484 74.7127 40.8411 74.2746 40.8404C73.7162 40.8505 73.164 40.7226 72.667 40.4681C72.1927 40.2194 71.8036 39.8345 71.5499 39.3629C71.2621 38.8174 71.1215 38.2062 71.1421 37.5898V33.1216H72.7202V37.3534C72.6809 37.9215 72.8631 38.4828 73.2285 38.9196C73.609 39.2689 74.1067 39.4627 74.6233 39.4627C75.1398 39.4627 75.6376 39.2689 76.0181 38.9196C76.39 38.4858 76.5767 37.9234 76.5382 37.3534V33.1216H78.0926Z" fill={color} />
                <path d="M85.7228 33.3699C86.1949 33.6175 86.5804 34.003 86.828 34.4751C87.1113 35.0221 87.2496 35.6326 87.2299 36.2482V40.7283H85.6696V36.4728C85.7101 35.9045 85.5278 35.3428 85.1613 34.9066C84.9816 34.7227 84.7648 34.5791 84.5253 34.4854C84.2859 34.3916 84.0292 34.3499 83.7724 34.3628C83.5138 34.3501 83.2554 34.3919 83.0141 34.4856C82.7728 34.5793 82.5539 34.7227 82.3716 34.9066C82.003 35.3419 81.8186 35.9037 81.8574 36.4728V40.7283H80.2853V33.1217H81.8574V33.9905C82.1218 33.6719 82.4581 33.4207 82.8385 33.2576C83.2419 33.0849 83.6764 32.9964 84.1152 32.9975C84.6735 32.9882 85.2255 33.116 85.7228 33.3699Z" fill={color} />
                <path d="M94.9312 34.4043V38.6065C94.9235 38.7135 94.9374 38.8208 94.9719 38.9223C95.0064 39.0238 95.0609 39.1174 95.1321 39.1975C95.3287 39.3396 95.5705 39.4047 95.8118 39.3808H96.7811V40.7283H95.5399C94.9553 40.77 94.3761 40.5931 93.9146 40.2319C93.7049 40.0219 93.5434 39.7688 93.4414 39.4902C93.3393 39.2115 93.2991 38.9141 93.3235 38.6183V34.4043H92.4252V33.1217H93.3235V31.2363H94.9075V33.1217H96.7574V34.4043H94.9312Z" fill={color} />
                <path d="M99.7125 40.3501C99.1383 40.0268 98.6655 39.55 98.3472 38.973C98.0103 38.3386 97.8414 37.6285 97.8567 36.9103C97.8416 36.1938 98.017 35.486 98.3649 34.8594C98.6876 34.2777 99.1694 33.8 99.7539 33.4823C100.362 33.1639 101.038 32.9976 101.725 32.9976C102.412 32.9976 103.088 33.1639 103.696 33.4823C104.282 33.7996 104.766 34.2773 105.091 34.8594C105.435 35.4876 105.61 36.1942 105.599 36.9103C105.614 37.6302 105.434 38.3408 105.079 38.9671C104.739 39.5516 104.244 40.0303 103.649 40.3501C103.037 40.6782 102.352 40.847 101.657 40.8407C100.977 40.8489 100.307 40.6798 99.7125 40.3501ZM102.798 39.168C103.159 38.9649 103.456 38.6637 103.655 38.2993C103.889 37.8685 104.003 37.3827 103.986 36.8926C104 36.4087 103.892 35.929 103.672 35.4978C103.478 35.1357 103.185 34.8364 102.827 34.6349C102.482 34.445 102.095 34.3454 101.701 34.3454C101.308 34.3454 100.92 34.445 100.575 34.6349C100.224 34.8376 99.9391 35.1372 99.7539 35.4978C99.541 35.9311 99.4375 36.41 99.4524 36.8926C99.4061 37.5825 99.6309 38.2632 100.079 38.7898C100.28 39.0065 100.524 39.1781 100.796 39.2932C101.068 39.4084 101.362 39.4644 101.657 39.4577C102.054 39.4661 102.447 39.3725 102.798 39.1858V39.168Z" fill={color} />
                <path d="M113.685 33.3521C114.165 33.1117 114.696 32.9901 115.233 32.9975C115.873 32.988 116.503 33.1558 117.053 33.4821C117.603 33.8074 118.048 34.2832 118.336 34.8533C118.657 35.4821 118.817 36.1806 118.803 36.8865C118.816 37.5959 118.656 38.2979 118.336 38.9314C118.043 39.5092 117.597 39.9957 117.048 40.3381C116.54 40.6379 115.966 40.8091 115.377 40.8369C114.788 40.8647 114.201 40.7482 113.667 40.4977C113.242 40.2901 112.872 39.9866 112.585 39.6111V40.7282H111.019V30.5151H112.585V34.2268C112.876 33.8511 113.253 33.5511 113.685 33.3521ZM116.882 35.5271C116.682 35.1639 116.385 34.8632 116.025 34.6583C115.678 34.4643 115.288 34.3626 114.89 34.3628C114.496 34.3626 114.109 34.4666 113.767 34.6642C113.406 34.8736 113.11 35.1782 112.91 35.5448C112.697 35.9693 112.585 36.4379 112.585 36.9131C112.585 37.3882 112.697 37.8568 112.91 38.2813C113.108 38.6524 113.405 38.9614 113.767 39.1738C114.109 39.3713 114.496 39.4753 114.89 39.4752C115.289 39.4771 115.682 39.3708 116.025 39.1678C116.388 38.9508 116.685 38.6379 116.882 38.2636C117.104 37.8388 117.215 37.3654 117.207 36.8865C117.218 36.4128 117.106 35.9444 116.882 35.5271Z" fill={color} />
                <path d="M127.402 37.5073H121.604C121.614 37.7832 121.678 38.0544 121.794 38.3051C121.91 38.5558 122.074 38.7808 122.278 38.9671C122.685 39.3356 123.219 39.5326 123.768 39.5168C124.135 39.5383 124.501 39.4489 124.817 39.2603C125.133 39.0716 125.385 38.7924 125.541 38.4588H127.237C127.01 39.1463 126.574 39.7456 125.99 40.1728C125.335 40.6324 124.549 40.8668 123.75 40.8407C123.068 40.8513 122.395 40.6821 121.799 40.3501C121.227 40.0292 120.757 39.5515 120.446 38.973C120.109 38.3386 119.94 37.6285 119.955 36.9103C119.94 36.1954 120.105 35.4881 120.434 34.8535C120.734 34.274 121.198 33.7967 121.77 33.4823C122.372 33.1699 123.039 33.0068 123.717 33.0068C124.395 33.0068 125.063 33.1699 125.665 33.4823C126.217 33.7876 126.671 34.245 126.971 34.8003C127.293 35.3993 127.456 36.0708 127.444 36.7507C127.449 37.0036 127.435 37.2565 127.402 37.5073ZM125.818 36.2365C125.828 35.9758 125.78 35.7161 125.678 35.476C125.576 35.236 125.422 35.0214 125.227 34.8476C124.809 34.4925 124.274 34.3051 123.726 34.3216C123.213 34.3108 122.715 34.4945 122.331 34.8358C121.938 35.2019 121.693 35.7009 121.646 36.2365H125.818Z" fill={color} />
                <path d="M132.81 34.8593C133.108 34.2934 133.555 33.8204 134.103 33.492C134.652 33.1636 135.28 32.9925 135.919 32.9975C136.462 32.9846 137.001 33.1064 137.485 33.3521C137.902 33.5611 138.268 33.8598 138.555 34.2269V33.1216H140.139V40.7282H138.555V39.5993C138.264 39.9749 137.893 40.2799 137.468 40.4918C136.939 40.7486 136.355 40.8701 135.768 40.8453C135.181 40.8205 134.61 40.6503 134.105 40.3499C133.554 40.0065 133.107 39.5204 132.81 38.9433C132.489 38.3103 132.326 37.6083 132.338 36.8983C132.322 36.1898 132.485 35.4888 132.81 34.8593ZM138.23 35.5449C138.033 35.1804 137.741 34.876 137.385 34.6642C137.04 34.4652 136.648 34.3612 136.25 34.3628C135.855 34.3618 135.466 34.4636 135.121 34.6583C134.764 34.8638 134.472 35.1646 134.276 35.5271C134.049 35.9435 133.937 36.4126 133.951 36.8865C133.94 37.3658 134.052 37.8398 134.276 38.2636C134.469 38.6388 134.764 38.9522 135.127 39.1679C135.467 39.3691 135.855 39.4753 136.25 39.4752C136.648 39.4769 137.04 39.3729 137.385 39.1738C137.743 38.9592 138.035 38.6505 138.23 38.2813C138.444 37.8569 138.555 37.3883 138.555 36.9131C138.555 36.4379 138.444 35.9693 138.23 35.5449Z" fill={color} />
                <path d="M153.827 28.9844V30.9348H148.816V34.0141H152.657V35.929H148.816V41.0119H146.404V28.9844H153.827Z" fill={color} />
                <path d="M163.083 38.7184H158.29L157.498 41.0116H154.968L159.289 28.9663H162.096L166.422 41.0116H163.875L163.083 38.7184ZM162.427 36.7916L160.683 31.7619L158.946 36.7916H162.427Z" fill={color} />
                <path d="M178.515 41.0116H176.098L170.637 32.7371V41.0116H168.225V28.9663H170.637L176.098 37.2408V28.9663H178.515V41.0116Z" fill={color} />
            </svg>

        </div>
    )
}

export default FantasticPhotos