import React, { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import clsx from 'clsx';
import domtoimage from 'dom-to-image';

import Text from '../../components/text';
import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import Loading from '../../components/loading';
import Spinner from '../../components/spinner-new/spinner';
import { getCutOutPhoto, getPhoto } from '../../services';
import { make16By9Photo, makeMergedPhoto, makeTodayCaption, resizePhoto, makeMergedTicket } from '../../utils';

import './photoDetail.scss'

const PhotoDetail = observer(() => {
  const store = useStore();
  const history = useHistory();
  const [photoFile, setPhotoFile] = useState();
  const [photoBlob, setPhotoBlob] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { onsitePhotos } = store;
  const { photoIndex } = useParams();
  const [errorFileCreating, setErrorFileCreating] = useState(false);

  const imgRef = useRef(null);

  const screen = { Screen: 'PhotoDetail' }
  const { config, backgrounds, frames, captions, session, nonBoothSessionId, mergeData, mergedPhotos, baseMergeData, mixpanel, brand } = store;

  const callback = useCallback(event => {
    trackMixpanelHardPress();
  }, []);

  const bind = useLongPress(callback, {
    // onStart: event => console.log('Press started'),
    // onFinish: event => handleLongPress(),
    // onCancel: event => console.log('Press cancelled'),
    threshold: 200,
    captureEvent: true,
    detect: 'both',
  });

  useEffect(() => {
    const redirectHome = () => {
      history.push('/')
    }

    if (store && !store.nonBoothSessionId) redirectHome();
  }, [store])

  useEffect(() => {
    const trackMixpanel = () => {
      store.mixpanel.track('PageOpened', {
        ...store.mixpanelBaseData,
        ...screen,
      });
    }

    if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
  }, [store])


  const generateFile = async () => {
    try {
      const image = imgRef.current;
      let options = { "cacheBust": false }
      const dataUrl = await domtoimage.toJpeg(image, options);
      const fetched = await fetch(dataUrl);
      const blob = await fetched.blob();
      const file = new File([blob], "phooto.jpg", { type: "image/jpeg" })
      setPhotoFile(file);
      setPhotoBlob(blob);
      store.setUserPhotoFile(file);
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      setErrorFileCreating(true)
    }

  }

  const generateFileIOS = async () => {
    let fetched
    try {
      fetched = await fetch(onsitePhotos[photoIndex || 0].url.split('?')[0]);
      const blob = await fetched.blob();
      const file = new File([blob], `FANtastic_Photo.jpg`, { type: "image/jpeg" })
      const urlFile = URL.createObjectURL(file)
      setPhotoFile(file);
      setPhotoBlob(blob);
      store.setUserPhotoFile(file);
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setErrorFileCreating(true)
      console.log('response: ', fetched)
    }
  }



  const handleClaimSouvenirTicket = async () => {
    store.mixpanel.track('TapClaim', {
      ...store.mixpanelBaseData,
      ...screen
    })
    setIsLoading(true)
    try {
      const ticket = await makeMergedTicket(onsitePhotos[photoIndex].url, store.brand.baseConfig.images.ticketTemplate)
      const fetched = await fetch(ticket);
      const blob = await fetched.blob();
      const file = new File([blob], "Souvenir_Photo.jpg", { type: "image/jpeg" })

      store.setMergedTicket(ticket);
      store.setMergedTicketFile(file);
      setIsLoading(false)
      history.push(`/souvenir-ticket/${photoIndex}`)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const handleShare = () => {
    store.mixpanel.track('TapSaveShare', {
      ...store.mixpanelBaseData,
      ...screen
    })
    navigator.canShare({ files: [photoFile] }) && navigator.share({ files: [photoFile] })
  }

  const generateNewBrandedImageFile = async () => {
    const fetched = await fetch(store.userBrandedImage);
    const blob = await fetched.blob();
    const file = new File([blob], "PGA_2022_Souvenir.jpg", { type: "image/jpeg" })
    store.setUserBrandedImageFile(file);
  }

  const handlePressPhoto = () => {
    store.mixpanel.track('TapPhotoDetail', {
      ...store.mixpanelBaseData,
      ...screen
    })
  }

  const trackMixpanelHardPress = () => {
    store.mixpanel.track('LongTapFullPhoto', {
      ...store.mixpanelBaseData,
      ...screen,
      PhotoIndex: photoIndex,
    });
  }

  const mergeTicket = async () => {
    setIsLoading(true)
    try {
      const ticket = await makeMergedTicket(onsitePhotos[photoIndex].url, store.brand.baseConfig.images.ticketTemplate)
      const fetched = await fetch(ticket);
      const blob = await fetched.blob();
      const file = new File([blob], "foto.jpg", { type: "image/jpeg" })
      store.setMergedTicket(ticket);
      store.setMergedTicketFile(file);
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }

  }

  const handleCreateSouvenir = async () => {
    setIsLoading(true)
    let photo;
    let newMergedPhotos = [...store.mergedPhotos]
    let resizedArray = []
    let originalResizedArray = []
    try {
      // const blob = await getPhoto()
      photo = await getCutOutPhoto({ photo: photoBlob, config: store.config, useUrlBlob: true });
      const resized = await resizePhoto(photo, store.brand.baseConfig.options.height)
      if (store.brand.baseConfig.options.videoMode) {
        const photo16by9 = await make16By9Photo(resized, 1080, 1920)
        store.setPhoto16By9(photo16by9)
      } else {

      }
      resizedArray.push(resized);
      store.setBoothPhotos(resizedArray);
      const photoMergeData = { ...baseMergeData };
      photoMergeData.position.centered = true;
      const mergedPhoto = await makeMergedPhoto(store, resized, photoMergeData, backgrounds, frames, captions, store.brand.baseConfig.options.resolution, true);
      const mergedPhotoObj = {
        photo: mergedPhoto.finalMerge,
        mergeData: { ...mergedPhoto.finalMergeData }
      }
      newMergedPhotos.unshift(mergedPhotoObj)
      store.setMergeData([baseMergeData, ...mergeData])
      store.setMergedPhotos(newMergedPhotos);
      history.push('/editphoto/0');
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <div className='photo-detail-container'>
      <Loading
        text={'PROCESSING...'}
        fontColor={store.brand.baseConfig.colors.loadingBarColor}
        color={brand.baseConfig.colors.loadingBarColor}
        backColor={brand.baseConfig.colors.loadingBarColorLight}
        display-if={isLoading}
      />
      <LogoHeader dark={false} showBack />
      <img
        src={onsitePhotos[photoIndex || 0].url}
        // src={store.userBrandedImage}
        className='photo-detail-image'
        alt=''
        onClick={handlePressPhoto}
        {...bind}
      />
      <p display-if={errorFileCreating}>Long Press to Share Image</p>
      <div className={clsx('photo-detail-buttons-container')}>
        <button
          className={clsx('photo-detail-decline-button', store.brand?.baseConfig?.options?.buttonStyle, 'photo-detail-share-button')}
          onClick={handleShare}
          disabled={!photoFile}
          display-if={photoFile}
        >
          <Text id='common.sharebutton' />
        </button>
        <button
          className={clsx('photo-detail-decline-button', store.brand?.baseConfig?.options?.buttonStyle, 'photo-detail-create-souvenir-button')}
          onClick={handleCreateSouvenir}
          disabled={!photoFile}
          display-if={photoFile}
        >
          <Text id='common.landingpagebutton' />
        </button>
        <button
          className={clsx('photo-detail-agree-button', store.brand?.baseConfig?.options?.buttonStyle)}
          onClick={handleClaimSouvenirTicket}
          display-if={store.brand.baseConfig.options.ticketSouvenir}
        >
          CLAIM SOUVENIR TICKET
        </button>
        <Spinner display-if={!photoFile && !errorFileCreating} />
      </div>

      <PBFooter dark={false} />
      <img
        src={onsitePhotos[photoIndex || 0].url}
        className='photo-detail-image-to-generate'
        alt=''
        ref={imgRef}
        onLoad={generateFileIOS}
      // crossorigin="anonymous"
      />
    </div>
  )
})

export default PhotoDetail;