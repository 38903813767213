import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import cx from 'clsx'
import clsx from 'clsx';
import { format } from 'date-fns';

import './editPhoto.scss'

import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { isMobile } from 'react-device-detect';

import LogoHeader from '../../components/logoHeader';
import PhotoMontage from '../../components/photoMontage';
import PBFooter from '../../components/pBFooter';
import { generateCaptions, makeMergedPhoto } from '../../utils';
import AdjustHelp from './adjustHelp';
import SelectBackground from './selectBackground';
import AddFrame from './addFrame';
import { RATIO } from '../../styles/mixins';
import Text from '../../components/text';
import { getCutOutPhoto, uploadRedbullAsset } from '../../services';
import { ReactComponent as BackgroundIcon } from './background-icon.svg'
import { ReactComponent as FrameIcon } from './frames-icon.svg';
import { FORMAT } from '../../constants';
import VerticalMenu from './verticalMenu';
import FinalVideo from '../../components/finalVideo';
import Spinner from '../../components/spinner-new/spinner';
import CaptionFrame from '../../components/captionFrame';
import VideoMontage from '../../components/videoMontage/videoMontage';
import Loading from '../../components/loading';



const EditPhoto = observer(() => {
  const store = useStore();

  let { phIx, childId } = useParams();
  const {
    mergeData,
    backgrounds,
    frames,
    captions,
    photos,
    resizedPhotos,
    mergedPhotos,
    todayCaption,
    session,
    mixpanel,
    nonBoothSessionId,
    brand,
    watermarkRemoved,
    removeWatermark,
  } = store;
  const history = useHistory();

  const [menu] = useState([
    { title: 'Adjust Photo', key: 'adjust-photo' },
    { title: 'Backgrounds', key: 'backgrounds' },
    { title: 'Frames', key: 'frames' },
  ])
  const [index, setIndex] = useState(2);

  const [bgIndex, setBgIndex] = useState(null);
  const [frIndex, setFrIndex] = useState(null);

  const [ready, setReady] = useState(false);

  const [displayAdjust, setDisplayAdjust] = useState(true);
  const [pressedSave, setPressedSave] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isVideo, setIsVideo] = useState(false);
  const [startRecording, setStartRecording] = useState(false);

  const screen = { Screen: 'EditPhoto' }

  const containerW = () => {
    if (brand.baseConfig.options.format === FORMAT.SQUARE) return RATIO < .6 ? 85 : RATIO > 1 ? 40 : 75;
    else return 45;
  }

  const containerH = () => {
    if (brand.baseConfig.options.format === FORMAT.SQUARE) return RATIO < .6 ? 85 : RATIO > 1 ? 40 : 75;
    else return 80;
  }

  const containerW_Old = RATIO < .6 ? 85 : RATIO > 1 ? 40 : 75;

  useEffect(() => {
    setTimeout(() => { setDisplayAdjust(false) }, 15000);
  }, [])

  useEffect(() => {

    if (mergedPhotos && mergeData && backgrounds && frames && captions && index !== null && bgIndex !== null && frIndex !== null && phIx !== null) setReady(true);
  }, [mergedPhotos, mergeData, backgrounds, frames, captions, index, bgIndex, frIndex, phIx])

  useEffect(() => {
    const fillBgAndFrame = () => {
      setBgIndex(mergeData[phIx].background);
      // setBgIndex(1);
      setFrIndex(mergeData[phIx].frame);
    }

    if (mergeData && phIx !== null) fillBgAndFrame();
  }, [mergeData, phIx])

  useEffect(() => {
    const setInitialMergData = () => {
      setXAxis(mergeData[phIx].position.x)
      setYAxis(mergeData[phIx].position.y)
      setModScale(mergeData[phIx].scale)
    }

    if (mergeData && phIx) setInitialMergData();
  }, [mergeData, phIx])


  useEffect(() => {
    const saveAndNavigate = () => {
      // store.setRemoveWatermark(false);
      handleDone();
    }
    if (removeWatermark) saveAndNavigate();
  }, [removeWatermark])

  const [xAxis, setXAxis] = useState(0);
  const [yAxis, setYAxis] = useState(0);
  const [modScale, setModScale] = useState(1);

  useEffect(() => {
    const captions = generateCaptions(({
      data: store.localizedDistance || '',
      shortUnit: frames[frIndex]?.shortUnit || '',
      twoLines: frames[frIndex]?.twoLines
    }))
    store.setCaptionsArray(captions)
  }, [frIndex])

  const handleDone = () => {
    if (backgrounds[bgIndex]?.video) handleMakeVideo()
    else handleMakeSouvenir()
  }

  const handleMakeVideo = async() => {    
    let updatedMergeData = [...mergeData];
    updatedMergeData[phIx].position.x = xAxis * brand.baseConfig.options.width / window.innerWidth * 100 / containerW();
    updatedMergeData[phIx].position.y = yAxis * brand.baseConfig.options.height / window.innerWidth * 100 / containerH();
    updatedMergeData[phIx].scale = modScale;
    updatedMergeData[phIx].background = brand.baseConfig.options.noBG ? bgIndex : bgIndex;
    updatedMergeData[phIx].frame = frIndex;
    const mergedPhoto = await makeMergedPhoto(store, photos[phIx], updatedMergeData[phIx], backgrounds, frames, captions, brand.baseConfig.options.resolution, false, false, true);
    store.setCustomizedUGC(mergedPhoto)
    setStartRecording(true)
  }

  const handleMakeSouvenir = async () => {
    mixpanel.track('tapDoneWithSouvenir', {
      ...store.mixpanelBaseData,
      ...screen,
    });
    setPressedSave(true);
    let updatedMergeData = [...mergeData];
    updatedMergeData[phIx].position.x = xAxis * brand.baseConfig.options.width / window.innerWidth * 100 / containerW();
    updatedMergeData[phIx].position.y = yAxis * brand.baseConfig.options.height / window.innerWidth * 100 / containerH();
    updatedMergeData[phIx].scale = modScale;
    updatedMergeData[phIx].background = brand.baseConfig.options.noBG ? bgIndex : bgIndex;
    updatedMergeData[phIx].frame = frIndex;
    store.setMergeData(updatedMergeData);

    let newMergedPhotos = [...mergedPhotos];
    let mergedPhoto;
    if (backgrounds[bgIndex]?.useOriginalPhoto) {
      mergedPhoto = await makeMergedPhoto(store, store.photo16By9, updatedMergeData[phIx], backgrounds, frames, captions, brand.baseConfig.options.resolution, false, false);
    } else {
      mergedPhoto = await makeMergedPhoto(store, brand.baseConfig.options.noBG && bgIndex === 0 ? resizedPhotos[phIx] : photos[phIx], updatedMergeData[phIx], backgrounds, frames, captions, brand.baseConfig.options.resolution, false, false);
    }

      console.log('brand.advanceConfig.options.redbullUpload', brand.advanceConfig.options.redbullUpload)
    if (brand.advanceConfig.options.redbullUpload) {
      console.log('uploadRedBull')
      uploadRedbullAsset(mergedPhoto, store.config, `WFLWR_${store.pid}_${format(new Date(), 'yyyyMMddHHmmss')}.jpg`)
    }
    newMergedPhotos[phIx].photo = mergedPhoto;
    newMergedPhotos[phIx].mergeData = { ...updatedMergeData[phIx] };
    trackMixpanel();
    store.setMergedPhotos(newMergedPhotos);
    store.setPhotoEdited(true);

    store.setRemoveWatermark(false);
    setPressedSave(false);
    if (brand.photoStrip) history.push('/selectphoto/' + phIx);
    else history.push('/finalphoto')
  }

  const trackMixpanel = () => {
    let sessionSplit;
    let sessionId = null;
    if (session) {
      sessionSplit = session.split('/');
      sessionId = sessionSplit[1];
    }
    mixpanel.track('MergeImage', {
      'Foreground': frames[frIndex].name,
      'Background': backgrounds[brand.baseConfig.options.noBG ? bgIndex : bgIndex].name,
      'BoothSessionID': sessionId,
      'NonBoothSessionID': nonBoothSessionId,
      'Brand': brand?.baseConfig.options.brand,
      ...store.mixpanelBaseData,
    })
  }

  const handleChangeCheckbox = (event) => {
    store.agreeTerms = event.target.checked
  }

  const handleSetbgIndex = (index) => {
    mixpanel.track('tapSelectBackground', {
      ...store.mixpanelBaseData,
      ...screen,
      'Index': index,
    });
    setBgIndex(index);
  }

  const handleSetFrIndex = (index) => {
    mixpanel.track('tapSelectFrame', {
      ...store.mixpanelBaseData,
      ...screen,
      'Index': index,
    });
    setFrIndex(index);
  }

  return (
    <div className="edit-photo-container">
      <Loading
        display-if={isLoading}
        color={brand.baseConfig.colors.loadingBarColor}
        backColor={brand.baseConfig.colors.loadingBarColorLight}
      />
      <div>
        <LogoHeader
          dark={false}
          alternateLogo={brand?.baseConfig.options.brand === 'retreat' || brand?.baseConfig.options.brand === 'partner' || brand?.baseConfig.options.brand === 'uva'}
          showSave={(!brand?.baseConfig.options.useWatermark || watermarkRemoved) && !removeWatermark || true}
          onSave={handleDone}
          pressed={pressedSave}
          centeredLogo={true}
        />
      </div>

      <div 
        className={clsx(brand.baseConfig.options.format === FORMAT.RECTANGULAR_16_BY_9 && 'photomontage-row')}
        display-if={!isLoading}
      >
        <div className='montage-container'>
          <PhotoMontage
            photo={photos[phIx]}
            originalPhoto={resizedPhotos[phIx]}
            noBG={brand.baseConfig.options.noBG}
            index={phIx}
            backgrounds={
              childId ?
                backgrounds.filter(b => b.brand === store.childBrands[childId].brand) :
                backgrounds.filter(b => !b.childBackground)
            }
            frames={frames}
            isAltFrameActive={!!store[brand.baseConfig.options.alternativeFrameCondition] && store.agreeTerms}
            bgId={bgIndex}
            frId={frIndex}
            setXAxis={setXAxis}
            setYAxis={setYAxis}
            modScale={modScale}
            setModScale={setModScale}
            defaultPositionX={mergeData[phIx].position.x / (brand.baseConfig.options.width / (window.innerWidth * containerW() / 100))}
            defaultPositionY={mergeData[phIx].position.y / (brand.baseConfig.options.height / (window.innerWidth * containerH() / 100))}
            defaultScale={mergeData[phIx].scale}
            todayCaption={todayCaption}
            watermark={(brand?.baseConfig.options.useWatermark && !watermarkRemoved) && brand?.baseConfig.images.watermark}
            displayAdjust={displayAdjust}
            setDisplayAdjust={setDisplayAdjust}
            useCaption={brand.useCaption}
            format={brand.baseConfig.options.format}
            // display-if={!backgrounds[bgIndex]?.video}
            xAxis={xAxis}
            yAxis={yAxis}
          />

          {/* <FinalVideo
            display-if={backgrounds[bgIndex]?.video}
            startRecording={backgrounds[bgIndex]?.video}
          /> */}
        </div>

        <VerticalMenu
          backgrounds={
            childId ?
              backgrounds.filter(b => b.brand === store.childBrands[childId].brand) :
              backgrounds.filter((b, index) => !b.childBackground)
          }
          bgIndex={bgIndex}
          setBgIndex={handleSetbgIndex}
          frames={
            childId ?
              frames.filter(f => f.brand === store.childBrands[childId].brand || f.id === 0) :
              frames.filter((f, index) => !f.childBackground)
          }
          frIndex={frIndex}
          setFrIndex={handleSetFrIndex}
          isMobile={true}
          format={FORMAT.RECTANGULAR_16_BY_9}
          display-if={brand.baseConfig.options.format === FORMAT.RECTANGULAR_16_BY_9}
        />

      </div>





      <div
        className="options-row botom-menu"
        display-if={
          (!brand?.baseConfig.options.useWatermark || watermarkRemoved) 
          && (!removeWatermark || true) 
          && brand.baseConfig.options.format !== FORMAT.RECTANGULAR_16_BY_9
          && !isLoading
          }
      >
        <div
          className={
            cx(
              'option-menu-item',
              { 'option-selected': menu[index].key === 'frames' },
              { 'option-menu-item-contrast': brand?.baseConfig.colors.primary === '#ffffff' },
              { 'option-disabled': backgrounds[bgIndex]?.maskAsset }
            )
          }
          onClick={() => !backgrounds[bgIndex]?.maskAsset && setIndex(2)}
        >
          <FrameIcon
            className={
              cx(
                'menu-icon icon',
                { 'menu-icon-selected': menu[index].key === 'frames' })}
            width="20"
            height="15"
          />
          <span
            className="option-title"
          >
            <Text id='common.frames' />
          </span>
        </div>
        <div
          className={
            cx(
              'option-menu-item',
              { 'option-selected': menu[index].key === 'backgrounds' },
              { 'option-menu-item-contrast': brand?.baseConfig.colors.primary === '#ffffff' }
            )
          }
          onClick={() => setIndex(1)}
        >
          <BackgroundIcon
            className={
              cx(
                'menu-icon icon',
                { 'menu-icon-selected': menu[index].key === 'backgrounds' })}
            width="20"
            height="15"
          />
          <span
            className="option-title"
          >
            <Text id='common.backgrounds' />
          </span>
        </div>
      </div>

      {
        ready &&
        <div 
          className="tools-container"
          display-if={
            (!brand?.baseConfig.options.useWatermark || watermarkRemoved) 
            && !removeWatermark 
            && (true && brand.baseConfig.options.format !== FORMAT.RECTANGULAR_16_BY_9)
            && !isLoading
            }
        >
          <AdjustHelp
            display-if={menu[index].key === 'adjust-photo'}
          />
          <SelectBackground
            display-if={menu[index].key === 'backgrounds'}
            backgrounds={
              childId ?
                backgrounds.filter(b => b.brand === store.childBrands[childId].brand) :
                backgrounds.filter((b, index) => !b.childBackground)
            }
            bgIndex={bgIndex}
            setBgIndex={handleSetbgIndex}
            isMobile={true}
            format={brand.baseConfig.options.format}
          />
          <AddFrame
            display-if={menu[index].key === 'frames'}
            frames={
              childId ?
                frames.filter(f => f.brand === store.childBrands[childId].brand || f.id === 0) :
                frames.filter((f, index) => !f.childBackground)
            }
            frIndex={frIndex}
            setFrIndex={handleSetFrIndex}
            isMobile={true}
            format={brand.baseConfig.options.format}
          />
        </div>
      }

      <div
        className='custom-caption-container'
        display-if={brand?.baseConfig.options.useCustomCaption && !isLoading}
      >
        <div className='input-container'>
          <input
            type={"checkbox"}
            checked={store.agreeTerms}
            // onChange={handleChangeCheckbox}
            onClick={handleChangeCheckbox}
          />
        </div>

        <p className={clsx('custom-caption-label', brand?.baseConfig.colors.primary === '#ffffff' && 'custom-caption-label-contrast')}>
          <Text id="common.displayDistance" />
        </p>
      </div>

      <div className={clsx('save-container')}>
        {/* <img
                    display-if={pressedSave}
                    src="assets/gif/loading-spinner.gif"
                    alt=""
                    className="loading-spinner"
                /> */}
        <Spinner
          display-if={pressedSave}
          className="loading-spinner"
        />
        <div
          className={clsx('save', brand?.baseConfig.colors.primary === '#ffffff' && 'contrast-save', brand.baseConfig?.options?.buttonStyle)}
          onClick={handleDone}
          display-if={!pressedSave}
        >
          <span className={clsx('text-save', brand?.baseConfig.colors.primary === '#ffffff' && 'contrast-text-save')}><Text id='common.donebutton' /></span>
        </div>
      </div>

      <PBFooter dark={false} />
      <CaptionFrame
        style={frames[frIndex]?.captionStyle}
      />
      <VideoMontage
        startRecording={startRecording}
        setStartRecording={setStartRecording}
        ugc={store.customizedUGC}
        videoSrc={backgrounds[bgIndex]?.asset}
        frameSrc={backgrounds[bgIndex]?.maskAsset || frames[frIndex]?.asset}
        frameAltSrc={frames[frIndex]?.alternaiveAsset}
        xAxis={xAxis * brand.baseConfig.options.width / window.innerWidth * 100 / containerW()}
        yAxis={yAxis * brand.baseConfig.options.height / window.innerWidth * 100 / containerH()}
        modScale={modScale}
        setIsLoading={setIsLoading}
        isAltFrameActive={!!store[brand.baseConfig.options.alternativeFrameCondition && store.agreeTerms]}
        captionFrameSrc={store.captionFrame && URL.createObjectURL(store.captionFrame)}
        phIx={phIx}
        bgIndex={bgIndex}
        frIndex={frIndex}
        onFinishVideo={() => history.push('/finalphoto/video')}
      />
    </div>
  )
})

export default EditPhoto;