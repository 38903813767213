import React from 'react';
import clsx from 'clsx'

const AdjustHelp = () => (
    <div className='instructions-container'>
        <p className="instructions">To adjust the image</p>
        <p className="instructions">pinch and zoom with your fingers.</p>
    </div>
)

export default AdjustHelp;