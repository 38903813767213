import React, { useState } from "react";
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { useStore } from '../../../../stores/store';
import { getToken, registerEmail, searchByRoomAndSurname } from '../../../../services';

import emptyCheck from './empty-check.svg'
import squareCheck from './square-check.svg'

import './guest-form.scss'

const GuestForm = observer(({ setGuestData, setToken }) => {
  const store = useStore();

  const [agree, setAgree] = useState(false)
  const [buttonPressed, setButtonPressed] = useState(false);
  const [searchError, setSearchError] = useState(false);


  const checkForm = () => {
    if (store.roomLastName && store.room && agree)
      return true;
    else return false;
  }

  const validGuest = (data) => {
    if (data.reservations.totalResults > 0) return true;
    return false;
  }

  const submit = async () => {
    try {
      const res = await getToken({ baseURL: store.config.API.OracleITBHotelURL });
      if (res.status == 200) {
        const token = res.data.token;
        setToken(token)

        const options = {
          baseURL: store.config.API.OracleITBHotelURL,
          room: store.room,
          surname: store.roomLastName,
          token,
          hotel: store.config.Hotel
        }
        const guestSearch = await searchByRoomAndSurname(options)

        if (guestSearch.status === 200) {

          if (validGuest(guestSearch.data.data)) {
            setGuestData(guestSearch.data.data)
          } else {
            setSearchError(true)
            setTimeout(() => setSearchError(false), 4000)
          }

        }
      }
    } catch (e) {
      console.log(e)
    }
  }


  return (

    <>
      <p className="register-title">Your Souvenir Photo Awaits:<br />Enter Your Info Now!</p>
      <p className="error"><span display-if={searchError}>Invalid Last Name or Room Number</span></p>
      <form className="form-container">
        <div className="input-container">
          <label className="input-label">Last Name<span className="required">*</span></label>
          <div className="input-row">
            <input
              type="text"
              value={store.roomLastName}
              placeholder="Smith"
              className={clsx('input-firstname', searchError && 'input-error')}
              onChange={event => store.setRoomLastName(event.target.value)}
            />
          </div>
        </div>
        <div className="input-container">
          <label className="input-label">Room Number<span className="required">*</span></label>
          <div className="input-row">
            <input
              type="text"
              value={store.room}
              placeholder="000"
              className={clsx('input-firstname', searchError && 'input-error')}
              onChange={event => store.setRoom(event.target.value)}
            />
          </div>
        </div>
      </form>

      <div className="consent-container">
        <div onClick={() => setAgree(!agree)} className="consent-check-container">
          {
            agree ?
              <img src={squareCheck} alt="" className="consent-check" /> :
              <img src={emptyCheck} alt="" className="consent-check" />
          }
        </div>
        <p className="agree-text">I AGREE  TO THE <span className="agree-text-link">TERMS OF SERVICE</span> AND THE <span className="agree-text-link">PRIVACY POLICY</span>.</p>
      </div>

      <div
        className={clsx('submit-button', !checkForm() && 'disabled', buttonPressed && 'edit-pressed')}
        onClick={submit}
      >
        <span className="submit-button-text">Submit</span>
      </div>
    </>

  )
})

export default GuestForm;