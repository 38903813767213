import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';

import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import { makeMergedTicket } from '../../utils';

import './souvenirTicket.scss'

const SouvenirTicket = observer(() => {
    const store = useStore();
    const history = useHistory();
    const [photoFile, setPhotoFile] = useState()
    const { onsitePhotos } = store;
    const { photoIndex } = useParams();

    const screen = { Screen: 'SouvenirTicket' }

    useEffect(() => {
        const trackMixpanel = () => {
            store.mixpanel.track('Landing', {
                ...store.mixpanelBaseData,
                ...screen,
                PhotoIndex: photoIndex,
            });
        }

        if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
    }, [store])

    useEffect(() => {
        const redirectHome = () => {
            history.push('/')
        }

        if (store && !store.nonBoothSessionId) redirectHome();
    },[store])

    // useEffect(() => {
    //     if (onsitePhotos[photoIndex].url) 
    //         generateFile(onsitePhotos[photoIndex].url);
    // }, [onsitePhotos, photoIndex]);

    // useEffect(() => {
    //    mergeTicket(); 
    // },[])

    const generateFile = async (photo) => {
        try {
            const fetched = await fetch(photo);
            const blob = await fetched.blob();
            const file = new File([blob], "foto.jpg", { type: "image/jpeg" })
            setPhotoFile(file);
        } catch (e) {
            console.log(e)
        }

    }

    const mergeTicket = async () => {
        const ticket = await makeMergedTicket(onsitePhotos[photoIndex].url, store.brand.baseConfig.images.ticketTemplate)
        store.setMergedTicket(ticket);
    }

    const handleOrder = () => {
        store.setPhotoIndex(photoIndex)
        history.push(`/shipping-information`)
    }

    const handlePhotoChange = () => {
        store.mixpanel.track('TapChangePhoto', {
            ...store.mixpanelBaseData,
            ...screen,
            PhotoIndex: photoIndex,
        });
        history.push('/select-favorite-photo')
    }

    const handleTapSouvenirTicket = () => {
        store.mixpanel.track('TapTicket', {
            ...store.mixpanelBaseData,
            ...screen,
            PhotoIndex: photoIndex,
        });
    }

    return (
        <div className='souvenir-ticket-container'>
            <LogoHeader dark={false} showBack />
            {/* <p className='souvenir-ticket-title'>CONGRATULATIONS</p> */}
            <div className='souvenir-ticket-photomontage-container' onClick={handleTapSouvenirTicket}>
                {/* <img
                    src={onsitePhotos[photoIndex || 0].url}
                    className='souvenir-ticket-image'
                    alt=''
                />
                <img 
                    src={store.brand.baseConfig.images.ticketTemplate}
                    className='souvenir-ticket-template'
                    alt=''
                /> */}
                <img 
                    src={store.mergedTicket}
                    className='souvenir-ticket-template'
                    alt=''
                />
            </div>
            <div className={clsx('souvenir-ticket-buttons-container', onsitePhotos.length === 1 && 'souvenir-ticket-buttons-centered')}>
                <button
                    className="souvenir-ticket-decline-button"
                    onClick={handlePhotoChange}
                    display-if={onsitePhotos.length !== 1}
                >
                    CHANGE PHOTO
                </button>
                <button
                    className="souvenir-ticket-agree-button"
                    onClick={handleOrder}
                >
                    ORDER
                </button>
            </div>
            <PBFooter dark={false} />
        </div>
    )
})

export default SouvenirTicket;