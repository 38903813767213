import React, { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores/store';
import { useHistory, useParams } from 'react-router-dom';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import clsx from 'clsx';

import LogoHeader from 'components/logoHeader';
import PBFooter from 'components/pBFooter';
import Text from 'components/text';
import Loading from 'components/loading';
import Card from './components/card/card';
import Spinner from 'components/spinner-new/spinner';
import { registerUser, getCutOutPhoto } from 'services';
import { makeMergedPhoto, resizePhoto } from 'utils';

import './selectYourCard.scss'

const SelectYourCard = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { onsitePhotos, config, bookingId, brand, baseMergeData, mergeData, backgrounds, frames, captions, selfieBlob } = store;
  const { photoIndex } = useParams();
  const [activeCard, setActiveCard] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const screen = { Screen: 'SelectYourCard' }

  useEffect(() => {
    const trackMixpanel = () => {
      store.mixpanel.track('PageOpened', {
        ...store.mixpanelBaseData,
        ...screen,
      });
    }

    if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
  }, [store])

  const handleSelectCard = async (event) => {
    event.preventDefault()

    try {
      setIsRegistering(true)
      const res = await registerUser({
        photo: selfieBlob,
        config: config,
        bookingID: bookingId,
        keyCard: config.OracleNFCCards[activeCard].ID
      })
      setIsRegistering(false)
      if (res.status === 200) {
        // window.alert(`register face id: ${res.data.id}`)
        createSouvenir()
      } else {
        // window.alert(`register face failed msg: ${res.data.exception}`)
        createSouvenir()
      }
    } catch (e) {
      console.log(e)
      setIsRegistering(false)
    }

  }

  const createSouvenir = async () => {
    setIsLoading(true)
    let photo;
    let newMergedPhotos = [...store.mergedPhotos]
    let resizedArray = []
    let originalResizedArray = []
    try {
      // const blob = await getPhoto()
      photo = await getCutOutPhoto({ photo: onsitePhotos[0], config: config, useUrlBlob: false });
      const resized = await resizePhoto(photo, brand.baseConfig.options.height)

      resizedArray.push(resized);
      store.setBoothPhotos(resizedArray);
      const photoMergeData = { ...baseMergeData };
      photoMergeData.position.centered = true;
      const mergedPhoto = await makeMergedPhoto(store, resized, photoMergeData, backgrounds, frames, captions, brand.baseConfig.options.resolution, true);
      const mergedPhotoObj = {
        photo: mergedPhoto.finalMerge,
        mergeData: { ...mergedPhoto.finalMergeData }
      }
      newMergedPhotos.unshift(mergedPhotoObj)
      store.setMergeData([baseMergeData, ...mergeData])
      store.setMergedPhotos(newMergedPhotos);
      // history.push('/editphoto/0');
      history.push('registration-complete')
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeSlide = (slide) => {
    setActiveCard(slide)
  }

  return (
    <div className='select-your-card__container'>
      <Loading
        text={'PROCESSING...'}
        fontColor={store.brand.baseConfig.colors.loadingBarColor}
        color={brand.baseConfig.colors.loadingBarColor}
        backColor={brand.baseConfig.colors.loadingBarColorLight}
        display-if={isLoading}
      />
      <LogoHeader dark={false} showBack />

      <span className='select-your-card__title'>
        <Text id='common.selectYourCard' markup />
      </span>
      <div className='separator-line' />

      <div className='select-your-card__carousel--main-container'>
        <div className='select-your-card__carousel--container' style={{ width: `${config.OracleNFCCards.length * 61.53}vw` }}>

          <Carousel
            infinite
            value={activeCard}
            plugins={[
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: config.OracleNFCCards.length
                }
              },
              'centered',
              'fastSwipe',
              // 'infinite'
            ]}
            offset={0}
            onChange={handleChangeSlide}
          >
            {
              config.OracleNFCCards.map((card, index) =>
                <Card item={card} index={index} active={activeCard} onClickCard={setActiveCard} />
              )
            }
          </Carousel>

        </div>
      </div>

      <div className='select-your-card__select-button--container'>
        <button
          className={clsx('select-your-card__select-button', store.brand?.baseConfig?.options?.buttonStyle)}
          onClick={handleSelectCard}
          display-if={!isRegistering}
        >
          <Text id='common.selectKeyCard' />
        </button>
        <Spinner
          display-if={isRegistering}
          className="loading-spinner"
        />
      </div>

      <PBFooter dark={false} />
    </div>
  )
})

export default SelectYourCard;