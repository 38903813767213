import React from 'react';
import clsx from 'clsx';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import Text from '../text';

import './pBFooter.scss';

const PBFooter = observer(({ dark, forceDark, screen = { Screen: null } }) => {
    const store = useStore();

    const fallbackTerms = store.lang.translate({ id: 'common.termslink', fields: null, plural: null, fallback: null, useDefault: true });
    const fallbackPrivacy = store.lang.translate({ id: 'common.privacylink', fields: null, plural: null, fallback: null, useDefault: true });
    const pbFooterLink = store.brand.baseConfig?.links?.pbLogo;

    const handlePressTerms = () => {
        store.mixpanel.track('tapTerms', {
            ...store.mixpanelBaseData,
            ...screen,
        });
        window.open(store.lang.translate({ 
            id: 'common.termslink', 
            fields: null, 
            plural: null, 
            fallback: fallbackTerms, 
            useDefault: false 
        }), "_blank")
    };

    const handlePressPrivacy = () => {
        store.mixpanel.track('tapPrivacy', {
            ...store.mixpanelBaseData,
            ...screen,
        });
        window.open(store.lang.translate({ 
            id: 'common.privacylink', 
            fields: null, 
            plural: null, 
            fallback: fallbackPrivacy, 
            useDefault: false 
        }), "_blank")
    };

    const handlePressLogo = () => {
        store.mixpanel.track('tapFooterLogo', {
            ...store.mixpanelBaseData,
            ...screen,
        });
        window.open(pbFooterLink, '_blank')
    }

    return (
        <div className={clsx('pb-footer', dark && 'dark-footer')}>
            <p b 
                className={clsx('terms', dark && 'light-text')} 
                onClick={handlePressTerms} 
                style={{ textTransform: store.brand.baseConfig.options.textTransform }}
            >
                <Text id='common.termslinkname' />
            </p>
            <p 
                className={clsx('privacy', dark && 'light-text')} 
                onClick={handlePressPrivacy} 
                style={{ textTransform: store.brand.baseConfig.options.textTransform }}
            >
                <Text id='common.privacylinkname' />
            </p>
            <img 
                src={
                    dark && store.brand.primary !== '#ffffff' || forceDark ? 
                    store.brand.baseConfig.images.footerBlack : 
                    store.brand.baseConfig.images.footer} 
                className="pb-footer-svg" 
                alt='' 
                onClick={pbFooterLink && handlePressLogo}
            />
        </div>
    )
})

export default PBFooter;