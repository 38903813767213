import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import './card.scss';

const Card = observer(({ item, index, active, onClickCard }) => {

  const handleClickCard = () => {
    active !== index && onClickCard(index)
  }

  return (
    <div className={clsx('card__container', active !== index && 'card__non-active')}>
      <img src={item.url} className='card__image' onClick={handleClickCard}/>
    </div>
  )
});

export default Card;