import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

const HelpCarouselItem = observer(({ item, index, active }) => {
    
    return (
        <div className={clsx('help-item-container', active !== index && 'non-active-item')}>
            <div className="title-row">
                <div className="help-item-number-container">
                    <p className="help-item-number">{index + 1}</p>
                </div>
                <div className="help-item-title">
                    {item?.text}
                </div>
            </div>
            <div className="help-img-container">
                <img src={item?.img} className={clsx('help-img', (index === 1 || index === 2) && 'midle-item')} alt='' />
            </div>
        </div>
    )
});

export default HelpCarouselItem;