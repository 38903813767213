import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import clsx from 'clsx';

import './patriotsPromotionalContent.scss'
import FantasticPhotos from './fantasticPhotos';

const PromotionalContent = observer(() => {
    const store = useStore();
    const { brand } = store;

    return (
        <div className="patriots__promotional-content-container">
            <div className="patriots__promotional-group">
                <p className="patriots__promotional-text">
                    NEW ENGLAND
                    <span className="patriots__new-england-title-bold"> PATRIOTS</span>
                </p>
                <p className="patriots__training-camp">
                    TRAINING CAMP 2021
                </p>
            </div>
            <div className="patriots__line" />
            <img src="assets/brands/patriots/camp-logo.png" className="patriots__camp-logo" alt="" />
            <div className="patriots__photo-container">
                <img src={brand.baseConfig.images.promoStrip} className="patriots__photo" alt='' />
            </div>
            <FantasticPhotos color={brand.baseConfig.colors.fantasticPhotosColor} />
            <p className="patriots__fun-line">
                it's fun to be a <span className="patriots__fun-line-fan">fan</span>
            </p>
        </div>
    )
});

export default PromotionalContent;