import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react-lite';
import { registerEmail } from '../../services';
import { useStore } from '../../stores/store';
import clsx from 'clsx';

import './chooseYourTeamModal.scss'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
    }
};



export const ChooseYourTeamModal = observer(({ isOpen, setIsOpen }) => {
    const store = useStore();

    useEffect(() => {
        const trackMixpanel = () => {
            store.mixpanel.track('View_Team_Selection', {
                'SessionID': store.nonBoothSessionId,
            });
        }

        if (isOpen && store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
    }, [store, isOpen])

    const selectTeam = (team) => {
        store.setTeamSelected(team);
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            class="email-modal"
            id="choose-team-modal"
            style={customStyles}
            className="team-modal-container"
            overlayClassName="modal-overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="team-modal-content">
                <div className="close-modal-container" onClick={() => setIsOpen(false)}>
                    <img src="assets/svg/black-close.svg" className="close-modal-x" alt="" />
                </div>
                <div className='submit-container'>
                    <p className="register-title">CHOOSE YOUR TEAM</p>

                    <div className={clsx('submit-button', 'primary-team-button')} onClick={() => selectTeam(0)}>
                        <p className="submit-button-text">#GOUSA</p>
                    </div>

                    <div className={clsx('submit-button', 'secondary-team-button')} onClick={() => selectTeam(1)}>
                        <p className="submit-button-text">#TEAMEUROPE</p>
                    </div>

                    <div>
                        <p className="register-title secondary-register-title">OR</p>
                        <div className={clsx('submit-button', 'default-team-button')} onClick={() => selectTeam(2)}>
                            <p className="submit-button-text">#RYDERCUP</p>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
})

export default ChooseYourTeamModal;