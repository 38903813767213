import React from 'react';
import { observer } from 'mobx-react-lite';

import Text from 'components/text';

import './checkboxRow.scss'

const CheckboxRow = observer(({ checkboxState, checkboxKey, textId, onInputChange, children }) => {

  return (
    <div className='terms-and-conditions__agreement-row'>
      <div className='terms-and-conditions__input--container'>
        <input
          type={"checkbox"}
          checked={checkboxState}
          onClick={(event) => onInputChange(event, checkboxKey)}
        />
      </div>

      <span className='terms-and-conditions__label'>
        <Text id={textId} markup />
        {children}
      </span>
    </div>
  )
});

export default CheckboxRow;