import axios from 'axios';

export const orderTicket = ({ config, sessionId, imageURL, shippingInfo }) => {
    const axiosConfig = {
        headers: {
            Authorization: 'Bearer ' + config.Token,
            'Content-Type': 'application/json'
        }
    }
    const payload = {
        "sessionId": sessionId || "abc-123-def-456-ghi",
        "campaignId": config.campaignId || "jkl-789-mno-012-pqr",
        "image": imageURL,
        "shippingInfo": shippingInfo,
    }
    return axios.post(
        config.API.OrderTicket,
        // 'https://cors-anywhere.herokuapp.com/https://partners-dev.photobutler.com/pb/order_ticket',
        payload,
        axiosConfig,
    );
}

export const getToken = ({ baseURL }) => {
    return axios.post(`${baseURL}/getToken`)
}

export const searchByRoomAndSurname = ({ baseURL, room, surname, token, hotel }) => {
    const axiosConfig = {
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return axios.get(`${baseURL}/rsv/v1/hotels/${hotel}/reservations?roomId=${room}&searchType=InHouse&surname=${surname}`, axiosConfig)
}

export const addCharge = ({ baseURL, payload, token, hotel, reservationId }) => {
    const axiosConfig = {
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return axios.post(`${baseURL}/csh/v0/hotels/${hotel}/reservations/${reservationId}/charges`, payload, axiosConfig)
}