import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory } from 'react-router-dom';

import './help.scss';
import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import HelpCarousel from './helpCarousel';
import Dots from './dots';

const Help = observer(() => {
    const history = useHistory();
    const store = useStore();
    const { brand } = {};

    const [active, setActive] = useState(0);

    const handleChangeSlide = value => {
        setActive(value);
    }

    return (
        <div className="help-container">

            <LogoHeader dark={false} showActions={false} fixed={true} />

            <HelpCarousel onChangeSlide={handleChangeSlide} list={brand.baseConfig.help} active={active} />

            <Dots onChangeSlide={handleChangeSlide} list={brand.baseConfig.help} active={active} />

            <button className="got-it-button" onClick={() => history.goBack()}>GOT IT!</button>

            <PBFooter dark={false} />

        </div>
    )
});

export default Help;