import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import EXIF from "exif-js";
// import { isAndroid, isMobile } from 'react-device-detect';
import Jimp from 'jimp';
import clsx from 'clsx'
// import useScreenOrientation from 'react-hook-screen-orientation'


import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { makeMergedPhoto, makeTodayCaption, resizePhoto } from '../../utils';
import { format } from 'date-fns';

import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import { v4 as uuidv4 } from 'uuid';
import { getCutOutPhoto, uploadAsset, uploadSelfieSearch, getPhoto } from '../../services';
import Loading from '../../components/loading';
import Text from '../../components/text';

import './facialSearchMenu.scss';
import { RATIO, WIDTH } from '../../styles/mixins';

const SCREEN = 'Face_Search_Menu';

const FacialSearchMenu = observer(() => {
    const store = useStore();

    const { config, backgrounds, frames, captions, session, nonBoothSessionId, mergeData, mergedPhotos, baseMergeData, mixpanel, brand } = store;
    const history = useHistory();
    const { referralurl, childId, showLoader } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const createNewNonSessionId = () => {
            const newNonBoothSessionId = uuidv4();
            store.setNonBoothSessionId(newNonBoothSessionId);
        }

        if (!session) createNewNonSessionId();
    }, [store, session])

    useEffect(() => {
        const handleErrorShowing = () => {
            setTimeout(() => setShowError(false), 3000);
        }

        if (showError) handleErrorShowing();
    }, [showError])

    useEffect(() => {
        const newTodayCaption = async () => {
            const black = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'black')
            const white = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'white')
            store.setTodayCaption({
                black,
                white
            });
        }

        if (frames && captions && brand.baseConfig.options.useCaption) newTodayCaption();
    }, [store, frames, captions])

    const submitPhoto = async (index) => {
        setIsLoading(true);
        const blob = await getPhoto(brand.baseConfig.images.searchFiles[index])
        const urlBlob = URL.createObjectURL(blob.data)
        store.setUserPhoto(urlBlob);
        let res
        try {
            res = await uploadSelfieSearch({ photo: urlBlob, config: store.config, overrideSessionId: store.wallSessionId })
            store.mixpanel.track('SelfieUploadedToSearch', {
                ...store.mixpanelBaseData,
                ...screen,
            })
            // const res = await uploadSelfieSearchOld(blob, store.config))
            if (res.status === 200 && res.data?.assets?.length > 0) {
                let assets = [...res.data.assets];
                store.mixpanel.track('FindResults', {
                    ...store.mixpanelBaseData,
                    ...screen,
                    'AssetCount': assets.length,
                });
                assets.sort(function (a, b) {
                    if (a.score < b.score) return -1;
                    if (a.score > b.score) return 1;
                    return 0;
                })
                if (assets[0].confidence !== 'HIGH') {
                    history.push('/no-photo-found');
                    return;
                }
                let onsitePhotosArray = [...assets];

                store.setOnsitePhotos(onsitePhotosArray);

                // history.push('/finalphoto?facialRecognition=true');
                if (assets.length === 1) handleBrandImage(onsitePhotosArray[0].url)
                else {
                    setIsLoading(false)
                    history.push('/select-favorite-photo');
                }
            } else if (res.status === 400) {
                setIsLoading(false)
                store.setError(res.data);
                history.push(`./error`);
            } else {
                setIsLoading(false)
                history.push('/no-matches')
            }
            // await store.setOnsitePhotos(res);
        } catch (e) {
            console.log(e)
            setIsLoading(false)
            // console.log('error_code: ', res.error_code)
            store.setError(res);
            store.mixpanel.track('ErrorSearchingResults', {
                ...store.mixpanelBaseData,
                ...screen,
                Error: e,
                ErrorCode: res?.error_code,
            })
            history.push(`./error`);
            // store.mixpanelTrack('LoadingModalClose', { ...screen })
        }

        // history.push('/finalphoto?facialRecognition=true');
    }

    const handleTapSelene = () => {
        mixpanel.track('TapSelene', {
            ...store.mixpanelBaseData,
            'Screen': SCREEN,
        })
        submitPhoto(0)
    }

    const handleTapBenoit = () => {
        mixpanel.track('TapBenoit', {
            ...store.mixpanelBaseData,
            'Screen': SCREEN,
        })
        submitPhoto(1)
    }

    const handleTapMyPhotos = () => {
        mixpanel.track('TapMyPhotos', {
            ...store.mixpanelBaseData,
            'Screen': SCREEN,
        })
        history.push('/terms')
    }

    return (
        <div className="facial-search-menu-container">
            <Loading
                display-if={isLoading || showLoader}
                color={brand.baseConfig.colors.loadingBarColor}
                backColor={brand.baseConfig.colors.loadingBarColorLight}
            />

            <LogoHeader
                dark={true}
                alternateLogo={brand.baseConfig.options.brand === 'carnival'}
                childId={childId}
                hideBackButton={true}
                showFlag
            />

            <p className="facial-search-menu__promotional-text">
                <span className="facial-search-menu__title-bold" lang='pl'><Text id='common.title' /></span>
            </p>
            <p className="facial-search-menu__subtitle">
                <div className="facial-search-menu__line" />
                <div className="facial-search-menu__white-line" />
                <span className='facial-search-menu__subtitle-text'><Text id='common.subtitle' /></span>
            </p>

            <div className='facial-search-menu_main-content' display-if={!isLoading}>
                <div>

                    <button className='start-button' onClick={handleTapSelene}>
                        SELENE
                    </button>

                    <button className='start-button'  onClick={handleTapBenoit}>
                        BENOIT
                    </button>

                    <button className='start-button' onClick={handleTapMyPhotos} >
                        <Text id='common.myphotos' />
                    </button>
                </div>


                <img
                    src={store.brand.baseConfig.images.landingMenu}
                    className='facial-search-menu__img'
                    alt=''
                />
            </div>

            <PBFooter dark={true && (brand.baseConfig.options.brand !== 'retreat' && brand.baseConfig.options.brand !== 'partner' && brand.baseConfig.options.brand !== 'rydercup')} />
        </div>
    )
});

export default FacialSearchMenu;

