import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { useStore } from '../../stores/store';

import './captionFrame.scss'

const width = window.innerWidth;

const CaptionFrame = observer(({ style = [], isScaledComponent }) => {
  const store = useStore();
  const canvasRef = useRef(null);
  const canvasRefScaled = useRef(null)
  const [ratio, setRatio] = useState(1)
  const [line1Styles, setLine1Styles] = useState({})
  const [line2Styles, setLine2Styles] = useState({})
  const [containerLine1Styles, setContainerLine1Styles] = useState({})
  const [containerLine2Styles, setContainerLine2Styles] = useState({})

  useEffect(() => {
    handleResize()
  }, [])

  useEffect(() => {
    if (isScaledComponent) {
      window.addEventListener('resize', handleResize)
    }
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, []);

  useEffect(() => {
    if (store.captionsArray?.length > 0 && style && canvasRef) {
      if (isScaledComponent) return
      else setTimeout(() => generateFrame(), 500)
    }
  }, [style, store.captionsArray]);

  const handleResize = () => {
    if (!isScaledComponent) return
    const width = window.innerWidth;
    const height = window.innerHeight;
    const screenRatio = (width / height);
    console.log('screenRatio: ', screenRatio)
    const photoWidth = (screenRatio <= .6) ? 0.85 : 0.75
    setRatio((width / 1080) * photoWidth)
  }

  const {
    top,
    left,
    rotation,
    color,
    fontSize,
    fontWheight,
    fontStyle,
    textAlign
  } = style[0] || {};

  const {
    top: top2,
    left: left2,
    rotation: rotation2,
    color: color2,
    fontSize: fontSize2,
    fontWheight: fontWheight2,
    fontStyle: fontStyle2,
    textAlign: textAlign2
  } = style[1] || {};

  const font = `${fontWheight} ${parseInt(fontSize * ratio)}px ${fontStyle}`
  const font2 = `${fontWheight2} ${parseInt(fontSize2 * ratio)}px ${fontStyle2}`

  const generateFrame = () => {

    if (isScaledComponent) return

    let canvas = canvasRef.current;
    let ctx = canvas.getContext('2d');

    if (!font) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = font;
    ctx.fillStyle = color;
    ctx.textAlign = textAlign;

    ctx.save()
    ctx.translate(left * ratio, top * ratio);
    ctx.rotate(-rotation * Math.PI / 180)
    ctx.translate(-left * ratio, -top * ratio);

    ctx.fillText(store.captionsArray[0], left * ratio, top * ratio);
    ctx.restore()

    if (font2 && store.captionsArray[1]) {

      ctx.font = font2;
      ctx.fillStyle = color2;
      ctx.textAlign = textAlign2;

      ctx.save()
      ctx.translate(left2 * ratio, top2 * ratio);
      ctx.rotate(-rotation2 * Math.PI / 180)
      ctx.translate(-left2 * ratio, -top2 * ratio);

      ctx.fillText(store.captionsArray[1], left2 * ratio, top2 * ratio);
      ctx.restore()
    }

    if (isScaledComponent) return
    canvas.toBlob((blob) => {

      store.setCaptionFrame(blob);
    },
      'image/png',
      1.0
    );
  }

  const getTextContainerStype = ({ line }) => {
    if (line === 1) {
      return {
        position: 'absolute',
        top: top * ratio,
        left: left * ratio,
        justifyContent: textAlign
      }
    } else {
      return {
        position: 'absolute',
        top: top2 * ratio,
        left: left2 * ratio,
        justifyContent: textAlign2
      }
    }
  }

  const getTextStyle = ({ line }) => {
    if (line === 1) {
      return {
        fontWheight,
        fontSize: `${parseInt(fontSize * ratio)}px`,
        fontStyle,
        lineHeight: `${parseInt(fontSize * ratio * .7)}px`,
        color,
        transform: `rotate(${-rotation}deg)`
      }
    } else {
      return {
        fontWheight2,
        fontSize: `${parseInt(fontSize2 * ratio)}px`,
        fontStyle2,
        lineHeight: `${parseInt(fontSize2 * ratio * .7)}px`,
        color,
        transform: `rotate(${-rotation2}deg)`
      }
    }
  }

  useEffect(() => {
    if (isScaledComponent) {
      setLine1Styles(getTextStyle(1))
      setLine2Styles(getTextStyle(2))
      setContainerLine1Styles(getTextContainerStype(1))
      setContainerLine2Styles(getTextContainerStype(2))
    }
  }, [ratio])

  return (
    <div className={clsx('caption-frame-container', isScaledComponent && 'caption-frame-scaled')} style={{ font: font }}>
      <canvas
        width={1080 * ratio}
        height={1080 * ratio}
        ref={isScaledComponent ? canvasRefScaled : canvasRef}
      ></canvas>
      <div className='caption-frame-text-container' style={getTextContainerStype({ line: 1, ratio })}>
        <span className={'caption-frame-text'} style={getTextStyle({ line: 1, ratio })}>{store.captionsArray[0]}</span>
      </div>
      <div className='caption-frame-text-container' style={getTextContainerStype({ line: 2, ratio })}>
        <span className={'caption-frame-text'} style={getTextStyle({ line: 2, ratio })}>{store.captionsArray[1]}</span>
      </div>
    </div>
  )
})

export default CaptionFrame;