import React, { useState } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../stores/store';
import PBFooter from '../../components/pBFooter';
import clsx from 'clsx';

import './fantasticPhotosModal.scss'

const customStyles = {
    content: {
        position: 'absolute',
        bottom: '0'
    }
};



export const FantasticPhotosModal = observer(({ isOpen, setIsOpen, onCreatePhoto }) => {
    const store = useStore();
    const history = useHistory();
    const { brand } = store;
    // const onCreatePhoto = () => {
    //     setIsOpen(false);
    //     history.push('/fantasticphotos')
    // }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            class="email-modal"
            id="fantastic-photo-modal"
            style={customStyles}
            className="fantastic-photo-container"
            overlayClassName="modal-overlay"
            shouldCloseOnOverlayClick={true}

        >
            <div className="team-modal-content">
                <div className="close-modal-container" onClick={() => setIsOpen(false)}>
                    <img src="assets/svg/black-close.svg" className="close-modal-x" alt="" />
                </div>
                <div className='fantastic-photos-container'>
                    <img src="assets/brands/rydercup/fantastic-photos_logo.svg" className="rydercup__fantastic-photo-svg" />
                    <div>
                        <p className="rydercup__promotional-text-2">Snap and share<br />exclusive experiences from<br />the Ryder Cup</p>
                    </div>
                    <div className='rydercup__photo-modal-container'>
                        <img src={brand.baseConfig.images.promoStrip} className="rydercup__photo" alt='' />
                    </div>

                    <div className={clsx('submit-button', 'primary-team-button')} onClick={onCreatePhoto}>
                        <p className="submit-button-text">Create Photo</p>
                    </div>

                    <div className='footer-modifier'>
                        <PBFooter dark={true && (brand.baseConfig.options.brand !== 'retreat' && brand.baseConfig.options.brand !== 'partner' && brand.baseConfig.options.brand !== 'rydercup')} />
                    </div>
                </div>

            </div>


        </Modal>
    )
})

export default FantasticPhotosModal;