import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Check } from './check.svg';
import clsx from 'clsx';
import { FORMAT } from '../../constants';

const AddFrame = observer(({ frames, frIndex, setFrIndex, isMobile = false, format }) => (
    <div>
        <p 
            className="option-menu-item frames-option-menu-item"
            display-if={!isMobile}
        >
            Frames
        </p>
        <ScrollContainer 
            horizontal={isMobile && format === FORMAT.SQUARE}
            vertical={!isMobile || format === FORMAT.RECTANGULAR_16_BY_9}
            className={clsx('scroll-container', format === FORMAT.RECTANGULAR_16_BY_9 && 'scroll-container-16-by-9', frames?.length <= 5 && 'centered', !isMobile && 'frame-scroll-container')} 
            nativeMobileScroll={false}
        >

            {
                frames?.map((frame, index) => {
                    return (
                        <div className="thumbnail-container" key={frame.asset} onClick={() => setFrIndex(index)}>
                            <Check className="check" display-if={frIndex === index} />
                            <img src={frame.thumbnail} className={clsx('thumbnail dashed', frIndex === index && 'selected')} alt=''/>
                        </div>

                    )
                })
            }
        </ScrollContainer>
    </div>
))

export default AddFrame;