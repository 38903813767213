import React, { useState } from "react";
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Lottie from "lottie-react";

import { useStore } from '../../../../stores/store';
import unlockAnimation from './unlock.json';
import confettiAnimation from './confetti.json';

import './confirm-purchase.scss'

const ConfirmPurchase = observer(({ token, onConfirm }) => {
  const store = useStore();

  const [agree, setAgree] = useState(false)
  const [buttonPressed, setButtonPressed] = useState(false);

  
  const submit = async () => {
    try {

      store.setPurchaseComplete(true);
      store.setWatermarkRemoved(true);
      store.setRemoveWatermark(true);
    } catch (e) {
      console.log(e)
    }
  }


  return (

      <>
        <p className="register-title">Unlock Your Souvenir Photo!</p>
        <p className="register-subtitle">Available for $0.99.</p>

        <div className="register-unlock-animation-container">
          <Lottie animationData={unlockAnimation} loop={true} />
          <Lottie animationData={confettiAnimation} loop={true} className="register-confetti" />
        </div>

        <p className="register-advice">By tapping "I accept", you acknowledge and confirm that your room will be charged $0.99 for the purchase of a souvenir photo. Taxes may apply.</p>
        <div
          className={clsx('submit-button', buttonPressed && 'edit-pressed')}
          onClick={onConfirm}
        >
          <span className="submit-button-text" onClick={submit}>I Accept</span>
        </div>
      </>

  )
})

export default ConfirmPurchase;