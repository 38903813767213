import React from 'react';
import { observer } from 'mobx-react-lite';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import HelpCarouselItem from './helpCarouselItem';

const HelpCarousel = observer(({ list, onChangeSlide, active }) => {
    return (
        <div className="main-carousel-container">
            <div className="carousel-container">
                <Carousel
                    value={active}
                    plugins={[
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                                numberOfSlides: 3
                            }
                        },
                        'centered',
                        'fastSwipe'
                    ]}
                    offset={0}
                    onChange={onChangeSlide} >
                    {
                        list?.map((item, index) => <HelpCarouselItem item={item} index={index} active={active} />)
                    }
                </Carousel>
            </div>
        </div>
    )
});

export default HelpCarousel;