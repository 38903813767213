import React from 'react';

import MergedPhoto from './mergedPhoto';
import './selectPhoto.scss';

const PhotoStrip = ({ photos, onSelectPhoto, ...bind }) => (
    <div className="photo-strip-container">
        {/* <p className="tap-text">Tap a photo to edit</p> */}
        {
            photos?.map((item, index) => <MergedPhoto item={item} index={index} onSelectPhoto={onSelectPhoto} key={item?.index} {...bind} />)
        }
    </div>
)

export default PhotoStrip;