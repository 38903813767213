import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

import RoutesFP from './routesFP';
import RoutesFS from './routesFS';
import RoutesTS from './routesTS';
import RoutesLab from './routesLab';

import Home from 'containers/home';
import ExtendedFPHome from 'containers/extendedFPHome';
import LandingMenu from 'containers/landingMenu';
import Welcome from 'containers/welcome';

import { ENTRY_POINT } from 'constants/entryPoint';

const Routes = ({
  brand,
  extendedFP,
  childBrands,
  sharedHome,
  ticketSouvenir,
  faceSearch,
  fantasticPhotos,
  oracleLab,
  entryPoint = ENTRY_POINT.FANTASTIC_PHOTOS
}) => {

  const getEntryPoint = () => {
    switch (entryPoint) {
      case ENTRY_POINT.FANTASTIC_PHOTOS:
        return Home;
      case ENTRY_POINT.FACE_SEARCH:
        return ExtendedFPHome;
      case ENTRY_POINT.LANDING_MENU:
        return LandingMenu;
      case ENTRY_POINT.WELCOME:
        return Welcome;
      default:
        return Home;
    }
  }

  return (
    <Switch>
      <>
        <RoutesFP fantasticPhotos={fantasticPhotos} />
        <RoutesFS faceSearch={faceSearch} />
        <RoutesTS ticketSouvenir={ticketSouvenir} />
        <RoutesLab oracleLab={oracleLab} />
        <Route path="/" exact component={getEntryPoint()} />

        {/* <Route path="/" exact component={ExtendedFPHome} /> */}
      </>
    </Switch>
  )
}

export default Routes;