import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import EXIF from "exif-js";
// import { isAndroid, isMobile } from 'react-device-detect';
import Jimp from 'jimp';
import clsx from 'clsx'
// import useScreenOrientation from 'react-hook-screen-orientation'


import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { makeMergedPhoto, makeTodayCaption, resizePhoto } from '../../utils';
import { format, parseJSON } from 'date-fns';

import LogoHeader from '../../components/logoHeader';
import MainContent from './mainContent';
import PromotionalContent from '../../components/promotionalContent';
import PromotionalContentV2 from '../../components/promotionalContentV2';
import PatriotsPromotionalContent from '../../components/patriotsPromotionalContent';
import RetreatPromotionalContent from '../../components/retreatPromotionalContent';
import PBFooter from '../../components/pBFooter';
import { v4 as uuidv4 } from 'uuid';
import { getCutOutPhoto, uploadAsset } from '../../services';
import Spinner from '../../components/spinner/spinner';
import Loading from '../../components/loading';
import LoadingRydercup from '../../components/loadingRydercup';
import LoadingScreen from '../../components/loadingScreen';
import CustomContent from './customContent';
import Text from '../../components/text';
import { toJS } from 'mobx';

import './homePreview.scss';
import { RATIO, WIDTH } from '../../styles/mixins';

import { getBrandConfigJson, getBrandBackgroundsJson, getBrandFramesJson, getBrandCaptionJson, getBrandMergeJson, getBransLangsJson } from '../../services';


const topPadding = (100 ** ((RATIO / 1.7)) - 10).toFixed(0).replace(',', '.') + 'px';

const urlSearchParams = new URLSearchParams(window.location.search);


const HomePreview = observer(() => {
    const store = useStore();

    const { config, backgrounds, frames, captions, session, nonBoothSessionId, mergeData, mergedPhotos, baseMergeData, mixpanel, brand } = store;
    const history = useHistory();
    const { referralurl, childId } = useParams();
    const [isLoading, setIsloading] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const brandSelect = urlSearchParams.get('brand')?.replace('/', '')
            if (brandSelect) {

                let url = `${config.Proxy || ''}${config.PreviewUrl}`
                const brand = await getBrandConfigJson(brandSelect, url)
                if (brand) {
                    brand.baseConfig.options.brand = store.brandId
                    for (const [key, value] of Object.entries(brand?.baseConfig?.images)) {
                        brand.baseConfig.images[key] = `${config.PreviewUrl}/${brandSelect}/${value}`
                    }
                    store.setBrand(brand)
                    setRootColors({
                        primary: brand.baseConfig.colors.primary
                        , secondary: brand.baseConfig.colors.secondary
                        , tertiary: brand.baseConfig.colors.tertiary
                        , quaternary: brand.baseConfig.colors.quaternary
                    })
                }

                const langs = await getBransLangsJson(brandSelect, url)
                if (langs) {
                    store.updateLangInfo('langs', langs)
                }

                const backgrounds = await getBrandBackgroundsJson(brandSelect, url)
                if (backgrounds) {
                    for (const index in backgrounds) {
                        backgrounds[index].thumbnail = `${config.PreviewUrl}/${brandSelect}/${backgrounds[index].thumbnail}`
                        backgrounds[index].asset = `${config.PreviewUrl}/${brandSelect}/${backgrounds[index].asset}`
                    }
                    store.setBackgrounds(backgrounds)
                }


                const frames = await getBrandFramesJson(brandSelect, url)
                if (frames) {
                    for (const index in frames) {
                        frames[index].thumbnail = `${config.PreviewUrl}/${brandSelect}/${frames[index].thumbnail}`
                        frames[index].asset = `${config.PreviewUrl}/${brandSelect}/${frames[index].asset}`
                    }
                    store.setFrames(frames)
                }

                /*                
                const caption = await getBrandCaptionJson(brandSelect, config.PreviewUrl)
                if (caption) {
                    store.setCaptions(caption)
                }

                const mergeData = await getBrandMergeJson(brandSelect, config.PreviewUrl)
                if (mergeData) {
                    store.setMergeData(mergeData)
                }
                */
            }
        }
        init()
    }, [])

    useEffect(() => {
        store.setIframeViewer();
    }, [store])

    useEffect(() => {
        const createNewNonSessionId = () => {
            const newNonBoothSessionId = uuidv4();
            store.setNonBoothSessionId(newNonBoothSessionId);
        }

        if (!session) createNewNonSessionId();
    }, [store, session])

    useEffect(() => {
        const handleErrorShowing = () => {
            setTimeout(() => setShowError(false), 3000);
        }

        if (showError) handleErrorShowing();
    }, [showError])

    useEffect(() => {
        const newTodayCaption = async () => {
            const black = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'black')
            const white = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'white')
            store.setTodayCaption({
                black,
                white
            });
        }

        if (frames && captions && brand.baseConfig.options.useCaption) newTodayCaption();
    }, [store, frames, captions])

    useEffect(() => {
        const trackMixpanel = () => {
            mixpanel.track('LaunchWebExperience', {
                'BoothSessionID': session?.split('/')[1] || null,
                'NonBoothSessionID': nonBoothSessionId,
                'ReferralURL': referralurl,
                'Brand': brand.baseConfig.options.brand,
                ...store.mixpanelBaseData,
            });
        }

        if (store && mixpanel && mixpanel.config?.token != null) trackMixpanel();
    }, [referralurl, session, mixpanel, nonBoothSessionId, store])

    const setRootColors = ({ primary, secondary, tertiary, quaternary }) => {
        let root = document.querySelector(':root');
        root.style.setProperty('--color-primary', primary);
        root.style.setProperty('--color-secondary', secondary);
        root.style.setProperty('--color-tertiary', tertiary);
        root.style.setProperty('--color-quaternary', quaternary);
    }

    const onSetFile = async ({ target: { files: [file] } }) => {
        setIsloading(true)
        EXIF.getData(file);
        const orientation = await new Promise(resolve => {
            EXIF.getData(file, () => {
                resolve(EXIF.getTag("Orientation"));
            })
        })
        let photo;
        let orientedPhoto;
        let urlOrientedPhoto;
        let urlNoOrientedPhoto;
        try {
            orientedPhoto = await checkOrientation(file);
            urlOrientedPhoto = URL.createObjectURL(orientedPhoto);
            urlNoOrientedPhoto = URL.createObjectURL(file);
            photo = await getCutOutPhoto({ photo: orientedPhoto, config });
        } catch (e) {
            console.log(e)
            setShowError(true)
        }
        try {
            let newMergedPhotos = [...mergedPhotos]
            let resizedArray = []
            let originalResizedArray = []
            const resized = await resizePhoto(photo, brand.baseConfig.options.height)
            const originalResized = await resizePhoto(urlNoOrientedPhoto, brand.baseConfig.options.height)
            resizedArray.push(resized);
            originalResizedArray.push(originalResized);
            store.setBoothPhotos(resizedArray);
            store.setResizedPhotos(originalResizedArray);
            const photoMergeData = { ...baseMergeData };
            photoMergeData.position.centered = true;
            const mergedPhoto = await makeMergedPhoto(store, resized, photoMergeData, backgrounds, frames, captions, brand.baseConfig.options.resolution, true);
            // await uploadAsset(mergedPhoto, config, `WFLWR_${store.pid}_${format(new Date(), 'yyyyMMddHHmmss')}.jpg`)
            const mergedPhotoObj = {
                photo: mergedPhoto.finalMerge,
                mergeData: { ...mergedPhoto.finalMergeData }
            }
            newMergedPhotos.unshift(mergedPhotoObj)
            store.setMergeData([baseMergeData, ...mergeData])
            store.setMergedPhotos(newMergedPhotos);
            if (childId) history.push(`/editphoto/0/${childId}`);
            else history.push('/editphoto/0');
            // history.push('/finalPhoto')
        } catch (e) {
            console.log(e)
            setIsloading(false)
            setShowError(true)
        }
    }

    const checkOrientation = async (file) => {
        const exifdata = file['exifdata'];
        if (file.exifdata) {
            const { Orientation } = file.exifdata;
            switch (Orientation) {
                case 0:
                case 1:
                case 2:
                    return file;
                case 3:
                    return await rotated(file, 180);
                // return file;
                case 4:
                    return await rotated(file, 0);
                case 5:
                case 6:
                    return await rotated(file, 0);
                case 7:
                case 8:
                    return await rotated(file, 0);
                default:
                    return file;
            }
        } else {
            return file;
        }
    }

    const rotated = async (file, degrees) => {
        const url = URL.createObjectURL(file);
        const image = await Jimp.read(url);
        await image.resize(Jimp.AUTO, brand.baseConfig.options.resolution);
        await image.rotate(degrees);
        // if (!isAndroid) {
        //     console.log('is Android: ', isAndroid)
        //     console.log('flip image')
        // }
        await image.flip(true, true);
        const rotated = await image.getBase64Async(Jimp.MIME_JPEG)
        const fetched = await fetch(rotated);
        const blobToReturn = await fetched.blob();
        return blobToReturn;
    }

    const handlePressVideo = () => {

    }


    return (
        <div className="home-container">
            <Loading
                display-if={isLoading}
                color={brand.baseConfig.colors.loadingBarColor}
                backColor={brand.baseConfig.colors.loadingBarColorLight}
            // text={<Text id='common.preparingPhotos' /> || 'loading...'}
            />

            <LogoHeader dark={true} alternateLogo={brand.baseConfig.options.brand === 'carnival'} childId={childId} hideBackButton={true} />

            <PromotionalContent />
            <PromotionalContentV2 />
            <CustomContent brand={brand.baseConfig.options.brand} />


            <div className={clsx('start-button', brand.baseConfig.options?.buttonStyle)} type="file" accept="image/*" display-if={!isLoading && !showError} >
                <input type="file" accept="image/*" id="file-image" style={{ display: 'none' }} onChange={onSetFile} />
                <label htmlFor="file-image" className="start-button-transparent">
                    <span className=""><Text id='common.landingpagebutton' /></span>
                </label>
            </div>
            <p
                className="no-photos-found"
                style={{ textTransform: brand.baseConfig.options.textTransform }}
                display-if={showError}
            >
                <Text id='common.error' />
            </p>

            <PBFooter dark={true && (brand.baseConfig.options.brand !== 'retreat' && brand.baseConfig.options.brand !== 'partner' && brand.baseConfig.options.brand !== 'rydercup')} />
        </div>
    )
});

export default HomePreview;

