import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import clsx from 'clsx';
import { OsTypes, isAndroid, isIOS } from 'react-device-detect'
// import { CanvasCapture } from 'canvas-capture';

import Text from '../text';
import LogoHeader from '../logoHeader';
import PBFooter from '../pBFooter';
import Loading from '../loading';

import template from './template.json';
import './finalVideo.scss'

let mediaRecorder;
let recordedChunks;

const iosOptions = {
  mimeType: 'video/webm;codecs=vp9',
  videoBitsPerSecond: 1000000
}

const androidOptions = {
  mimeType: 'video/webm;codecs=vp9',
  ignoreMutedMedia: true
}

let frame = 1
const k = 0.01
const k2 = 0.001

const {innerWidth, innerHeight} = window;

const FinalVideo = observer(({ startRecording }) => {
  const videoRef = useRef(null);
  const video2Ref = useRef(null);
  const imgRef = useRef(null)
  const canvasRef = useRef(null);
  const canvas2Ref = useRef(null);
  const canvas3Ref = useRef(null);
  const store = useStore();
  const history = useHistory();
  const [photoFile, setPhotoFile] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [recording, setRecording] = useState(false)
  const [finalRecording, setFinalRecording] = useState(false)
  const [bFrame, setBFrame] = useState(1)

  const { onsitePhotos } = store;
  const { photoIndex } = useParams();
  
  let frame = 1

  const { scale, translate, rotation } = template.animations.animation.bones[`UGC 1`];
  const { width, height } = template.comps[0]
  
  const width2 = innerWidth * 0.45;
  const ratio = width2 / width
  const height2 = innerHeight * 0.80;
  const ratio2 = height2 / height;

  // useEffect(() => {
  //   CanvasCapture.init(canvasRef.current, {})
  // }, [])

  const screen = { Screen: 'FinalVideo' }

  useEffect(() => {

    if (startRecording) setTimeout(() => record(), 500);
  }, [startRecording])

  const generateFile = async (photo) => {
    const fetched = await fetch(photo);
    const blob = await fetched.blob();
    const file = new File([blob], "foto.jpg", { type: "image/jpeg" })
    setPhotoFile(file);
    store.setUserPhotoFile(file);
  }

  const handleShare = () => {
    store.mixpanel.track('TapSaveShare', {
      ...store.mixpanelBaseData,
      ...screen
    })
    navigator.canShare({ files: [store.userBrandedImageFile] }) && navigator.share({ files: [store.userBrandedImageFile] })
    generateNewBrandedImageFile();
  }

  // useEffect(() => {
  //   drawUGCCanvas()
  // }, [])

  useEffect(() => {
    if (recording) {
      // recordBtn.textContent = "Stop";
      // paintToCanvas()
      let canvas = canvasRef.current
      const stream = canvas.captureStream(15);
      mediaRecorder = new MediaRecorder(stream, {
        mimeType: isIOS ? 'video/mp4;codecs=avc1' : 'video/webm;codecs=vp9',
        videoBitsPerSecond: 1000000
      });
      recordedChunks = [];
      mediaRecorder.ondataavailable = e => {
        if (e.data.size > 0) {
          recordedChunks.push(e.data);
        }
      };
      mediaRecorder.start(1000);

    }
  }, [recording])

  useEffect(() => {
    if (finalRecording) {
      setFinalRecording(false)
      if (mediaRecorder && mediaRecorder.state !== 'inactive') mediaRecorder.stop();
      setTimeout(() => {
        const blob = new Blob(recordedChunks, {
          type: isIOS ? "video/mp4" : "video/webm"
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = isIOS ? "recording.mp4" : "recording.webm";
        a.click();
        URL.revokeObjectURL(url);
        frame = 1
      }, 0);
    }
  }, [finalRecording])

  const record = () => {
    let video = videoRef.current;
    let image = imgRef.current;
    let foreground = video2Ref.current;
    video.play()
    foreground.play()
    setRecording(true)
    paintToCanvas()
    setTimeout(() => {
      setRecording(false)
      setFinalRecording(true)
    }, 15000)
  };

  const paintToCanvas = () => {
    try {
      frame++
      setBFrame(frame)
      let video = videoRef.current;
      let image = imgRef.current;
      let foreground = video2Ref.current;
      let canvas = canvasRef.current;
      let canvas2 = canvas2Ref.current;
      let canvas3 = canvas3Ref.current;
      let ctx = canvas.getContext('2d');
      let ctx2 = canvas2.getContext('2d');
      // let ctx3 = canvas3.getContext('2d');

      const x = (translate[frame].x - width * scale[frame].x * 0.85) + 171 * scale[frame].x;
      const y = ((translate[frame].y - height * scale[frame].y * 0.955)) + 93 * scale[frame].y;
      const widthUGC = width * scale[frame - 1].x
      const heightUGC = height * scale[frame - 1].x

      canvas.width = width;
      canvas.height = height;
      canvas2.width = width2;
      canvas2.height = height2;
      canvas3.width = width;
      canvas3.height = height;

      ctx.drawImage(video, 0, 0, width, height);
      // ctx2.drawImage(image, 0, 0, width, height);
      ctx.save()
      ctx.translate(x + widthUGC / 2, + y + heightUGC / 2);
      ctx.rotate(rotation[frame].z * Math.PI / 180)
      ctx.translate(- x - widthUGC / 2, - y - heightUGC / 2);
      ctx.drawImage(image, x, y, widthUGC, heightUGC);
      ctx.restore()

      ctx2.save()
      ctx2.translate(x * ratio + widthUGC * ratio / 2, y * ratio + heightUGC * ratio / 2);
      ctx2.rotate(rotation[frame].z * Math.PI / 180)
      ctx2.translate(- x * ratio - widthUGC * ratio / 2, - y * ratio - heightUGC * ratio / 2);
      ctx2.drawImage(image, x * ratio, y * ratio, widthUGC * ratio, heightUGC * ratio);
      ctx2.restore()
      // ctx2.drawImage(image, x * ratio, y * ratio, widthUGC * ratio, heightUGC * ratio);
      // ctx3.drawImage(foreground, 0, 0, width, height);

      ctx.drawImage(foreground, 0, 0, width, height);
      if (frame < scale.length) setTimeout(() => paintToCanvas(), 1000 / 15)

    } catch (e) {
      console.log(e)
    }
  }

  const drawUGCCanvas = () => {
    let canvas2 = canvas2Ref.current;
    let image = imgRef.current;
    let ctx2 = canvas2.getContext('2d');
    const x = (translate[frame].x - width * scale[frame].x * 0.85) + 171 * scale[frame].x;
    const y = ((translate[frame].y - height * scale[frame].y * 0.955)) + 93 * scale[frame].y;
    const widthUGC = width * scale[frame - 1].x
    const heightUGC = height * scale[frame - 1].x
    ctx2.drawImage(image, x * ratio, y * ratio, widthUGC * ratio, heightUGC * ratio);
  }

  const ugcStyles = useMemo(() => {
    return {
      position: 'absolute',
      top: ((translate[frame].y * ratio - height * scale[1].y * 0.85) + 93 * scale[1].y) * ratio,
      left: ((translate[frame].x * ratio - width2 * scale[1].x * 0.955) + 171 * ratio * scale[0].x) * ratio,
      transform: `scale(${scale[frame].x})`
    }
  }, [])

  return (
    <div className='final-video-container'>
      <div className={clsx('final-video-video-container')} >
        <video
          ref={videoRef}
          src='assets/brands/redbull/video/bg.mp4'
          className="final-video-video"
          playsInline
          // autoPlay
          muted
        // loop
        />
        <video
          ref={video2Ref}
          src={isIOS ? 'assets/brands/redbull/video/fg-IOS.mov' : 'assets/brands/redbull/video/fg.webm'}
          className="final-video-video"
          playsInline
          // autoPlay
          muted
          // loop
          // style={{ zIndex: 200 }}
        />
        <div className='final-video-ugc-container'>
          <img
            ref={imgRef}
            src={store.photo16By9}
            className={clsx('final-video-ugc')}
            // style={ugcStyles}
            alt=''
            style={{ zIndex: -1 }}
            onLoad={drawUGCCanvas}
          />
          <canvas ref={canvas2Ref} width={0} height={0} className='canvas' style={{ zIndex: 199, display: 'block' }}></canvas>
        </div>
      </div>
      <canvas ref={canvasRef} width={0} height={0} className='canvas'></canvas>
      <canvas ref={canvas3Ref} width={0} height={0} className='canvas'></canvas>
    </div>
  )
})

export default FinalVideo;