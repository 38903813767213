import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import clsx from 'clsx';

import './fenwaybowlPromotionalContent.scss'
import FantasticPhotos from './fantasticPhotos';

const PromotionalContent = observer(() => {
    const store = useStore();
    const { brand } = store;

    return (
        <div className="fenwaybowl__promotional-content-container">
            <div className="fenwaybowl__promotional-group">
                <p className="fenwaybowl__promotional-text">
                    <span className="fenwaybowl__new-england-title-bold">FENWAY BOWL 2021</span>
                </p>
                <p className="fenwaybowl__training-camp">
                    FENWAY PARK | BOSTON, MA
                </p>
            </div>
            <div className="fenwaybowl__line" />
            <img src="assets/brands/fenwaybowl/football-helmets.svg" className="fenwaybowl__camp-logo" alt="" />
            <div className="fenwaybowl__photo-container">
                <img src={brand.baseConfig.images.promoStrip} className="fenwaybowl__photo" alt='' />
            </div>
            <FantasticPhotos color={brand.baseConfig.colors.fantasticPhotosColor} />
        </div>
    )
});

export default PromotionalContent;