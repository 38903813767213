import React, { useState, useEffect, useRef, useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { isAndroid, isFirefox, isMobile } from 'react-device-detect';

import Loading from 'components/loading';
import LogoHeader from 'components/logoHeader';
import PBFooter from 'components/pBFooter';
import Text from 'components/text';

import { useStore } from 'stores/store';
import { registerUser, uploadSelfieSearch, getCutOutPhoto } from 'services';
import { makeBrandedUserImage, resizePhoto, make16By9Photo } from 'utils';

import './takePhoto.scss';

const WEBCAM_WIDTH = 1080;
const WEBCAM_HEIGHT = window.innerHeight / window.innerWidth * 1080 * 0.80;

const W_WIDTH = document.documentElement.clientWidth;
const W_HEIGHT = document.documentElement.clientHeight;

// const focusHeight = (W_HEIGHT - W_WIDTH / 100 * 15 - W_WIDTH / 100 * 15) / 100 * 30;
const focusHeight = '15vw';
const videoHeight = W_HEIGHT - W_WIDTH / 100 * 15 - W_WIDTH / 100 * 15;

const SCREEN = 'TakePhoto';

const IS_VERTICAL = window.innerWidth < window.innerHeight;

const FocusRectangle = observer(({ isLoading }) => (
	<div display-if={!isLoading}>
		<div
			className="take-photo-overlay-box take-photo-left-top"
		// style={{ height: focusHeight }}
		/>
		<div
			className="take-photo-overlay-box take-photo-right-top"
		// style={{ height: focusHeight }}
		/>
		<div
			className="take-photo-overlay-box take-photo-left-bottom"
		// style={{ height: focusHeight }}
		/>
		<div
			className="take-photo-overlay-box take-photo-right-bottom"
		// style={{ height: focusHeight }}
		/>
	</div>
))

const FocusOpacity = observer(({ takingPicture, isLoading }) => (
	<div display-if={takingPicture && !isLoading}>
		<div
			className='rectangle rectangle-1'
		/>
		<div
			className='rectangle rectangle-2'
			style={{ height: (W_HEIGHT - W_WIDTH / 100 * 23 - W_WIDTH / 100 * 23) }}
		/>
		<div
			className='rectangle rectangle-3'
		/>
		<div
			className='rectangle rectangle-4'
			style={{ height: (W_HEIGHT - W_WIDTH / 100 * 23 - W_WIDTH / 100 * 23) }}
		/>
	</div>
))

const Countdown = observer(({ countdown, takingPicture, isLoading }) => (
	<div
		className='take-photo-countdown-container'
		display-if={!isLoading}
		style={{ height: (W_HEIGHT - W_WIDTH / 100 * 15 - W_WIDTH / 100 * 15) }}
	>
		<p
			className="countdown"
			display-if={takingPicture && countdown !== 0 && countdown !== 4}
		>
			{countdown}
		</p>
	</div>
))

const TakePhoto = observer(() => {
	const history = useHistory();
	const store = useStore();
	const { isRegister } = useParams();

	const [webcamBlobURL, setWebcamBlobURL] = useState("");
	const [takingPicture, setTakingPicture] = useState(false);
	const [pictureTaken, setPictureTaken] = useState(false);
	const [webcamLoading, setWebcamLoading] = useState(false);
	const [countdown, setCountdown] = useState(4);
	const [photoTaked, setPhotoTaked] = useState(null);
	const [webcamWidth, setWebcamWidth] = useState(0);
	const [webcamHeight, setWebcamHeight] = useState(0);

	const [redirectAddress, setRedirectAddress] = useState(null);


	const [isLoading, setIsLoading] = useState(false);
	const [loading, setloading] = useState(false);

	const [error, setError] = useState(null);

	const webcamRef = useRef(null);
	const photoRef = useRef(null);
	const redirect = useRef(null);

	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

	const screen = { Screen: 'TakePhoto' };

	useEffect(() => {
		forceUpdate();
	},[isLoading])
	
    useEffect(() => {
        const redirectHome = () => {
            history.push('/')
        }

        if (store && !store.nonBoothSessionId) redirectHome();
    },[store])

	useEffect(() => {
		store.mixpanel.track('LandingTakeSelfie', { 
			...store.mixpanelBaseData,
			...screen
		 })
	},[])

	useEffect(() => {
		enableWebcamLiveFeed();

		return () => {
			// setWebcamLoading(true);
			disableWebcamLiveFeed()
		}
	}, [])

	useEffect(() => {

		if (countdown === 0) takeSnapshot(0);
	}, [countdown])

	useEffect(() => {
		const handleRedirect = () => {
			let address = redirect.current;
			address.click();
			setRedirectAddress(null)
		}

		if (redirectAddress) handleRedirect();
	}, [redirectAddress])

	const getWidthAndHeight = ({ width, height }) => {
		const ratio = width / height;
		if (ratio > 1) {
			return { resizedWidth: 1000, resizedHeight: 1000 / ratio }
		} else {
			return { resizedWidth: 1000 * ratio, resizedHeight: 1000 }
		}
	}

	const enableWebcamLiveFeed = () => {
		// navigator.mediaDevices.getUserMedia({ video: { width: WEBCAM_WIDTH, height: WEBCAM_HEIGHT }, facingMode: 'user' }).then(stream => {
		navigator.mediaDevices.getUserMedia({
			video: { facingMode: 'user' },
			audio: false
		})
			.then(stream => {
				let {width, height} = stream.getTracks()[0].getSettings();
				console.log()

				let video = webcamRef.current;
				const { resizedWidth, resizedHeight } = getWidthAndHeight({ width, height });
				setWebcamWidth(((!store.forceHorizontal && IS_VERTICAL) && !isAndroid && !isFirefox) || (isFirefox && isMobile) ? resizedHeight : resizedWidth)
				setWebcamHeight(((!store.forceHorizontal && IS_VERTICAL) && !isAndroid && !isFirefox) || (isFirefox && isMobile) ? resizedWidth : resizedHeight)

				video.srcObject = stream;
				video.play();

				const disableWebcamLoader = setInterval(() => {
					if (video.currentTime > 0) {
						setWebcamLoading(false);
						clearInterval(disableWebcamLoader)
					}
				}, 200);
			})
			.catch(err => {
				console.error("error:", err);
				store.mixpanel.track('NoCameraAccess', { 
					...store.mixpanelBaseData,
					...screen, 
					Error: err 
				})
			});
	}

	const disableWebcamLiveFeed = () => {
		navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then(stream => {
			stream.getTracks().forEach((track) => {
				track.stop();
			});
		});
	}

	const onTakePicture = () => {
		// store.mixpanelTrack('TapFakeShutterButton', { ...screen })
		setCountdown(3)
		setTimeout(() => setCountdown(2), 1000)
		setTimeout(() => setCountdown(1), 2000)
		setTimeout(() => setCountdown(0), 3000)
		setTakingPicture(true)
	}

	const takeSnapshot = async () => {
		paintToCanvas();
		let photo = photoRef.current;
		const data = photo.toDataURL('image/jpeg', 1.0)
		setPhotoTaked(data)

		store.mixpanel.track('SelfieTaken', { 
			...store.mixpanelBaseData,
			...screen,
		})
		setCountdown(4);

		setWebcamBlobURL(data);
		setPictureTaken(!pictureTaken);

		if (isRegister) {
			const selfie = await fetch(data)
			const blob = await selfie.blob()
			console.log('blob: ', blob)
			store.setSelfieBlob(blob)
			const url = URL.createObjectURL(blob)
			const onsitePhoto = { url	}
			const photos = [ onsitePhoto ]
			store.setOnsitePhotos(photos)
			history.push('/user-photo/0')
			// const res = await registerUser(data, store.config, store.bookingId)
			// console.log('res: ', res)
			// if (res.status === 200) {
			// 	window.alert(`register face id: ${res.data.id}`)
			// } else {
			// 	window.alert(`register face failed msg: ${res.data.exception}`)
			// }
		} else if (store.brand.baseConfig?.options?.videoMode) {
			await getUGC(data)
		} else {
			await submitPhoto(data);
		}
		setTakingPicture(false);

	}

	const getUGC = async (data) => {
		setIsLoading(true);
		try {
			const selfie = await fetch(data)
			const blob = await selfie.blob()
      const photo = await getCutOutPhoto({ photo: blob, config: store.config });
			const resized = await resizePhoto(photo, store.brand.baseConfig.options.height)
			const photo16by9 = await make16By9Photo(resized, 1080, 1920)
			store.setPhoto16By9(photo16by9)
			setIsLoading(false);
			history.push('/final-video')
    } catch (e) {
      console.log(e)
			setIsLoading(false);
    }
	}

	// const paintToCanvas = () => {
	// 	let video = webcamRef.current;
	// 	let photo = photoRef.current;
	// 	let ctx = photo.getContext('2d');

	// 	const width = W_WIDTH * 0.9;
	// 	const height = W_HEIGHT - W_WIDTH / 100 * 15 - W_WIDTH / 100 * 15;
	// 	photo.width = width;
	// 	photo.height = height;

	// 	ctx.drawImage(video, 0, 0, width, height);
	// }

	const paintToCanvas = () => {
		let video = webcamRef.current;
		let photo = photoRef.current;
		let ctx = photo.getContext('2d');

		console.log('webcamWidth: ', webcamWidth)
		console.log('webcamHeight: ', webcamHeight)

		photo.width = webcamWidth;
		photo.height = webcamHeight;

		ctx.drawImage(video, 0, 0, webcamWidth, webcamHeight);
	}

	const submitPhoto = async (data) => {
		setIsLoading(true);
		// store.mixpanelTrack('LoadingModalOpen', { ...screen })
		const blob = data;
		store.setUserPhoto(blob);
		let base64;
		let results
		let res

		try {
			res = await uploadSelfieSearch({ photo: blob, config: store.config, overrideSessionId: store.wallSessionId })
			store.mixpanel.track('SelfieUploadedToSearch', {
				...store.mixpanelBaseData,
				...screen,
			})
			// const res = await uploadSelfieSearchOld(blob, store.config))
			if (res.status === 200 && res.data?.assets?.length > 0) {
				let assets = [...res.data.assets];
				store.mixpanel.track('FindResults', {
					...store.mixpanelBaseData,
					...screen,
					'AssetCount': assets.length,
				});
				assets.sort(function (a, b) {
					if (a.score < b.score) return -1;
					if (a.score > b.score) return 1;
					return 0;
				})
				if (assets[0].confidence !== 'HIGH') {
					history.push('/no-photo-found');
					return;
				}
				let onsitePhotosArray = [...assets];

				store.setOnsitePhotos(onsitePhotosArray);

				// history.push('/finalphoto?facialRecognition=true');
				if (assets.length === 1) handleBrandImage(onsitePhotosArray[0].url)
				else {
					setIsLoading(false)
					history.push('/select-favorite-photo');
				}
			} else if (res.status === 400) {
				setIsLoading(false)
				store.setError(res.data);
				// history.push(`./error`);
				history.push('/no-matches')
			} else {
				setIsLoading(false)
				store.mixpanel.track('NoSelfieResults', {
					...store.mixpanelBaseData,
					...screen,
				});
				history.push('/no-matches')
			}
			// await store.setOnsitePhotos(res);
		} catch (e) {
			console.log(e)
			setIsLoading(false)
			// console.log('error_code: ', res.error_code)
			store.setError(res);
			store.mixpanel.track('ErrorSearchingResults', { 
				...store.mixpanelBaseData,
				...screen, 
				Error: e,
				ErrorCode: res?.error_code,
			})
			history.push(`./error`);
			// store.mixpanelTrack('LoadingModalClose', { ...screen })
		}

		// history.push('/finalphoto?facialRecognition=true');
	}

	const handleClickTakeSnapshot = () => {
		store.mixpanel.track('tapTakeSelfie', {
			...store.mixpanelBaseData,
			...screen,
		})
		takeSnapshot(0)
	}

	const handleBrandImage = async photo => {
        try {
            const brandedImage = await makeBrandedUserImage(photo, store.brand.advanceConfig.images.brandedV)
            store.setUserBrandedImage(brandedImage);
            const fetched = await fetch(brandedImage);
            const blob = await fetched.blob();
            const file = new File([blob], "PGA_2022_Souvenir.jpg", { type: "image/jpeg" })
            store.setUserBrandedImageFile(file);
            setIsLoading(false)
            history.push('/photo-detail/0')
        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }



	return (
		<div 
			className="take-photo-container"
      style={{ backgroundImage: `url(${store.brand.baseConfig.images.backgroundImage})` }}
		>
			<LogoHeader dark={false} alternateLogo={store.brand.brand === 'carnival'} hideBackButton={false} />
			{/* <Spinner display-if={isLoading} /> */}
			<Loading
				text={'PROCESSING...'}
        color={store.brand.baseConfig.colors.loadingBarColor}
        backColor={store.brand.baseConfig.colors.loadingBarColorLight}
				fontColor={store.brand.baseConfig.colors.loadingBarColor}
				display-if={isLoading}
			/>
			<div className={clsx('take-photo-title', !!isLoading && 'display-none')}  >
				<span><Text id='common.takeSelfie' markup/></span>
				<div className='separator-line' />
			</div>
			<div
				className={clsx('take-photo-content-container', isLoading && 'display-none')}
				display-if={!isLoading}
			>
				<video
					className={clsx('video-player showFeed')}
					ref={webcamRef}
					playsinline=""
					style={{ height: videoHeight }}
					// display-if={!isLoading}
				/>
				{/* <img
					src={photoTaked}
					className={clsx('video-player showFeed, photo')}
					style={{ height: videoHeight }}
					display-if={photoTaked}
					display-if={!isLoading}
				/> */}
				<div
					className='take-photo-flash'
					display-if={countdown === 0}
				/>

				<FocusOpacity takingPicture={takingPicture} isLoading={isLoading} />

				<canvas className="hide-feed" ref={photoRef} />

				<Countdown countdown={countdown} takingPicture={takingPicture} isLoading={isLoading} />

				{/* <p
					className="take-photo-title"
					display-if={!isLoading}
				>
					Take a Selfie
				</p> */}

				<FocusRectangle isLoading={isLoading} />

				<button
					className="take-photo-button"
					onClick={onTakePicture}
					display-if={!takingPicture && !isLoading}
				>
					TAKE MY PHOTO
				</button>

				<div
					className='take-photo-circle-button-container'
					display-if={!takingPicture && !isLoading}
				>
					<button
						className='take-photo-circle-button'
						onClick={handleClickTakeSnapshot}
					/>
				</div>
				<p className="take-photo-subtitle">This application uses facial recognition to find your photos</p>
			</div>
			<PBFooter dark={false} />
			<a href={redirectAddress} ref={redirect} />
		</div>
	)
})

export default TakePhoto;