import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

import HtmlForPDF from '../containers/htmlForPDF';

const DesktopRoutes = () => {
    return (
        <Switch>
            <>
                <Route path="/html-for-pdf" exact component={HtmlForPDF} />
            </>
        </Switch>
    )
}

export default DesktopRoutes;