import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { useHistory, useParams } from 'react-router-dom';


import './htmlForPDF.scss'

const Marks = () => (
    <div>
        <div className='mark vertical top left' />
        <div className='mark horizontal top left' />
        <div className='mark vertical top right' />
        <div className='mark horizontal top right' />
        <div className='mark vertical bottom left' />
        <div className='mark horizontal bottom left' />
        <div className='mark vertical bottom right' />
        <div className='mark horizontal bottom right' />
    </div>
)


let urlSearchParams = new URLSearchParams(window.location.search);
const ticketUGCImage = urlSearchParams.get('image')

const HtmlForPDF = () => {
    const store = useStore();
    const { ticket } = store.brand.advanceConfig.options;
    // const ticket = {
    //     size: {
    //         width: 900,
    //         height: 2250,
    //     },
    //     coordinates: {
    //         x: 0,
    //         y: 450
    //     }
    // }
    const pdfContainer = {
        width: ticket.size.width + 72,
        height: ticket.size.height + 72,
    }
    const bleedContainer = {
        width: ticket.size.width + 24,
        height: ticket.size.height + 24,
    }

    return (
        <div
            className='html-for-pdf-container'
            style={{ width: pdfContainer.width }}
        >
            <div
                className='html-for-pdf-bleed-container'
                style={{ width: bleedContainer.width, height: bleedContainer.height }}
            >
                <Marks />
                <img
                    src={store.ticketUGCImage}
                    // src={ticketUGCImage}
                    className='ticket-img'
                    style={{
                        width: bleedContainer.width,
                        top: store.brand.advanceConfig.options.ticket.coordinates.y,
                        left: store.brand.advanceConfig.options.ticket.coordinates.x
                        // top: 450,
                        // left: 0,
                    }}
                    alt=''
                />
                <img
                    src={store.brand.advanceConfig.images.ticket1}
                    // src='assets/brands/pga/ticket_Page_1.png'
                    className='ticket-img'
                    style={{ width: bleedContainer.width, height: bleedContainer.height }}
                    alt=''
                />

                <div
                    className='html-for-pdf-crop-container'
                    style={{ width: ticket.size.width, height: ticket.size.height }}
                >
                    <Marks />

                </div>
            </div>
            <div
                className='html-for-pdf-bleed-container'
                style={{ width: bleedContainer.width, height: bleedContainer.height }}
            >
                <Marks />
                <img
                    src={store.brand.advanceConfig.images.ticket2}
                    // src='assets/brands/pga/ticket_Page_2.png'
                    className='ticket-img'
                    style={{ width: bleedContainer.width, height: bleedContainer.height }}
                    alt=''
                />
                <div
                    className='html-for-pdf-crop-container'
                    style={{ width: ticket.size.width, height: ticket.size.height }}
                >
                    <Marks />
                </div>
            </div>
        </div>
    )
}

export default HtmlForPDF;