import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

import IframeDesktop from '../containers/iframeDesktop/iframeDesktop';
import Terms from '../containers/terms';
import SouvenirTicket from '../containers/souvenirTicket';
import ShippingInformation from '../containers/shippingInformation';
import Congratulations from '../containers/congratulations';
import HtmlForPDF from '../containers/htmlForPDF';
import ErrorScreen from '../containers/error';

const RoutesTS = ({ ticketSouvenir }) => {
    return (
        ticketSouvenir &&
            <>
                <Route path="/iframe-desktop" exact component={IframeDesktop} />
                <Route path="/terms" exact component={Terms} />
                <Route path="/souvenir-ticket/:photoIndex" exact component={SouvenirTicket} />
                <Route path="/shipping-information" exact component={ShippingInformation} />
                <Route path="/congratulations" exact component={Congratulations} />
                <Route path="/html-for-pdf" exact component={HtmlForPDF} />
                <Route path="/error" exact component={ErrorScreen} />

            </>
    )
}

export default RoutesTS;