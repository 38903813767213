import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Jimp from 'jimp';
import EXIF from "exif-js";
import clsx from 'clsx';
import { saveAs } from 'file-saver';
// import { isAndroid } from 'react-device-detect';

import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { makePhotoStrip, resizePhoto } from '../../utils';

import LogoHeader from '../../components/logoHeader';
import PhotoStrip from './photoStrip';
import PBFooter from '../../components/pBFooter';
import { ReactComponent as AddPhoto } from './add-photo.svg';
import { ReactComponent as Upload } from './upload.svg';
import { makeMergedPhoto } from '../../utils';
import './selectPhoto.scss'
import { getCutOutPhoto } from '../../services';
import Loading from '../../components/loading';
import { animateScroll as scroll } from 'react-scroll';
import { useLongPress } from 'use-long-press';

const SelectPhoto = observer(() => {
    const store = useStore();
    let { scrollTo } = useParams();

    const { config, mergeData, backgrounds, frames, captions, photos, mergedPhotos, baseMergeData, session, mixpanel, nonBoothSessionId, watermarkRemoved, removeWatermark, brand } = store;
    const history = useHistory();
    const callback = useCallback(event => {
        trackMixpanelHardPress();
    }, []);


    const bind = useLongPress(callback, {
        // onStart: event => console.log('Press started'),
        // onFinish: event => console.log('Long press finished'),
        // onCancel: event => console.log('Press cancelled'),
        threshold: 500,
        captureEvent: true,
        detect: 'both',
    });

    const [isLoading, setIsloading] = useState(false);
    const [isMerging, setIsMerging] = useState(false);

    useEffect(() => {
        const scrollToPhoto = () => {
            const width = window.innerWidth;
            if (scrollTo > 0) scroll.scrollTo(((0 + scrollTo * 80) * width) / 100, 0)
        }

        if (scrollTo) scrollToPhoto();
    }, [scrollTo])

    useEffect(() => {
        const updatePhotos = () => {
            store.setRemoveWatermark(false);
            makeMergedPhotos();
        }
        if (removeWatermark) updatePhotos();
    }, [removeWatermark])

    const trackMixpanel = (frIndex, bgId) => {
        let sessionSplit;
        let sessionId = null;
        if (session) {
            sessionSplit = session.split('/');
            sessionId = sessionSplit[1];
        }
        mixpanel.track('MergeImage', {
            'Foreground': frames[frIndex].name,
            'Background': backgrounds[bgId].name,
            'BoothSessionID': sessionId,
            'NonBoothSessionID': nonBoothSessionId,
            'Brand': brand.baseConfig.options.brand,
            ...store.mixpanelBaseData,
        })
    }

    const trackMixpanelHardPress = () => {
        let sessionSplit;
        let sessionId = null;
        if (session) {
            sessionSplit = session.split('/');
            sessionId = sessionSplit[1];
        }
        mixpanel.track('HardPressImage', {
            'BoothSessionID': sessionId,
            'NonBoothSessionID': nonBoothSessionId,
            'Brand': brand.baseConfig.options.brand,
        })
    }

    const trackMixpanelDownloadAll = () => {
        let sessionSplit;
        let sessionId = null;
        if (session) {
            sessionSplit = session.split('/');
            sessionId = sessionSplit[1];
        }
        mixpanel.track('DowloadPhotoStrip', {
            'BoothSessionID': sessionId,
            'NonBoothSessionID': nonBoothSessionId,
            'Brand': brand.baseConfig.options.brand,
        })
    }

    const onSetFile = async ({ target: { files: [file] } }) => {
        // scroll.scrollToTop();
        setIsloading(true);
        EXIF.getData(file);
        const orientation = await new Promise(resolve => {
            EXIF.getData(file, () => {
                resolve(EXIF.getTag("Orientation"));
            })
        })
        const orientedPhoto = await checkOrientation(file);


        const photo = await getCutOutPhoto({ photo: orientedPhoto, config });

        try {
            let newMergedPhotos = [...mergedPhotos]
            let resizedArray = [...photos]
            let newMergeData = [...mergeData];
            let insertAt = 0;
            mergedPhotos.forEach((item, index) => { if (item?.photo !== null) insertAt = index + 1 });
            const resized = await resizePhoto(photo, brand.baseConfig.options.resolution);
            resizedArray.push(resized);
            store.setBoothPhotos(resizedArray);
            const mergedPhoto = await makeMergedPhoto(store, resized, { ...baseMergeData }, backgrounds, frames, captions, brand.baseConfig.options.resolution, true);
            const mergedPhotoObj = {
                photo: mergedPhoto.finalMerge,
                mergeData: { ...mergedPhoto.finalMergeData }
            }
            trackMixpanel(baseMergeData.frame, baseMergeData.background)
            newMergeData.push({ ...baseMergeData });
            newMergeData[insertAt] = { ...mergedPhoto.finalMergeData };
            store.setMergeData(newMergeData);
            newMergedPhotos.push(mergedPhotoObj);
            store.setMergedPhotos(newMergedPhotos);
            // setTimeout(() => {
            history.push('/editphoto/' + insertAt);
            setIsloading(false)
            // }, 1000);
        } catch (e) {
            console.log(e)
            setIsloading(false)
        }
    }

    const checkOrientation = async (file) => {
        const exifdata = file['exifdata'];
        if (file.exifdata) {
            const { Orientation } = file.exifdata;
            switch (Orientation) {
                case 0:
                case 1:
                case 2:
                    return file;
                case 3:
                    return await rotated(file, 180);
                case 4:
                    return await rotated(file, 0);
                case 5:
                case 6:
                    return await rotated(file, 0);
                case 7:
                case 8:
                    return await rotated(file, 0);
                default:
                    return file;
            }
        } else {
            return file;
        }
    }

    const rotated = async (file, degrees) => {
        const url = URL.createObjectURL(file);
        const image = await Jimp.read(url);
        await image.resize(Jimp.AUTO, brand.baseConfig.options.resolution);
        await image.rotate(degrees);
        // if (!isAndroid) {
        //     console.log('is Android: ', isAndroid)
        //     console.log('flip image')
        // }
        await image.flip(true, true);
        const rotated = await image.getBase64Async(Jimp.MIME_JPEG)
        const fetched = await fetch(rotated);
        const blobToReturn = await fetched.blob();
        return blobToReturn;
    }

    const onSelectPhoto = index => {
        history.push('/editphoto/' + index)
    }

    const checkMergedPhotos = () => {
        if (!mergedPhotos) return false;
        let checkedPhotos = false;
        mergedPhotos.forEach(photo => {
            if (photo.photo !== null) checkedPhotos = true
        });
        return checkedPhotos;
    }

    const onDownloadStrip = async () => {
        trackMixpanelDownloadAll();
        const strip = await makePhotoStrip(mergedPhotos)
        saveAs(strip, 'PB-photo-strip.png');
    }

    const makeMergedPhotos = async () => {
        try {
            setIsMerging(true)
            let newMergedPhotos = [];
            for (const p in photos) {
                const mergedPhoto = await makeMergedPhoto(store, photos[p], mergeData[p], backgrounds, frames, captions, brand.baseConfig.options.resolution, false);
                const mergedPhotoObj = {
                    photo: mergedPhoto,
                    mergeData: { ...mergeData[p] }
                }
                newMergedPhotos.push(mergedPhotoObj)
            }
            store.setMergedPhotos(newMergedPhotos)
            setIsMerging(false);
        } catch (e) {
            console.log(e)
            setIsMerging(false);
        }
    }

    return (
        <div className="select-photo-container">

            <div className='sticky-header-container'>
                <div >
                    <LogoHeader
                        dark={true}
                        showActions={true}
                        fixed={true}
                        alternateLogo={brand.baseConfig.options.brand === 'retreat' || brand.baseConfig.options.brand === 'partner'}
                        showLetter={false}
                    />

                </div>
                <div className="sticky-header">
                    <div className="action-row" style={{ justifyContent: 'center' }} >
                        <div className="row" onClick={onDownloadStrip} display-if={false}>
                            <img src={'assets/svg/download.svg'} className='download' alt='' />
                            <span className="action-text">download all</span>
                        </div>
                        {/* <div className="row">
                        <img src={'assets/svg/letter.svg'} className='letter' alt='' />
                        <span className="action-text">email</span>
                    </div> */}
                    </div>
                </div>
            </div>

            <PhotoStrip photos={mergedPhotos} onSelectPhoto={onSelectPhoto} display-if={checkMergedPhotos()} {...bind} />

            <div className="upload-pick" type="file" accept="image/*" display-if={false}>
                <input type="file" accept="image/*" id="file-image" style={{ display: 'none' }} onChange={onSetFile} />
                <label htmlFor="file-image">
                    <div className="upload-cload-container">
                        <Upload className="upload-cload" display-if={!isLoading} />
                        <p className="add-photo-text" display-if={!isLoading}  >ADD PHOTO</p>
                        <Loading
                            display-if={isLoading}
                            color={brand.baseConfig.colors.loadingBarColorLight}
                            fontColor={brand.baseConfig.colors.primary} text={'loading...'}
                            uploadCloud={true}
                        />
                    </div>
                </label>
            </div>

            <div
                className="remove-watermark-button"
                display-if={store.brand.useWatermark && !store.watermarkRemoved}
                onClick={() => store.setShowEmailRegister(true)}
            >
                <p className="remove-watermark-text">REMOVE THE WATERMARK</p>
            </div>

            <PBFooter dark={true} />
        </div>
    )
});

export default SelectPhoto;