import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Scrollbars } from 'rc-scrollbars';
import { useStore } from '../../stores/store';
import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import Loading from '../../components/loading';
import SelectPhoto from './selectPhoto';
import clsx from 'clsx';

import './selectFavoritePhoto.scss';
import LoadingScreen from '../../components/loadingScreen';
import { makeBrandedUserImage } from '../../utils';


const SelectFavoritePhoto = observer(() => {
  const store = useStore();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const screen = { Screen: 'SelectFavoritePhoto' }

  useEffect(() => {
    const redirectHome = () => {
      history.push('/')
    }

    if (store && !store.nonBoothSessionId) redirectHome();
  }, [store])

  useEffect(() => {
    const trackMixpanel = () => {
      store.mixpanel.track('Landing', {
        ...store.mixpanelBaseData,
        ...screen
      });
    }

    if (store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
  }, [store])

  const handleSelectPhoto = async i => {
    // setIsLoading(true)
    store.mixpanel.track('tapSelectPhoto', {
      ...store.mixpanelBaseData,
      ...screen,
      'PhotoSelected': i
    })
    try {
      // const brandedImage = await makeBrandedUserImage(store.onsitePhotos[i].url, store.brand.advanceConfig.images.brandedV)
      // store.setUserBrandedImage(brandedImage);
      // const fetched = await fetch(brandedImage);
      // const blob = await fetched.blob();
      // const file = new File([blob], "Souvenir.jpg", { type: "image/jpeg" })
      // store.setUserBrandedImageFile(file);
      // setIsLoading(false)
      history.push(`/photo-detail/${i}`)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }


  return (
    <div className="select-favorite-photo-container">
      <Loading
        text={'PROCESSING...'}
        backColor={store.brand.baseConfig.colors.tertiary}
        fontColor={store.brand.baseConfig.colors.secondary}
        color={store.brand.baseConfig.colors.secondary}
        display-if={isLoading}
      />

      <LogoHeader dark={false} backRoute='/terms' />

      {/* TODO: translate */}
      <p className='select-favorite-photo-title'>Select a photo</p>

      <Scrollbars
        style={{ width: '92vw', height: '50vh', top: '0vw' }}
        className="select-favorite-photo-scroll-container"
      >
        <div className="select-favorite-photo-scroll-content-container">
          {
            store.onsitePhotos.map((photo, index) =>
              <div className={clsx('select-photo-array-photo-container', store.onsitePhotos.length === 2 && 'select-photo-array-photo-bigger-container')}>
                <img
                  alt=""
                  src={photo.thumb}
                  className={clsx('select-photo-array-photo', store.onsitePhotos.length === 2 && 'select-photo-array-photo-bigger')}
                  onClick={() => handleSelectPhoto(index)}
                />
              </div>
            )
          }
        </div>
      </Scrollbars>

      {/* TODO: translate */}
      <p
        className='select-favorite-view-all'
        onClick={() => window.open(store.config.StreamWebUrl, '_blank')}
        display-if={store.brand?.baseConfig?.options?.isVisibleViewAll}
      >
        {`View All >>`}
      </p>
      <PBFooter dark={false} />
    </div>
  )
});

export default SelectFavoritePhoto;