import React, { useEffect, useLayoutEffect } from 'react';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import history from './navigation/history';
import Routes from './navigation/routes'
import DesktopRoutes from './navigation/desktopRoutes';
import { observer } from 'mobx-react-lite'
import { useStore } from './stores/store';
import { v4 as uuidv4 } from 'uuid';

import { BrowserView, MobileView } from 'react-device-detect';
import AppDesktop from './containers/appDesktop';
import IframeDesktop from './containers/iframeDesktop';
import Text from './components/text';
import { getBrandConfigJson, getBrandBackgroundsJson, getBrandFramesJson, getBrandCaptionJson, getBrandMergeJson, getBransLangsJson, getBrandRootJson } from './services';
import { toJS } from 'mobx';
import { setRootVariables } from './utils';

import './App.scss';
import 'styles/global.scss'

const urlSearchParams = new URLSearchParams(window.location.search);
const iframe = urlSearchParams.get('iframe');

const App = observer(() => {
	// 
	const store = useStore();
	const { brand, config } = store;

	useEffect(() => {
		const createNewNonSessionId = () => {

			const newNonBoothSessionId = uuidv4();
			store.setNonBoothSessionId(newNonBoothSessionId);
		}

		if (!store.nonBoothSessionId) createNewNonSessionId();
	}, [])

	useEffect(() => {
		store.setVideoRes();
	})

	useEffect(() => {
		if (store.distance && store.unit) store.setLocalizedDistance();
	}, [store.distance, store.unit])

	useEffect(() => {
		const setMixpanelBaseData = () => {
			const data = {
				'SessionID': store.sessionId,
				'NonBoothSessionID': store.nonBoothSessionId,
				'ReferralURL': store.referralurl,
			}
			store.setMixpanelBaseData(data);
		}

		if (store && store.mixpanel && store.mixpanel.config?.token != null && store.nonBoothSessionId) setMixpanelBaseData();
	}, [store])

	useLayoutEffect(() => {
		const checkToRedirect = () => {
			if (!window.location.href.includes('#/sessionurl/')) {
				const splited = window.location.href.split('/sessionurl/');
				let redirectTo;
				redirectTo = '#/sessionurl/' + splited[1]
				history.push(redirectTo)
			}
		}

		const redirectToSharedHome = () => {
			history.push('#/shared-home')
		}

		if (window.location.href.includes('/sessionurl/')) checkToRedirect();
		if (window.location.href.includes('/shared-home') && !window.location.href.includes('#/shared-home')) redirectToSharedHome();
	}, [])

	useLayoutEffect(() => {
		const checkToRedirect = () => {
			const splited = window.location.href.split('?s=');
			const redirectTo = '#/home/' + splited[1]
			history.push(redirectTo)
		}

		if (window.location.href.includes('?s=')) checkToRedirect();
	})

	useEffect(() => {
		const reHydratateUser = () => {
			const rCCookies = localStorage.getItem("RCCookies");
			const rCData = localStorage.getItem("RCData");
			if (rCCookies) {
				const { acceptCookies } = JSON.parse(rCCookies);
				store.setAcceptCookies(acceptCookies);
				if (acceptCookies) {
					store.setShowAcceptCookiesModal(false);
				}
			}
			if (rCData) {
				const { user, watermarkRemoved } = JSON.parse(rCData)
				store.setUser(user);
				store.setWatermarkRemoved(watermarkRemoved);
			}
		}

		reHydratateUser();
	}, [])

	// useEffect(() => {
	// 	store.config && store.setWallSessionId(store.config);
	// }, [store.config])

	useEffect(() => {
		setRootVariables(store.cssVariables)
	}, [store.cssVariables])

	useEffect(() => {

		const init = async () => {
			const brandSelect = urlSearchParams.get('brand')?.replace('/', '')
			if (brandSelect) {
				let url = `${config.Proxy || ''}${config.PreviewUrl}`
				const brand = await getBrandConfigJson(brandSelect, url)
				if (brand) {
					brand.baseConfig.options.brand = store.brandId
					for (const [key, value] of Object.entries(brand?.baseConfig?.images)) {
						brand.baseConfig.images[key] = `${config.PreviewUrl}/${brandSelect}/${value}`
					}
					store.setBrand(brand)
				}
				const root = await getBrandRootJson(brandSelect, url)
				if (root) {
					setRootColors({
						primary: root['--color-primary']
						, secondary: root['--color-secondary']
						, tertiary: root['--color-tertiary']
						, quaternary: root['--color-quaternary']
						, fontFamilyPrimary: root['--font-family-primary']
						, fontFamilySecondary: root['--font-family-secondary']
					})
				}

				let langs = await getBransLangsJson(brandSelect, url)
				Object.entries(langs).forEach(
					([key, value]) => langs[key].common.adjusttooltip = langs[key].common.adjusttooltip.replace('<b>(.common.donebutton)</b>', `<strong>${langs[key].common.donebutton}</strong>`)
				);

				if (langs) {
					store.updateLangInfo('langs', langs)
				}
				const backgrounds = await getBrandBackgroundsJson(brandSelect, url)
				if (backgrounds) {
					for (const index in backgrounds) {
						backgrounds[index].thumbnail = `${config.PreviewUrl}/${brandSelect}/${backgrounds[index].thumbnail}`
						backgrounds[index].asset = `${config.PreviewUrl}/${brandSelect}/${backgrounds[index].asset}`
					}
					store.setBackgrounds(backgrounds)
				}


				const frames = await getBrandFramesJson(brandSelect, url)
				if (frames) {
					for (const index in frames) {
						frames[index].thumbnail = `${config.PreviewUrl}/${brandSelect}/${frames[index].thumbnail}`
						frames[index].asset = `${config.PreviewUrl}/${brandSelect}/${frames[index].asset}`
					}
					store.setFrames(frames)
				}

				/*                
				const caption = await getBrandCaptionJson(brandSelect, config.PreviewUrl)
				if (caption) {
					store.setCaptions(caption)
				}
		
				const mergeData = await getBrandMergeJson(brandSelect, config.PreviewUrl)
				if (mergeData) {
					store.setMergeData(mergeData)
				}
				*/
			}
		}
		init()
	}, [])

	useEffect(() => {
		const init = (event) => {
			try {
				if (!event.data.target && event.data) {
					const brandSelect = urlSearchParams.get('brand')?.replace('/', '')
					if (brandSelect) {
						const data = JSON.parse(event.data)
						const brand = data.brand
						const backgrounds = data.backgrounds
						const frames = data.frames
						let langs = data.langs
						const mergeDatas = data.mergeDatas
						const root = data.root
						const lang = data.lang

						if (brand) {
							brand.baseConfig.options.brand = store.brandId
							for (const [key, value] of Object.entries(brand?.baseConfig?.images)) {
								brand.baseConfig.images[key] = `${config.PreviewUrl}/${brandSelect}/${value}`
							}
							store.setBrand(brand)
							setRootColors({
								primary: brand.baseConfig.colors.primary
								, secondary: brand.baseConfig.colors.secondary
								, tertiary: brand.baseConfig.colors.tertiary
								, quaternary: brand.baseConfig.colors.quaternary
							})
						}

						if (root) {
							setRootColors({
								primary: root['--color-primary']
								, secondary: root['--color-secondary']
								, tertiary: root['--color-tertiary']
								, quaternary: root['--color-quaternary']
								, fontFamilyPrimary: root['--font-family-primary']
								, fontFamilySecondary: root['--font-family-secondary']
							})
						}

						Object.entries(langs).forEach(
							([key, value]) => langs[key].common.adjusttooltip = langs[key].common.adjusttooltip.replace('<b>(.common.donebutton)</b>', `<strong>${langs[key].common.donebutton}</strong>`)
						);
						if (langs) {
							store.updateLangInfo('langs', langs)
						}
						if (lang)
							store.changeLang(lang)
						if (backgrounds) {
							for (const index in backgrounds) {
								backgrounds[index].thumbnail = `${config.PreviewUrl}/${brandSelect}/${backgrounds[index].thumbnail}`
								backgrounds[index].asset = `${config.PreviewUrl}/${brandSelect}/${backgrounds[index].asset}`
							}
							store.setBackgrounds(backgrounds)
						}
						if (frames) {
							for (const index in frames) {
								frames[index].thumbnail = `${config.PreviewUrl}/${brandSelect}/${frames[index].thumbnail}`
								frames[index].asset = `${config.PreviewUrl}/${brandSelect}/${frames[index].asset}`
							}
							store.setFrames(frames)
						}
					}

				}
			}
			catch (e) {
				console.log(e)
			}
		}
		window.addEventListener("message", init);
		return () => {
			window.removeEventListener('message', init);
		};
	}, [])

	const setRootColors = ({ primary, secondary, tertiary, quaternary, fontFamilyPrimary, fontFamilySecondary }) => {
		let root = document.querySelector(':root');
		root.style.setProperty('--color-primary', primary);
		root.style.setProperty('--color-secondary', secondary);
		root.style.setProperty('--color-tertiary', tertiary);
		root.style.setProperty('--color-quaternary', quaternary);
		root.style.setProperty('--font-family-primary', fontFamilyPrimary);
		root.style.setProperty('--font-family-secondary', fontFamilySecondary);
	}

	return (
		<div>
			<MobileView>
				<div className="app-container">
					<HashRouter>
						<Routes
							brand={brand.baseConfig.options.brand}
							extendedFP={brand.baseConfig.options.extendedFP}
							childBrands={brand.advanceConfig.options.childBrands}
							sharedHome={brand.baseConfig.options.sharedHome}
							ticketSouvenir={brand.baseConfig.options.ticketSouvenir}
							faceSearch={brand.baseConfig.options.faceSearch}
							fantasticPhotos={brand.baseConfig.options.fantasticPhotos}
							oracleLab={brand.baseConfig.options.oracleLab}
							entryPoint={brand.baseConfig.options.entryPoint}
						/>
					</HashRouter>
				</div>
				<div className="app-rotated">
					<img src="assets/images/rotate-phone.png" className="phone-rotated" />
					<p className="rotate-text">
						<Text id='common.rotate' />
					</p>
				</div>
			</MobileView>
			<BrowserView>
				<div className="app-container desktop-container">
					{
						(iframe || (!iframe && (window.location.href.includes('/shared-desktop')))) ?
							<HashRouter>
								<Routes
									brand={brand.baseConfig.options.brand}
									extendedFP={brand.baseConfig.options.extendedFP}
									childBrands={brand.advanceConfig.options.childBrands}
									sharedHome={brand.baseConfig.options.sharedHome}
									ticketSouvenir={brand.baseConfig.options.ticketSouvenir}
									faceSearch={brand.baseConfig.options.faceSearch}
									fantasticPhotos={brand.baseConfig.options.fantasticPhotos}
									oracleLab={brand.baseConfig.options.oracleLab}
									entryPoint={brand.baseConfig.options.entryPoint}
								/>
							</HashRouter> :
							<>
								{
									window.location.href.includes('/html-for-pdf') ?
										<HashRouter>
											<DesktopRoutes />
										</HashRouter> :
										<IframeDesktop />
								}
							</>
					}
				</div>
			</BrowserView>
		</div>
	);
})

export default App;
