import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react-lite';
import { getToken, registerEmail, searchByRoomAndSurname, addCharge } from '../../../../services';
import { useStore } from '../../../../stores/store';
import { ReactComponent as Close } from './x-close.svg';
import clsx from 'clsx';

import GuestForm from '../guest-form/guest-form';
import ConfirmPurchase from '../confirm-purchase/confirm-purchase';
import PurchaseComplete from '../purchase-complete';
import './oracleHospitalityModal.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  }
};

export const OracleHospitalityModal = observer(({ isOpen, setIsOpen }) => {
  const store = useStore();

  const [token, setToken] = useState(null)
  const [payload, setPayload] = useState(null)
  const [guestData, setGuestData] = useState(null)

  useEffect(() => {
    const makePayload = () => {
      basePayload.criteria.reservationId.id = guestData.reservations?.reservationInfo[0]?.reservationIdList[0]?.id;
      setPayload(basePayload)
    }
    if (guestData) { 
      if (store.loyaltyMember || store.config.LoyaltyMembershipRoom === store.room) {
        handlePurchaseComplete()
      } else {
        makePayload() 
      }
    };
  }, [guestData])

  const handleConfirmPurchase = async () => {

    try {
      const reservationId = guestData.reservations?.reservationInfo[0]?.reservationIdList[0]?.id
      const res = await addCharge({ baseURL: store.config.API.OracleITBHotelURL, token, hotel: store.config.Hotel, reservationId, payload })
      if (res.status === 200) {
        handlePurchaseComplete();
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handlePurchaseComplete = () => {
    store.setPurchaseComplete(true);
    store.setWatermarkRemoved(true);
    store.setRemoveWatermark(true);
  }

  let basePayload = {
    "criteria": {
      "hotelId": store.config.Hotel,
      "charges": [
        {
          "transactionCode": "2100",
          "articleCode": "2101",
          "price": {
            "amount": 2,
            "currencyCode": "USD"
          },
          "postingQuantity": 1,
          "postingReference": "1 copy print",
          "postingRemark": "1 copy print",
          "checkNumber": "",
          "applyRoutingInstructions": false,
          "usePackageAllowance": false,
          "autoPosting": true,
          "folioWindowNo": 2,
          "cashierId": 777
        }
      ],
      "reservationId": {
        "id": "",
        "idExtension": 0
      },
      "incomeAuditDate": "2021-10-05",
      "postIt": false,
      "cashierId": 777,
      "welcomeOfferPosting": true
    }
  }

  useEffect(() => {
    const trackMixpanel = () => {
      store.mixpanel.track('View_Oracle_Hospitality_Integration', {
        'SessionID': store.nonBoothSessionId,
      });
    }

    if (isOpen && store && store.mixpanel && store.mixpanel.config?.token != null) trackMixpanel();
  }, [store, isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      class="email-modal"
      id="modal1"
      style={customStyles}
      className="modal-container"
      overlayClassName="oracle-modal-overlay"
      shouldCloseOnOverlayClick={true}
    >
      <div className="oracle-modal-content">
        <Close alt="" className="menu-close" onClick={() => setIsOpen(false)} />
        <GuestForm display-if={!payload && !store.purchaseComplete} setGuestData={setGuestData} setToken={setToken} />
        <ConfirmPurchase display-if={payload && !store.purchaseComplete} onConfirm={handleConfirmPurchase} />
        <PurchaseComplete display-if={store.purchaseComplete} setIsOpen={setIsOpen} />

      </div>

    </Modal>
  )
})

export default OracleHospitalityModal;