import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import EXIF from "exif-js";
import Jimp from 'jimp';
import clsx from 'clsx'

import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { make16By9Photo, makeMergedPhoto, makeTodayCaption, resizePhoto, checkVertical, makeFanCardPhotoV, makeFanCardPhotoH } from '../../utils';

import LogoHeader from '../../components/logoHeader';
import PromotionalContent from '../../components/promotionalContent';
import PromotionalContentV2 from '../../components/promotionalContentV2';
import PBFooter from '../../components/pBFooter';
import { v4 as uuidv4 } from 'uuid';
import { getCutOutPhoto } from '../../services';
import Loading from '../../components/loading';
import CustomContent from './customContent';
import Text from '../../components/text';

import './home.scss';

const Home = observer(() => {
  const store = useStore();

  const { config, backgrounds, frames, captions, session, nonBoothSessionId, mergeData, mergedPhotos, baseMergeData, mixpanel, brand } = store;
  const history = useHistory();
  const { referralurl, childId, showLoader } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [showError, setShowError] = useState(false);

  const screen = { Screen: 'FantasticPhotos' }

  useEffect(() => {
    store.setIframeViewer();
  }, [store])

  useEffect(() => {
    const createNewNonSessionId = () => {
      const newNonBoothSessionId = uuidv4();
      store.setNonBoothSessionId(newNonBoothSessionId);
    }

    if (!session) createNewNonSessionId();
  }, [store, session])

  useEffect(() => {
    const handleErrorShowing = () => {
      setTimeout(() => setShowError(false), 3000);
    }

    if (showError) handleErrorShowing();
  }, [showError])

  useEffect(() => {
    const newTodayCaption = async () => {
      const black = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'black')
      const white = await makeTodayCaption(store, { asset: brand.baseConfig.options.noFrame }, captions, 'white')
      store.setTodayCaption({
        black,
        white
      });
    }

    if (frames && captions && brand.baseConfig.options.useCaption) newTodayCaption();
  }, [store, frames, captions])

  useEffect(() => {
    const trackMixpanel = () => {
      mixpanel.track('LaunchWebExperience', {
        'BoothSessionID': session?.split('/')[1] || null,
        'NonBoothSessionID': nonBoothSessionId,
        'ReferralURL': referralurl,
        'Brand': brand.baseConfig.options.brand,
        ...store.mixpanelBaseData,
      });
    }

    if (store && mixpanel && mixpanel.config?.token != null) trackMixpanel();
  }, [referralurl, session, mixpanel, nonBoothSessionId, store])

  const onSetFile = async ({ target: { files: [file] } }) => {
    mixpanel.track('tapCreateSouvenir',{
      ...store.mixpanelBaseData,
      ...screen,
    })
    setIsloading(true)
    EXIF.getData(file);
    const orientation = await new Promise(resolve => {
      EXIF.getData(file, () => {
        resolve(EXIF.getTag("Orientation"));
      })
    })
    let photo;
    let orientedPhoto;
    let orientedPhotoFC;
    let urlOrientedPhoto;
    let urlOrientedPhotoFC;
    let urlNoOrientedPhoto;
    try {
      orientedPhoto = await checkOrientation(file);
      orientedPhotoFC = await checkOrientationFC(file);
      urlOrientedPhoto = URL.createObjectURL(orientedPhoto);
      urlOrientedPhotoFC = URL.createObjectURL(orientedPhotoFC);
      urlNoOrientedPhoto = URL.createObjectURL(file);
      photo = await getCutOutPhoto({ photo: orientedPhoto, config });
    } catch (e) {
      console.log(e)
      setShowError(true)
    }
    try {
      let newMergedPhotos = [...mergedPhotos]
      let resizedArray = []
      let originalResizedArray = []
      const resized = await resizePhoto(photo, brand.baseConfig.options.height)
      if (brand.baseConfig.options.videoMode) {
        const photo16by9 = await make16By9Photo(resized, 1080, 1920)
        store.setPhoto16By9(photo16by9)
      } 
      const originalResized = await resizePhoto(urlNoOrientedPhoto, brand.baseConfig.options.height)
      const isUGCVertical = await checkVertical(originalResized);
      store.setIsUGCVertical(isUGCVertical)
      if (brand.baseConfig.options.fanCard) {
        let photo16by9
        if (isUGCVertical) {
          photo16by9 = await makeFanCardPhotoV(urlOrientedPhotoFC)
        } else {
          photo16by9 = await makeFanCardPhotoH(urlOrientedPhotoFC)
        }
        store.setPhoto16By9(photo16by9)
      }
      resizedArray.push(resized);
      originalResizedArray.push(originalResized);
      store.setBoothPhotos(resizedArray);
      store.setResizedPhotos(originalResizedArray);
      history.push('/editphoto/0');
      return
      const photoMergeData = { ...baseMergeData };
      photoMergeData.position.centered = true;
      const mergedPhoto = await makeMergedPhoto(store, resized, photoMergeData, backgrounds, frames, captions, brand.baseConfig.options.resolution, true);
      const mergedPhotoObj = {
        photo: mergedPhoto.finalMerge,
        mergeData: { ...mergedPhoto.finalMergeData }
      }
      newMergedPhotos.unshift(mergedPhotoObj)
      store.setMergeData([baseMergeData, ...mergeData])
      store.setMergedPhotos(newMergedPhotos);
      if (childId) history.push(`/editphoto/0/${childId}`);
      else history.push('/editphoto/0');
    } catch (e) {
      console.log(e)
      setIsloading(false)
      setShowError(true)
    }
  }

  const checkOrientationFC = async (file) => {
    const exifdata = file['exifdata'];
    if (file.exifdata) {
      const { Orientation } = file.exifdata;
      switch (Orientation) {
        case 0:
        case 1:
        case 2:
          return file;
        case 3:
          return await rotated(file, 0);
        case 4:
          return await rotated(file, 0);
        case 5:
        case 6:
          return await rotated(file, 270);
        case 7:
        case 8:
          return await rotated(file, 90);
        default:
          return file;
      }
    } else {
      return file;
    }
  }

  const checkOrientation = async (file) => {
    const exifdata = file['exifdata'];
    if (file.exifdata) {
      const { Orientation } = file.exifdata;
      switch (Orientation) {
        case 0:
        case 1:
        case 2:
          return file;
        case 3:
          return await rotated(file, 180);
        case 4:
          return await rotated(file, 0);
        case 5:
        case 6:
          return await rotated(file, 0);
        case 7:
        case 8:
          return await rotated(file, 0);
        default:
          return file;
      }
    } else {
      return file;
    }
  }

  const rotated = async (file, degrees) => {
    const url = URL.createObjectURL(file);
    const image = await Jimp.read(url);
    await image.resize(Jimp.AUTO, brand.baseConfig.options.resolution);
    await image.rotate(degrees);
    await image.flip(true, true);
    const rotated = await image.getBase64Async(Jimp.MIME_JPEG)
    const fetched = await fetch(rotated);
    const blobToReturn = await fetched.blob();
    return blobToReturn;
  }

  const handlePressVideo = () => {

  }

  return (
    <div
      className="home-container"
      style={{ backgroundImage: `url(${brand.baseConfig.images.backgroundImage})` }}
    >
      <Loading
        display-if={isLoading || showLoader}
        color={brand.baseConfig.colors.loadingBarColor}
        backColor={brand.baseConfig.colors.loadingBarColorLight}
      />

      <LogoHeader
        dark={!brand.baseConfig.options.lightHeader}
        alternateLogo={brand.baseConfig.options.brand === 'carnival'}
        childId={childId}
        hideBackButton={true}
      />

      <PromotionalContent display-if={!(isLoading || showLoader)} />
      <PromotionalContentV2 display-if={!(isLoading || showLoader)} />
      <CustomContent
        brand={brand.baseConfig.options.brand}
        display-if={!(isLoading || showLoader)}
      />

      <div
        className={clsx('start-button', brand.baseConfig?.options?.buttonStyle, 'create-video')}
        onClick={() => history.push('/take-photo')}
        display-if={brand.baseConfig.options.videoMode && !(isLoading || showLoader) && false}
      >
        <Text id='common.createVideo' />
      </div>

      <div
        className={clsx('start-button', brand.baseConfig?.options?.buttonStyle)}
        display-if={!(isLoading || showLoader) && !showError}
      >
        <input 
          type="file"
          accept="image/*"
          id="file-image"
          style={{ display: 'none' }}
          onChange={onSetFile}
        />
        <label htmlFor="file-image" className="start-button-transparent">
          <span className=""><Text id='common.landingpagebutton' /></span>
        </label>
      </div>
      <p
        className="no-photos-found"
        style={{ textTransform: brand.baseConfig.options.textTransform }}
        display-if={showError}
      >
        <Text id='common.error' />
      </p>

      <PBFooter
        dark={!brand.baseConfig.options.lightHeader}
        screen={screen}
      />
    </div>
  )
});

export default Home;

