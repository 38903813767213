import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import clsx from 'clsx';
import Text from '../text';

import './promotionalContent.scss'
import FantasticPhotos from './fantasticPhotos';

const PromotionalContent = observer(() => {
    const store = useStore();
    const { brand } = store;
    return (
        <div className="promotional-content-v2__promotional-content-container">
            <div className="promotional-content-v2__promotional-group">
                <p className="promotional-content-v2__promotional-text">
                    <span className="promotional-content-v2__new-england-title-bold" lang='pl'><Text id='common.title' /></span>
                </p>
                <p className="promotional-content-v2__training-camp">
                    <Text id='common.subtitle' />
                </p>
            </div>
            <div className="promotional-content-v2__line" />
            <img src={brand.baseConfig.images.secondaryLogo} className="promotional-content-v2__camp-logo" alt="" />
            <div className="promotional-content-v2__video-container">
                {/* <img src={store.brand.promoStrip} className="promotional-content-v2__photo" alt='' /> */}
                <div className='promotional-content-v2__video-background-photo' />
                <div className="promotional-content-v2__video-content-container">
                    <video
                        src={brand.baseConfig.images.video}
                        className="promotional-content-v2__video"
                        playsInline
                        autoPlay
                        muted
                        loop
                        display-if={brand.baseConfig.images.video}
                    />
                    <img 
                        src={brand.baseConfig.images.promoStrip}
                        className="promotional-content-v2__video"
                        display-if={!brand.baseConfig.images.video}
                    />
                </div>
            </div>
            <FantasticPhotos color={brand.baseConfig.colors.fantasticPhotosColor} />
        </div>
    )
});

export default PromotionalContent;