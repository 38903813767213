import axios from 'axios';

export const getWallEventInfo = (config) => {
    const axiosConfig = {
        headers: {
            Authorization: 'Bearer ' + config.Token,
            'Content-Type': 'application/json'
        }
    }
    return axios.get(`${config.API.GetWallEventInfo}?systemId=${config.SystemId}`, axiosConfig);
}