import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { useStore } from 'stores/store';
import LogoHeader from 'components/logoHeader';
import PBFooter from 'components/pBFooter';
import Text from 'components/text';
import Spinner from 'components/spinner-new/spinner';
import { getBookingUserData } from 'services';
import InputBookingId from './components/booking-id/booking-id';
import WelcomeUser from './components/welcome-user/welcome-user';

import './user-identity.scss'

const SCREEN = 'terms';

const UserIdentity = observer(() => {
  const store = useStore();
  const history = useHistory();

  const [isBooked, setIsBooked] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isInvalidBookingID, setIsInvalidBookingID] = useState(false)

  const handleSubmit = async () => {
    if (store.bookingId) {
      try {
        setIsLoading(true)
        const res = await getBookingUserData(store.config, store.bookingId)
        setIsLoading(false)
        if (res.status === 200) {
          store.setBookingData(res.data)
          setIsBooked(true)
        } else {
          handleInvalidBookingID()
        }
      } catch (e) {
        console.log(e)
        setIsLoading(false)
        handleInvalidBookingID()
      }
    }
  }

  const handleInvalidBookingID = () => {
    setIsInvalidBookingID(true)
    setTimeout(() => { store.setBookingId('') }, 3000)
  }

  const handleNext = () => {
    history.push('/register-user/true')
  }

  const handleChangeBookingId = (event) => {
    setIsInvalidBookingID(false)
    store.setBookingId(event.target.value)
  }

  return (
    <div className="user-identity__container">
      <LogoHeader
        dark={false}
      />

      <div className="user-identity__content-container">

        <InputBookingId
          bookingId={store.bookingId}
          onChangeBookingId={handleChangeBookingId}
          display-if={!isBooked}
          isInvalid={isInvalidBookingID}
        />

        <WelcomeUser
          bookingData={store.bookingData}
          display-if={isBooked}
        />

        <div className='user-identity__cta-cutton--container'>
          <button
            className={clsx(
              'user-identity__cta-button',
              store.brand?.baseConfig?.options?.buttonStyle,
              !store.bookingId && 'disabled'
            )}
            onClick={handleSubmit}
            disabled={!store.bookingId}
            display-if={!isBooked && !isLoading}
          >
            <Text id='common.submit' />
          </button>
          <button
            className={clsx('user-identity__cta-button', store.brand?.baseConfig?.options?.buttonStyle)}
            onClick={handleNext}
            display-if={isBooked && !isLoading}
          >
            <Text id='common.next' />
          </button>

          <Spinner
            display-if={isLoading}
            className="loading-spinner"
          />
        </div>
      </div>

      <PBFooter dark={false} />
    </div>
  )
});

export default UserIdentity;