import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import { ReactComponent as Edit } from './edit-button.svg';
import { ReactComponent as Download } from './download-button.svg';
import { ReactComponent as BlackEdit } from './edit.svg';
import { ReactComponent as Pen } from './pen.svg';
import Text from '../../components/text';
import { checkUser } from '../../utils';

import './finalPhoto.scss';

const MergedPhoto = observer(({ item, index, onSelectPhoto, onRemoveWatermark, video, isVideo, ...bind }) => {
  const store = useStore();
  const { brand } = store;
  const { session, mixpanel, nonBoothSessionId } = store;

  const savePhoto = async (item) => {
    trackDownloadImage();
    saveAs(item?.photo, `${brand.baseConfig.options.brand}_PhotoBooth_Pic00${index}.jpg`, { type: "image/jpeg" })
  }

  const trackDownloadImage = () => {
    let sessionSplit;
    let sessionId = null;
    if (session) {
      sessionSplit = session.split('/');
      sessionId = sessionSplit[1];
    }
    mixpanel.track('DownloadIndividualImage', {
      'BoothSessionID': sessionId,
      'NonBoothSessionID': nonBoothSessionId,
      'Brand': brand.baseConfig.options.brand,
    })
  }

  console.log('item.photo: ', item?.photo)

  return (
    <div display-if={item?.photo !== null} className="photo-parent">
      <img src={item?.photo} className="photo" alt='' {...bind} display-if={!isVideo} />
      <video
        src={video && URL.createObjectURL(video)}
        className="photo"
        alt=''
        display-if={isVideo}
        playsInline
        autoPlay
        loop
        {...bind}
      />
      <div className='edit-button' onClick={() => onSelectPhoto(0)} display-if={!brand.baseConfig.options.useWatermark || store.watermarkRemoved}>
        <Pen className="pen-icon" />
        <Text id='common.edit' />
        {/* <span className="edit-text-translated" ><Text id='common.edit' />edit</span> */}
      </div>
      {/* <BlackEdit className="black-edit" onClick={() => onSelectPhoto(0)} display-if={!store.brand.useWatermark || store.watermarkRemoved}/> */}

    </div>
  )
})

export default MergedPhoto;