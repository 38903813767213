import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import clsx from 'clsx';

import './ryderCupPromotionalContent.scss'
import FantasticPhotos from './fantasticPhotos';

const PromotionalContent = observer(() => {
    const store = useStore();
    const { brand } = store;

    return (
        <div className="rydercup__promotional-content-container">
            <img src="assets/brands/rydercup/fantastic-photos_logo.svg" className="rydercup__fantastic-photo-svg" />
            <div className="rydercup__promotional-group">
                <p className="rydercup__promotional-text-2">Snap and share<br />exclusive experiences from<br />the Ryder Cup</p>
            </div>
            <div className="rydercup__photo-container">
                <img src={brand.baseConfig.images.promoStrip} className="rydercup__photo" alt='' />
            </div>
        </div>
    )
});

export default PromotionalContent;