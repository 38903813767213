import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { useStore } from 'stores/store';
import LogoHeader from 'components/logoHeader';
import PBFooter from 'components/pBFooter';
import Text from 'components/text';

import './welcome.scss'

const SCREEN = 'terms';

const Welcome = observer(() => {
  const store = useStore();
  const history = useHistory();

  const handleBegin = () => {
    store.mixpanel.track('tapBegin', {
      ...store.mixpanelBaseData,
      'Screen': SCREEN,
    });
    history.push('/terms-and-conditions');
  }

  return (
    <div className="welcome__container">
      <LogoHeader
        dark={false}
        hideBackButton
      />
      <img
        className='welcome__background--image'
        src={store.brand.baseConfig.images.welcomeBGImage}
      />

      <div className="welcome__content-container">
        <p className="welcome__title"><Text id='common.welcomeTitle' markup /></p>
        <div className='separator-line' />
        <p className='welcome__text'><Text id='common.premiumService' markup /></p>

        <button
          className={clsx('welcome__begin-button', store.brand?.baseConfig?.options?.buttonStyle)}
          onClick={handleBegin}
        >
          <Text id='common.begin' />
        </button>
      </div>

      <PBFooter dark={false} />
    </div>
  )
});

export default Welcome;