import { observer } from 'mobx-react-lite'

import { useStore } from '../../stores/store'

const Text = observer(({ markup, id, fields, plural, children, useDefault = false }) => {
  const store = useStore()
  const fallback = store.lang.translate({ id, fields, plural, fallback: children, useDefault: true })
  const value = store.lang.translate({ id, fields, plural, fallback, useDefault })

  if (markup) {
    return <span dangerouslySetInnerHTML={{ __html: value }} />
  } else {
    return value
  }
})

export default Text
