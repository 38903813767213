export const getBransLangsJson = async (brands, url) => {
    let langs = null
    url = `${url}/${brands}/brands/${brands}/locales/locales.js`
    const result = await fetch(url, {
        cache: "no-store", headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        },
    })
    if (result.status === 200) {
        const jsonString = await result.text()
        const json = jsonString.replace(/(window.([A-Z])\w+).=/, '')
        langs = JSON.parse(json)
    }
    return langs

}

export const getBrandConfigJson = async (brands, url) => {
    let config = null
    url = `${url}/${brands}/brands/${brands}/config.js`
    const result = await fetch(url, {
        cache: "no-store", headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        },
    })
    if (result.status === 200) {
        const jsonString = await result.text()
        const json = jsonString.replace(/(window.([A-Z])\w+).=/, '')
        config = JSON.parse(json)
    }
    return config

}

export const getBrandBackgroundsJson = async (brandId, url) => {
    let backgrounds = null
    url = `${url}/${brandId}/brands/${brandId}/backgrounds.js`
    const result = await fetch(url, {
        cache: "no-store", headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        },
    })
    if (result.status === 200) {
        const jsonString = await result.text()
        const json = jsonString.replace(/(window.([A-Z])\w+).=/, '')
        backgrounds = JSON.parse(json)
    }
    return backgrounds
}

export const getBrandFramesJson = async (brandId, url) => {
    let frames = null
    url = `${url}/${brandId}/brands/${brandId}/frames.js`
    const result = await fetch(url, {
        cache: "no-store", headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        },
    })
    if (result.status === 200) {
        const jsonString = await result.text()
        const json = jsonString.replace(/(window.([A-Z])\w+).=/, '')
        frames = JSON.parse(json)
    }
    return frames
}

export const getBrandCaptionJson = async (brandId, url) => {
    let captions = null
    url = `${url}/${brandId}/brands/${brandId}/captions.js`
    const result = await fetch(url, {
        cache: "no-store", headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        },
    })
    if (result.status === 200) {
        const jsonString = await result.text()
        const json = jsonString.replace(/(window.([A-Z])\w+).=/, '')
        captions = JSON.parse(json)
    }
    return captions
}

export const getBrandMergeJson = async (brandId, url) => {
    let mergeData = null
    url = `${url}/${brandId}/brands/${brandId}/mergeData.js`
    const result = await fetch(url, {
        cache: "no-store", headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        },
    })
    if (result.status === 200) {
        const jsonString = await result.text()
        const json = jsonString.replace(/(window.([A-Z])\w+).=/, '')
        mergeData = JSON.parse(json)
    }
    return mergeData
}

export const getBrandRootJson = async (brandId, url) => {
    let cssVariables = null
    url = `${url}/${brandId}/brands/${brandId}/cssVariables.js`
    const result = await fetch(url, {
        cache: "no-store", headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        },
    })
    if (result.status === 200) {
        const jsonString = await result.text()
        const json = jsonString.replace(/(window.([A-Z])\w+).+=/, '')
        cssVariables = JSON.parse(json)
    }
    return cssVariables
}