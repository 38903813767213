import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Jimp from 'jimp';
import EXIF from "exif-js";
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { isIOS } from 'react-device-detect'
// import { isAndroid } from 'react-device-detect';

import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { makePhotoStrip, resizePhoto } from '../../utils';

import LogoHeader from '../../components/logoHeader';
import PBFooter from '../../components/pBFooter';
import { makeMergedPhoto } from '../../utils';
import './finalPhoto.scss'
import { getCutOutPhoto } from '../../services';
import LoadingScreen from '../../components/loadingScreen';
import Loading from '../../components/loading';
import { animateScroll as scroll } from 'react-scroll';
import { useLongPress } from 'use-long-press';
import MergedPhoto from './mergedPhoto';
import Text from '../../components/text';
import { FORMAT } from '../../constants';

import FantasticPhotosModal from '../../components/fantasticPhotosModal';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FinalPhoto = observer(({ registrationComplete = false }) => {
  let query = useQuery();
  const store = useStore();
  let { scrollTo, isVideo } = useParams();

  const [photoFile, setPhotoFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);

  const facialRecognition = query.get("facialRecognition") === "true" ? true : false;
  const onsitePhoto = parseInt(query.get("onsitephoto")) || 0;
  const branded = query.get("branded") === "true";

  const {
    config,
    mergeData,
    backgrounds,
    frames,
    captions,
    photos,
    mergedPhotos,
    baseMergeData,
    session,
    mixpanel,
    nonBoothSessionId,
    watermarkRemoved,
    removeWatermark,
    onsitePhotos,
    brand,
  } = store;

  const history = useHistory();
  const callback = useCallback(event => {
    trackMixpanelHardPress();
  }, []);

  const screen = { Screen: 'FinalPhoto' }


  const bind = useLongPress(callback, {
    // onStart: event => console.log('Press started'),
    // onFinish: event => handleLongPress(),
    // onCancel: event => console.log('Press cancelled'),
    threshold: 200,
    captureEvent: true,
    detect: 'both',
  });

  const [isLoading, setIsloading] = useState(false);
  const [isMerging, setIsMerging] = useState(false);

  useEffect(() => {
    mixpanel.track('souvenirCreated', {
      ...store.mixpanelBaseData,
      ...screen,
    })
  }, [])

  useEffect(() => {
    const scrollToPhoto = () => {
      const width = window.innerWidth;
      if (scrollTo > 0) scroll.scrollTo(((0 + scrollTo * 80) * width) / 100, 0)
    }

    if (scrollTo) scrollToPhoto();
  }, [scrollTo])

  useEffect(() => {
    const updatePhotos = () => {
      store.setRemoveWatermark(false);
      makeMergedPhotos();
    }
    if (removeWatermark) updatePhotos();
  }, [removeWatermark])

  useEffect(() => {
    const generateFile = async (photo) => {
      const fetched = await fetch(photo);
      const blob = await fetched.blob();
      const file = new File([blob], `FANtastic_Photo_${store.pid}_${format(new Date(), 'yyyyMMddHHmmss')}.jpg`, { type: "image/jpeg" })
      const urlFile = URL.createObjectURL(file)
      setPhotoFile(file);
      setPhotoURL(urlFile);
    }

    const generateMP4 = () => {
      const type = "video/mp4";
      const extension = "mp4";
      const file = new File([store.videoSouvenir], `FANtastic_Video_${store.pid}_${format(new Date(), 'yyyyMMddHHmmss')}.${extension}`, { type })
      const urlFile = URL.createObjectURL(file);
      setPhotoFile(file);
      setPhotoURL(urlFile);
    }

    if (mergedPhotos[0].photo && !isVideo) generateFile(mergedPhotos[0].photo)
    else if (!!isVideo) generateMP4()
  }, [mergedPhotos])

  const handleLongPress = () => {
    if (facialRecognition && store.watermarkRemoved) {
      store.setShowFantasticPhotosModal(true);
    }
  }

  const trackMixpanel = (frIndex, bgId) => {
    let sessionSplit;
    let sessionId = null;
    if (session) {
      sessionSplit = session.split('/');
      sessionId = sessionSplit[1];
    }
    mixpanel.track('MergeImage', {
      'Foreground': frames[frIndex].name,
      'Background': backgrounds[bgId].name,
      'BoothSessionID': sessionId,
      'NonBoothSessionID': nonBoothSessionId,
      'Brand': brand.baseConfig.options.brand,
      ...store.mixpanelBaseData,
    })
  }

  const trackMixpanelHardPress = () => {
    let sessionSplit;
    let sessionId = null;
    if (session) {
      sessionSplit = session.split('/');
      sessionId = sessionSplit[1];
    }
    mixpanel.track('HardPressImage', {
      'BoothSessionID': sessionId,
      'NonBoothSessionID': nonBoothSessionId,
      'Brand': brand.baseConfig.options.brand,
    })
  }

  const trackMixpanelDownloadAll = () => {
    let sessionSplit;
    let sessionId = null;
    if (session) {
      sessionSplit = session.split('/');
      sessionId = sessionSplit[1];
    }
    mixpanel.track('DowloadPhotoStrip', {
      'BoothSessionID': sessionId,
      'NonBoothSessionID': nonBoothSessionId,
      'Brand': brand.baseConfig.options.brand,
    })
  }

  const onSetFile = async ({ target: { files: [file] } }) => {
    mixpanel.track('tapCreateAnother', {
      ...store.mixpanelBaseData,
      ...screen,
    })
    setIsloading(true);
    EXIF.getData(file);
    const orientation = await new Promise(resolve => {
      EXIF.getData(file, () => {
        resolve(EXIF.getTag("Orientation"));
      })
    })
    const orientedPhoto = await checkOrientation(file);


    const photo = await getCutOutPhoto({ photo: orientedPhoto, config });

    try {
      let newMergedPhotos = [...mergedPhotos]
      let resizedArray = [...photos]
      let newMergeData = [...mergeData];
      let insertAt = 0;
      // mergedPhotos.forEach((item, index) => { if (item?.photo !== null) insertAt = index + 1 });
      const resized = await resizePhoto(photo, brand?.baseConfig.options.height || brand?.baseConfig.options.resolution);
      resizedArray.push(resized);
      // store.setBoothPhotos(resizedArray);
      store.setBoothPhotos([resized]);
      // console.log('baseMergeData: ', store.baseMergeData)
      // console.log('window.BASE_MERGE_DATA: ', window.BASE_MERGE_DATA)
      const mergedPhoto = await makeMergedPhoto(store, resized, { ...window.BASE_MERGE_DATA }, backgrounds, frames, captions, brand?.baseConfig.options.resolution, true);
      const mergedPhotoObj = {
        photo: mergedPhoto.finalMerge,
        mergeData: { ...mergedPhoto.finalMergeData }
      }
      trackMixpanel(baseMergeData.frame, baseMergeData.background)
      // newMergeData.push({ ...baseMergeData });
      newMergeData[insertAt] = { ...mergedPhoto.finalMergeData };
      store.setMergeData(newMergeData);
      newMergedPhotos.push(mergedPhotoObj);
      // store.setMergedPhotos(newMergedPhotos);
      store.setMergedPhotos([mergedPhotoObj]);
      // setTimeout(() => {
      history.push('/editphoto/' + insertAt);
      setIsloading(false)
      // }, 1000);
    } catch (e) {
      console.log(e)
      setIsloading(false)
    }
  }

  const checkOrientation = async (file) => {
    const exifdata = file['exifdata'];
    if (file.exifdata) {
      const { Orientation } = file.exifdata;
      switch (Orientation) {
        case 0:
        case 1:
        case 2:
          return file;
        case 3:
          return await rotated(file, 180);
        case 4:
          return await rotated(file, 0);
        case 5:
        case 6:
          return await rotated(file, 0);
        case 7:
        case 8:
          return await rotated(file, 0);
        default:
          return file;
      }
    } else {
      return file;
    }
  }

  const rotated = async (file, degrees) => {
    const url = URL.createObjectURL(file);
    const image = await Jimp.read(url);
    await image.resize(Jimp.AUTO, brand?.baseConfig.options.resolution);
    await image.rotate(degrees);
    // if (!isAndroid) {
    //     console.log('is Android: ', isAndroid)
    //     console.log('flip image')
    // }
    await image.flip(true, true);
    const rotated = await image.getBase64Async(Jimp.MIME_JPEG)
    const fetched = await fetch(rotated);
    const blobToReturn = await fetched.blob();
    return blobToReturn;
  }

  const onSelectPhoto = index => {
    mixpanel.track('tapEditSouvenir', {
      ...store.mixpanelBaseData,
      ...screen,
      'Index': index
    })
    history.push('/editphoto/' + index)
  }

  const checkMergedPhotos = () => {
    if (!mergedPhotos) return false;
    let checkedPhotos = false;
    mergedPhotos.forEach(photo => {
      if (photo.photo !== null) checkedPhotos = true
    });
    return checkedPhotos;
  }

  const onDownloadStrip = async () => {
    trackMixpanelDownloadAll();
    const strip = await makePhotoStrip(mergedPhotos)
    saveAs(strip, 'PB-photo-strip.png');
  }

  const makeMergedPhotos = async () => {
    try {
      setIsMerging(true)
      let newMergedPhotos = [];
      for (const p in photos) {
        const mergedPhoto = await makeMergedPhoto(store, photos[p], mergeData[p], backgrounds, frames, captions, brand?.baseConfig.options.resolution, false);
        const mergedPhotoObj = {
          photo: mergedPhoto,
          mergeData: { ...mergeData[p] }
        }
        newMergedPhotos.push(mergedPhotoObj)
      }
      store.setMergedPhotos(newMergedPhotos)
      setIsMerging(false);
    } catch (e) {
      console.log(e)
      setIsMerging(false);
    }
  }

  const trackRemoveWatermark = () => {
    mixpanel.track('tapRemoveWatermark', {
      ...store.mixpanelBaseData,
      ...screen,
    });
  }

  const trackFPFromSearch = () => {
    store.mixpanel.track('FP_from_Search', {
      'SessionID': store.nonBoothSessionId,
    });
  }

  const trackSaveAndShare = () => {
    mixpanel.track('tapSaveShare', {
      ...store.mixpanelBaseData,
      ...screen,
    });
  }

  const handleOnCreatePhoto = async () => {
    store.setShowFantasticPhotosModal(false)
    setIsloading(true)
    try {
      // const photo = await getPhoto(onsitePhotos[onsitePhoto].url);
      // const fetched = await fetch(photo);
      // const blob = await fetched.blob();
      const resized = await resizePhoto(onsitePhotos[onsitePhoto].url, brand?.baseConfig.options.resolution);
      const cutPhoto = await getCutOutPhoto({ photo: resized, config, useUrlBlob: false });
      let newMergedPhotos = [...mergedPhotos]
      let resizedArray = [...photos]
      let newMergeData = [...mergeData];
      let insertAt = 0;
      // mergedPhotos.forEach((item, index) => { if (item?.photo !== null) insertAt = index + 1 });
      // const resized = await resizePhoto(cutPhoto, brand.resolution);
      resizedArray.push(cutPhoto);
      // store.setBoothPhotos(resizedArray);
      store.setBoothPhotos([cutPhoto]);
      const mergedPhoto = await makeMergedPhoto(store, resized, { ...baseMergeData }, backgrounds, frames, captions, brand?.baseConfig.options.resolution, true);
      const mergedPhotoObj = {
        photo: mergedPhoto.finalMerge,
        mergeData: { ...mergedPhoto.finalMergeData }
      }
      trackMixpanel(baseMergeData.frame, baseMergeData.background)
      // newMergeData.push({ ...baseMergeData });
      newMergeData[insertAt] = { ...mergedPhoto.finalMergeData };
      store.setMergeData(newMergeData);
      newMergedPhotos.push(mergedPhotoObj);
      // store.setMergedPhotos(newMergedPhotos);
      store.setMergedPhotos([mergedPhotoObj]);
      // setTimeout(() => {
      history.push('/editphoto/' + insertAt);
      setIsloading(false)
      // }, 1000);
    } catch (e) {
      console.log(e)
      setIsloading(false)
    }
  }

  const handleOnRemoveWatermark = () => {
    if (brand.chooseTeam) {
      store.setShowChooseYourTeamModal(true);
    }
    store.setShowEmailRegister(true);
    trackRemoveWatermark();
  }

  return (
    <div className={clsx('final-photo-container', registrationComplete && 'registration-complete__container')}>
      <Loading
        display-if={isLoading || isMerging}
        color={brand.baseConfig.colors.loadingBarColor}
        backColor={brand.baseConfig.colors.loadingBarColorLight}
      />

      <LogoHeader
        dark={false}
        showActions
        fixed={true}
        alternateLogo={brand.baseConfig.options.brand === 'retreat' || brand.baseConfig.options.brand === 'partner' || brand.baseConfig.options.brand === 'uva'}
        showLetter={false}
        hideBackButton
      />

      <div display-if={registrationComplete} className='registration-complete__content--container'>
        <span className='registration-complete__title'>
          <Text id='common.youAreNowAllSet' markup />
        </span>
        <div className='separator-line' />
        {/* <span className='registration-complete__subtitle'>
          <Text id='common.weLookForward' />
        </span> */}
        <span className='registration-complete__enjoy--text'>
          <Text id='common.enjoyYourComplementary' />
        </span>
      </div>

      <div className={
        clsx(
          brand.baseConfig.options.format === FORMAT.SQUARE && 'photo-merged-container',
          brand.baseConfig.options.format === FORMAT.RECTANGULAR_16_BY_9 && 'photo-merged-container-16-by-9',
          registrationComplete && 'photo-merged-container-16-by-9-registration-complete'
        )}
        display-if={!facialRecognition && !isLoading && !isMerging}>
        <div
          className='edit-button-container'
          onClick={() => onSelectPhoto(0)}
          display-if={!brand.baseConfig.options.isEditHidden}
        >
          <div className='edit-button-top' display-if={!brand.baseConfig.options.isEditHidden}>
            Edit
          </div>
        </div>
        <MergedPhoto
          // item={mergedPhotos[0]}
          item={{ photo: photoURL }}
          index={0}
          onSelectPhoto={onSelectPhoto}
          video={store.videoSouvenir}
          isVideo={!!isVideo}
          {...bind}
        />
      </div>

      {/* TODO: transalte - add to features */}
      <div
        onClick={() => window.open(brand.baseConfig.links.feedback, '_blanc')}
        display-if={brand.baseConfig.options.feedback}
        className='feedback'
      >
        <span>Give feedback!</span>
      </div>

      <div className="photo-merged-container" display-if={facialRecognition}>
        <div className="photo-parent" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={
              !branded ?
                onsitePhotos[onsitePhoto].url :
                (watermarkRemoved || !brand.options.useWatermark) && onsitePhotos.length > 0 ?
                  onsitePhotos[onsitePhoto].branded :
                  onsitePhotos[onsitePhoto].watermarked
            }
            className={clsx('photo photo-centered photo-facial-recognition')}
            alt=''
            {...bind}
          />
        </div>
      </div>


      <p
        className="press-and-hold-text"
        display-if={(!brand.baseConfig.options.useWatermark || store.watermarkRemoved) && true}
      >
        {
          store.iframeViewer ?
            'Right Click on your photo to save' :
            <Text id='common.downloadtooltip' />
        }

      </p>




      <div
        className='cta-row'
        display-if={(
          !brand.baseConfig.options.useWatermark || store.watermarkRemoved)
          && store.photoEdited
          && brand.baseConfig.options.useShareButton
          && !isLoading
          && !isMerging
        }
      >
        <div
          className={clsx('save-share-button edit-button', brand.baseConfig?.options?.buttonStyle)}
          type="file" accept="image/*"
          display-if={((!brand.baseConfig.options.useWatermark || store.watermarkRemoved) && store.photoEdited) || true}
        >
          <input type="file" accept="image/*" id="file-image" style={{ display: 'none' }} onChange={onSetFile} />
          <label htmlFor="file-image" className="remove-watermark-transparent">
            <div className="upload-cload-container">
              <p className="get-photo-text"><Text id='common.createAnother' /></p>
            </div>
          </label>
        </div>

        <div
          className={clsx('save-share-button', brand.baseConfig?.options?.buttonStyle)}
          onClick={() => {
            if (navigator.canShare({ files: [photoFile] })) {
              navigator.share({ files: [photoFile] });
              trackSaveAndShare();
            }
          }}
        >
          <p className=""><Text id='common.sharebutton' /></p>
        </div>
      </div>



      <div
        className="remove-watermark-button"
        display-if={brand.baseConfig.options.useWatermark && !store.watermarkRemoved}
        onClick={() => { handleOnRemoveWatermark() }}
      >
        <span className="get-photo-text">Remove Watermark</span>
      </div>

      <div
        className="remove-watermark-button create-another"
        display-if={brand.baseConfig.options.useWatermark && store.watermarkRemoved && !store.photoEdited}
        onClick={() => { store.setShowFantasticPhotosModal(true); trackFPFromSearch(); }}
      >
        <span className="get-photo-text">Create Souvenir Photo</span>
      </div>

      <p
        display-if={facialRecognition && watermarkRemoved}
        className="find-more-photos-text absolute-more-photos"
        onClick={() => history.push('/select-favorite-photo')}
      >
        {'Find More Photos >'}
      </p>

      {/* <div
                display-if={facialRecognition && !watermarkRemoved} 
                onClick={() => history.push('/select-favorite-photo')}
                className="not-you-container"
            >
                <p className="not-you">Not You?</p>
                <p className="find-more-photos-text">
                    {'View More >'}
                </p>
            </div> */}

      <div
        className="remove-watermark-button create-another try-fantastic"
        display-if={facialRecognition && !watermarkRemoved}
        onClick={() => { history.push('/'); trackFPFromSearch(); }}
      >
        <span className="get-photo-text">TRY FANTASTIC PHOTOS</span>
      </div>


      {/* <img src="assets/brands/rydercup/fantastic-photos_logo.svg" className="rydercup__fantastic-photo-svg" alt=""/> */}

      <PBFooter dark={false} />

      <FantasticPhotosModal isOpen={store.showFantasticPhotosModal} setIsOpen={store.setShowFantasticPhotosModal} onCreatePhoto={handleOnCreatePhoto} />
    </div>
  )
});

export default FinalPhoto;