import React, { useState } from "react";
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { useStore } from '../../../../stores/store';

import './purchase-complete.scss'

const PurchaseComplete = observer(({ setIsOpen }) => {
  const store = useStore();

  const submit = async () => {
    try {
      setIsOpen(false)
    } catch (e) {
      console.log(e)
    }
  }

  const isLoyaltyMembershipRoom = store.room === store.config.LoyaltyMembershipRoom


  return (

    <>
      <p
        className="register-title dark-cyan-title"
      >
        <span display-if={!store.loyaltyMember && !isLoyaltyMembershipRoom}>Purchase Confirmed:<br />Enjoy Your Souvenir Photo</span>
        <span display-if={store.loyaltyMember || isLoyaltyMembershipRoom}>As a Loyalty Member Enjoy Your Complementary Souvenir Photo</span>
      </p>

      <div
        className={clsx('submit-button')}
        onClick={submit}
      >
        <span className="submit-button-text" onClick={submit} >OK</span>
      </div>
    </>

  )
})

export default PurchaseComplete;