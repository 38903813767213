import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStore } from '../../stores/store';
import EmailModal from '../emailModal';
import EmailModalV2 from '../emailModalV2';
import ChooseYourTeamModal from '../chooseYourTeamModal';
import FantasticPhotosModal from '../fantasticPhotosModal';
import AcceptCookiesModal from '../acceptCookiesModal';
import { ReactComponent as BackArrow } from './back-button.svg'
import RemoveWatermark from '../removeWatermark';

import './logoHeader.scss'

const LogoHeader = observer(({
  showHome,
  dark,
  fixed = false,
  showSave = false,
  onSave,
  showLetter = false,
  alternateLogo = false,
  onShowRegister = false,
  backRoute = null,
  hideBackButton = false,
  pressed = false,
  childId = false,
  centeredLogo = false,
  screen = { Screen: null },
  showFlag = false
}) => {
  const history = useHistory();
  const store = useStore();

  const handleBackButtonClick = () => {
    store.mixpanel.track('TapBack', {
      ...store.mixpanelBaseData,
      ...screen,
    })
    if (backRoute) {
      history.push(backRoute);
    } else {
      history.goBack();
    }
  }

  const handleClickLogo = () => {
    store.mixpanel.track('TapLogo', {
      ...store.mixpanelBaseData,
      ...screen,
    })
    if (store.homelogo) {
      console.log('click logo')
      store.resetBookingData();
      store.setBookingId('')
      history.push('/')
    }
  }

  const handleTapEnglish = () => {
    store.mixpanel.track('TapEnglish', {
      ...store.mixpanelBaseData,
      ...screen,
    })
    store.changeLang('en')
  }

  const handleTapFrench = () => {
    store.mixpanel.track('TapFrench', {
      ...store.mixpanelBaseData,
      ...screen,
    })
    store.changeLang('fr')
  }

  const handleHomeLogo = () => {
    console.log('click logo')
    store.resetBookingData();
    history.push('/')
  }

  return (
    <div className={clsx('logo-header-container', dark && 'dark-header', fixed && 'fixed-header')}>
      <span
        onClick={handleBackButtonClick}
        className={clsx('header-back-button', store.brand.primary === "#ffffff" && 'contrast-text')}
        display-if={!hideBackButton && store.brand.baseConfig.options.showBackButton}>
        <BackArrow className="header-back-arrow" />
        {/* {'Back'} */}
      </span>
      <img src={'assets/svg/home.svg'} className={clsx('home', showHome ? 'display-block' : 'display-none')} onClick={() => history.push('/')} alt='' />
      <img
        display-if={childId === false}
        src={store.brand.baseConfig.images.logo}
        className={clsx('logo', centeredLogo && 'centered-logo')}
        alt="logo"
        onClick={handleClickLogo}
      />
      <img
        display-if={childId !== false}
        src={store.childBrands[childId].logo}
        className={clsx('logo', centeredLogo && 'centered-logo')}
        alt="logo"
      />
      <div
        display-if={false}
        className={clsx('header-save', pressed && 'pressed-save', store.brand.primary === "#ffffff" && 'contrast-text')}
        style={{ display: showSave ? 'flex' : 'none' }}
        onClick={onSave}
      >
        <span className={clsx('header-save-text')}>DONE</span>
      </div>
      <img
        src={'assets/svg/letter.svg'}
        className={clsx('home', showLetter ? 'display-block' : 'display-none')}
        onClick={() => store.setShowEmailRegister(true)}
        alt=''
      />
      <img
        src={store.brand.advanceConfig.images.usaFlag}
        className={clsx('flag usa-flag', store.lang.currentLang === 'en' && 'selected-flag')}
        onClick={handleTapEnglish}
        alt=''
        display-if={showFlag}
      />
      <img
        src={store.brand.advanceConfig.images.frenchFlag}
        className={clsx('flag french-flag', store.lang.currentLang === 'fr' && 'selected-flag')}
        onClick={handleTapFrench}
        alt=''
        display-if={showFlag}
      />
      <RemoveWatermark
        isOpen={store.showEmailRegister}
        setIsOpen={store.setShowEmailRegister}
        removeVersion={store.brand.baseConfig?.options.emailVersion}
      />
      <ChooseYourTeamModal isOpen={store.showChooseYourTeamModal} setIsOpen={store.setShowChooseYourTeamModal} />
      {/* <FantasticPhotosModal isOpen={store.showFantasticPhotosModal} setIsOpen={store.setShowFantasticPhotosModal} /> */}
      <AcceptCookiesModal isOpen={store.showAcceptCookiesModal} setIsOpen={store.setShowAcceptCookiesModal} />

    </div>
  )
})

export default LogoHeader;