import { action, observable, autorun } from 'mobx'
import { format, parseISO } from 'date-fns'
import titleCase from 'voca/title_case'
import translate from 'preact-i18n/src/lib/translate'
import { dset } from 'dset'
import persisted from '../services/persisted'

import en from '../locales/en.json'
import es from '../locales/es.json'
import fr from '../locales/fr.json'
import it from '../locales/it.json'
import de from '../locales/de.json'
import hr from '../locales/hr.json'
import ja from '../locales/ja.json'
import ka from '../locales/ka.json'
import nl from '../locales/nl.json'
import pl from '../locales/pl.json'
import pt_BR from '../locales/pt_BR.json'
import ru from '../locales/ru.json'
import sk from '../locales/sk.json'
import sl from '../locales/sl.json'
import tr from '../locales/tr.json'
import uk from '../locales/uk.json'
import zh from '../locales/zh.json'
// import { useStore } from './store'

// const store = useStore()
// let langs = {
//   en, es, fr, it, de, hr, ja, ka, nl, pl, pt_BR, ru, sk, sl, tr, uk, zh
// }

let langs = window.LANGS


// setTimeout(() => console.log('window.LANGS in langs: ', window.LANGS), 100)

// let langs = window.LANGS;


// const overrides = store.env.langsOverrides
// Object.keys(overrides).forEach((key) => {
//   Object.keys(overrides[key]).forEach((lang) => {
//     dset(langs[lang], key, overrides[key][lang])
//   })
// })

Object.entries(langs).forEach(
  ([key, value]) => langs[key].common.adjusttooltip = langs[key].common.adjusttooltip.replace('<b>(.common.donebutton)</b>', `<strong>${langs[key].common.donebutton}</strong>`)
);

const dateLangs = {
  'en': require('date-fns/locale/en-US').default,
  'es': require('date-fns/locale/es').default,
  'fr': require('date-fns/locale/fr').default,
  'it': require('date-fns/locale/it').default,
  'de': require('date-fns/locale/de').default,
  'pt': require('date-fns/locale/pt').default,
}

let defaultLang = 'en'

let urlParams = new URLSearchParams(window.location.search);

export const lang = observable({
  langs,
  dateLangs,
  defaultLang,
  urlParams,

  // @persisted('local', 'ui.lang')
  @observable
currentLang: urlParams.get('lang') || defaultLang,
  // currentLang: defaultLang,

  @action changeLang(lang) {
  lang.currentLang = lang
},

translate({ id, fields, plural, fallback, useDefault = false }) {
  return translate(
    id,
    useDefault ? lang.defaultLang : lang.currentLang,
    lang.langs,
    fields,
    plural,
    fallback,
  )
},

formatDate(date, pattern = 'iiii, d MMMM yyyy', options = {}, notz = false) {
  if (!notz) { date = store.api.adjustCalendarTime(date) }
  if (typeof date === 'string') { date = parseISO(date) }
  const locale = lang.dateLangs[lang.currentLang]
  return titleCase(format(date, pattern, { ...options, locale }))
},
})


autorun(() => {
  document.documentElement.setAttribute('lang', lang.currentLang)
  urlParams.set('lang', lang.currentLang)
})

