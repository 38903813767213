import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import { ReactComponent as Check } from './check.svg';
import clsx from 'clsx';
import Text from '../../components/text';
import SelectBackground from './selectBackground';
import AddFrame from './addFrame';

const VerticalMenu = ({ backgrounds, bgIndex, setBgIndex, frames, frIndex, setFrIndex, isMobile = false, format }) => (
  <div className='vertical-menu-container'>
    <div className='vertical-menu-content-container'>

      <div className='menu-block'>
        <span 
          className="menu-title"
          display-if={backgrounds[bgIndex]?.maskAsset === ""}
        >
          <Text id='common.frames' />
        </span>

        <AddFrame
          frames={frames}
          frIndex={frIndex}
          setFrIndex={setFrIndex}
          isMobile={true}
          format={format}
          display-if={backgrounds[bgIndex]?.maskAsset === ""}
        />
      </div>


      <div className='menu-block'>
        <span className="menu-title" >
          <Text id='common.backgrounds' />
        </span>

        <SelectBackground
          backgrounds={backgrounds}
          bgIndex={bgIndex}
          setBgIndex={setBgIndex}
          isMobile
          format={format}
        />
      </div>

    </div>
  </div>
)

export default VerticalMenu;